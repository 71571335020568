import axios, { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import {
  DecodedTokenDataType,
  StripePlanType,
  SubscriberType
} from 'interfaces/subscription.interface';
import focusBearApi from 'services/axios-config';
import * as Sentry from '@sentry/react';
import {
  HTTP_STATS_CODE,
  LOCAL_STORAGE,
  SINGLE_ENTITLEMENT
} from 'constants/general';
import { SUBSCRIPTION_INFO_KEY } from 'constants/routes';
import { getLocalStorage } from 'utils/helpers';
import Endpoints from 'constants/endpoints';
import { store } from 'store';
import {
  updateIsCancelingSubscription,
  updateIsGuestUser,
  updateShowCancelReasonModal
} from 'store/reducer/user/slice';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateSubscriptionInfo,
  updateIsFetchingStripPlans,
  updateIsSubscriptionChecking,
  updateSubscriptionStripPans,
  updateEntitlements,
  updateActiveEntitlements,
  updateSubscriptions
} from './slice';
import { updateError } from '../setting/slice';
import { ENTITLEMENT } from 'constants/enum';
import moment from 'moment';

export const getStripePlans = createAsyncThunk(
  'subscription/get-stripe-plans',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsFetchingStripPlans(true));
      const { data } = await focusBearApi.get(
        Endpoints.SUBSCRIPTION_STRIPE_PRODUCTS,
        {
          headers: {
            'Cache-Control': 'private, max-age=86400, must-revalidate' //@Description: 1 day == 86400 seconds
          }
        }
      );
      const plansWithDefaultPrices = data?.data?.filter(
        (item: StripePlanType) => item.default_price
      );
      dispatch(updateSubscriptionStripPans(plansWithDefaultPrices));
      dispatch(updateIsFetchingStripPlans(false));
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(updateIsFetchingStripPlans(false));
    }
  }
);

const getRevenueCatSubscriber = async (
  decodedToken: DecodedTokenDataType,
  RCStripeApiKey: string
) => {
  const app_user_id = decodedToken[SUBSCRIPTION_INFO_KEY].id;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Platform': 'web',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${RCStripeApiKey}`,
      'Cache-Control': 'private, max-age=86400, must-revalidate' //@Description: 1 day == 86400 seconds
    }
  };

  try {
    const res = await axios.get(
      `${Endpoints.REVENUE_CAT}${app_user_id}`,
      options
    );

    return res.data.subscriber;
  } catch (error) {
    Sentry.captureException(error);
  }
};

const getRevenueCatSubscription = async () => {
  const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
  if (!token) {
    throw new Error(
      "Can't fetch subscription info because there is no access token"
    );
  } else {
    const decodedToken: DecodedTokenDataType = await jwt_decode(token);
    const RCResponse: SubscriberType = await getRevenueCatSubscriber(
      decodedToken,
      process.env.REACT_APP_RC_STRIPE_API_KEY as string
    );
    const tokenSubscriptionInfo = decodedToken[SUBSCRIPTION_INFO_KEY];
    const userId = tokenSubscriptionInfo.id;
    const stripeCustomerId = tokenSubscriptionInfo.stripeCustomerId;
    const userHasActiveSubscription =
      tokenSubscriptionInfo.subscriptionStatus.hasActiveSubscription;
    const entitlements = Object.entries(RCResponse.entitlements).map(
      ([entitlement, value]) => ({
        entitlement: entitlement as ENTITLEMENT,
        ...value
      })
    );
    const subscriptions = Object.entries(RCResponse.subscriptions).map(
      ([product_identifier, subscription]) => ({
        ...subscription,
        product_identifier
      })
    );
    const activeEntitlements = Object.entries(
      tokenSubscriptionInfo?.subscriptionStatus?.expirations ?? {}
    ).map(([entitlement, expirationInfo]) => ({
      entitlement: entitlement as ENTITLEMENT,
      ...expirationInfo
    }));

    if (!RCResponse.entitlements) {
      throw new Error('Could not fetch subscription info from RevenueCat API.');
    }

    return userHasActiveSubscription
      ? {
          hasActiveSubscription: true,
          stripeCustomerId,
          userId,
          activeEntitlements,
          entitlements,
          subscriptions
        }
      : {
          hasActiveSubscription: false,
          unsubscribeDetectedAt: null,
          stripeCustomerId,
          userId,
          activeEntitlements,
          entitlements,
          subscriptions
        };
  }
};

export const getSubscriptionInfo = createAsyncThunk(
  'subscription/get_subscription_info',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsSubscriptionChecking(true));
      const { entitlements, activeEntitlements, subscriptions, ...rest } =
        await getRevenueCatSubscription();
      const isTrialActive =
        entitlements.length === SINGLE_ENTITLEMENT &&
        entitlements.some(
          (entitlement) =>
            entitlement.product_identifier.includes('trial') &&
            moment(entitlement.expires_date).isBefore(moment())
        );

      dispatch(updateIsGuestUser(isTrialActive));
      dispatch(updateSubscriptions(subscriptions));
      dispatch(updateEntitlements(entitlements));
      dispatch(updateActiveEntitlements(activeEntitlements));
      dispatch(updateSubscriptionInfo(rest));
      dispatch(updateIsSubscriptionChecking(false));
    } catch (error) {
      dispatch(
        updateError({
          message: 'errors.couldnt_fetch_your_subscription_info',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const cancelSubscription = async ({
  cancel_subscription_reason,
  entitlement_id
}: {
  cancel_subscription_reason: string;
  entitlement_id: string;
}) => {
  try {
    store.dispatch(updateIsCancelingSubscription(true));
    const { status } = await focusBearApi.post(
      Endpoints.STRIPE.CANCEL_SUBSCRIPTION_SESSION,
      {
        cancel_subscription_reason,
        entitlement_id
      }
    );
    store.dispatch(updateIsCancelingSubscription(false));
    if (status === HTTP_STATS_CODE.CREATED) {
      store.dispatch(updateShowCancelReasonModal(false));
      store.dispatch(getSubscriptionInfo());
    } else {
      toast.error(t('could_not_process_the_request'));
    }
  } catch (error) {
    toast.error(t('could_not_process_the_request'));
    store.dispatch(updateIsCancelingSubscription(false));
  }
};
