import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { init as SentryInit } from '@sentry/react';
import './index.css';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import Auth0Provider from './services/auth0-provider';
import './services/i18n';
import { HTTP_STATS_CODE, LOCAL_STORAGE } from 'constants/general';
import reportAccessibility from 'utils/reportAccessibility';
import { SENTRY_CONFIG } from 'services/sentry-config';
import Loading from 'components/common/Loading';
import * as Sentry from '@sentry/react';
import ErrorPage from 'pages/other/ErrorPage';
import { updateError } from 'store/reducer/setting/slice';
import { registerServiceWorks } from 'utils/registerWorker';
import {
  getLocalStorage,
  removeLocalStorage,
  isProductionEnvironment
} from 'utils/helpers';
import { ROUTES } from 'constants/routes';
import NavigationHelper from 'dashboard';

if (isProductionEnvironment) {
  SentryInit(SENTRY_CONFIG);
  registerServiceWorks();
}

const getAppContext = () => {
  if (
    getLocalStorage(
      LOCAL_STORAGE.REDIRECT_TO_MANAGE_SUBSCRIPTION_VIA_WEBVIEW
    ) === ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION
  ) {
    removeLocalStorage([
      LOCAL_STORAGE.REDIRECT_TO_MANAGE_SUBSCRIPTION_VIA_WEBVIEW
    ]);
    window.location.pathname = ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION;
  }

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary
        onError={(error) => {
          store.dispatch(
            updateError({
              message: error.message,
              status: HTTP_STATS_CODE.REST_CONTENT,
              url: window.location.pathname
            })
          );
          Sentry.captureException(error);
        }}
        fallback={<ErrorPage />}
      >
        <Suspense fallback={<Loading />}>
          <NavigationHelper />
        </Suspense>
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <Auth0Provider>{getAppContext()}</Auth0Provider>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById('root')
);

reportAccessibility(React);
reportWebVitals();
