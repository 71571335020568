import { DEFAULT_TAB_LAYOUT_TAB, ROUTINE } from 'constants/general';
import { ActivityType } from 'interfaces/common.interface';
import moment from 'moment';
import { getDurationFormat } from './helpers';

export const getUniqueGoals = (values: string[]) =>
  values.filter((goal, index, goals) => goals.indexOf(goal) === index);

export const formatRoutineTimeSuggestions = (duration: number) =>
  moment()
    .startOf('date')
    .add(duration, 'seconds')
    .format(getDurationFormat(duration));

export const formatOnboardingPackTitle = (
  duration: number,
  pack_name: string
) =>
  `${moment().startOf('day').add(duration, 'seconds').format('H[h]:mm[m]')} ${pack_name}`;

export const getRoutinesDuration = (
  activeTab: number,
  activities: ActivityType[],
  routines: { value: boolean; title: string }[]
) => {
  const { morning_duration, evening_duration } = activities.reduce(
    (total, suggestion) =>
      suggestion.activity_type === ROUTINE.MORNING
        ? {
            ...total,
            morning_duration:
              total.morning_duration +
              parseInt(suggestion.duration_seconds?.toString())
          }
        : {
            ...total,
            evening_duration:
              total.evening_duration +
              parseInt(suggestion.duration_seconds?.toString())
          },
    { morning_duration: 0, evening_duration: 0 }
  );

  return routines.some(
    (routine) =>
      routine.value && routine.title.toLowerCase().includes(ROUTINE.MORNING)
  ) && activeTab === DEFAULT_TAB_LAYOUT_TAB
    ? formatRoutineTimeSuggestions(morning_duration)
    : formatRoutineTimeSuggestions(evening_duration);
};
