import React, { PropsWithChildren, useMemo } from 'react';
import { EMPTY_STRING } from 'constants/general';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from 'store/hooks';
import { isDashboard } from 'utils/helpers';
import classNames from 'classnames';
import { ROUTES } from 'constants/routes';
import { isDarkModeActivated } from 'utils/validators';
import { detailsPageWrapperSelector } from 'store/reducer/habit-pack/selectors';

export const DetailsPageWrapperContext = React.createContext({
  page: EMPTY_STRING
});

const DetailsPageWrapper = ({
  page,
  children
}: PropsWithChildren<{ page: string }>) => {
  const { isAuthenticated } = useAuth0();
  const { themeMode, isOnHabitPacksTab } = useAppSelector(
    detailsPageWrapperSelector
  );

  const [isMarketplaceRoute, shouldDarkModeActivated] = useMemo(
    () => [
      window.location.pathname === ROUTES.MARKETPLACE,
      isDarkModeActivated(themeMode)
    ],
    [themeMode]
  );

  return (
    <div
      className={classNames('w-full flex flex-col animate-fadeIn ', {
        'px-2 sm:px-6 md:px-10 lg:px-14': isDashboard,
        'h-full': isAuthenticated || !isMarketplaceRoute,
        'h-screen py-8 bg-bear bg-no-repeat bg-center bg-fixed overflow-y-auto scrollbar-thin scrollbar-thumb-orange-300':
          !isAuthenticated && isMarketplaceRoute,
        'bg-gray-600':
          (isAuthenticated || isOnHabitPacksTab) && shouldDarkModeActivated,
        'bg-transparent':
          (isAuthenticated || isOnHabitPacksTab) && !shouldDarkModeActivated
      })}
    >
      <DetailsPageWrapperContext.Provider value={{ page }}>
        {children}
      </DetailsPageWrapperContext.Provider>
    </div>
  );
};

export default DetailsPageWrapper;
