import { useEffect } from 'react';
import TabBody from './TabBody';
import {
  MAXIMUM_ALLOWED_SECONDS,
  PLATFORMS,
  TIME_FORMAT_24H
} from 'constants/general';
import { isYoutubeURL } from 'utils/validators';
import { useTranslation } from 'react-i18next';
import { Mode } from 'constants/enum';
import { ActivityType } from 'interfaces/common.interface';
import SharePack from 'components/SharePack';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Output from './Output';
import TabActions from './tab-actions';
import { updateAreSettingsValid } from 'store/reducer/setting/slice';
import OverlaySpinner from 'components/OverlaySpinner';
import Gear from 'assets/icons/Gear';
import { getValidNumber } from 'utils/helpers';
import moment from 'moment';
import { isMobilePlatform } from 'utils/settings.util';

const GeneratingSettings = () => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-fit h-fit flex items-center justify-center gap-2 m-auto`}
    >
      <p className='text-xl xs:text-3xl sm:text-4xl text-center'>
        {t('generating_settings_please_wait')}
      </p>
      <Gear styles='w-7 xs:w-9 sm:w-12 h-7 xs:h-9 sm:h-12 animate-spinSlow' />
    </div>
  );
};

const Tabs = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    setting: {
      currentSettings,
      platform,
      mode,
      isTextEditorWithinCharacterLimit,
      isSettingEditingFinished,
      areSettingsSaving
    },
    habitPack: { isUserUpsertingPack },
    focusMode: { currentViewedFocusTemplate }
  } = useAppSelector((state) => state);

  const validateFocusModeTemplate = () => {
    const areAllRequiredFieldsFilled =
      currentViewedFocusTemplate?.name &&
      currentViewedFocusTemplate?.description &&
      currentViewedFocusTemplate?.welcome_message;
    const areDescriptionUrlsValid =
      currentViewedFocusTemplate?.description_video_url ?? ''
        ? true
        : isYoutubeURL(
            String(currentViewedFocusTemplate?.description_video_url)
          );
    const areWelcomeUrlsValid =
      currentViewedFocusTemplate?.welcome_video_url ?? ''
        ? true
        : isYoutubeURL(String(currentViewedFocusTemplate?.welcome_video_url));

    const areSettingsValid = Boolean(
      areAllRequiredFieldsFilled &&
        areDescriptionUrlsValid &&
        areWelcomeUrlsValid &&
        isTextEditorWithinCharacterLimit &&
        (currentViewedFocusTemplate?.allowed_urls?.length ||
          currentViewedFocusTemplate?.allowed_apps?.length)
    );
    dispatch(updateAreSettingsValid(areSettingsValid));
  };

  useEffect(() => {
    const checkDataValidation = () => {
      let flattenNamesDurationsAndCompletionRequirementsArray: {
        name: string;
        duration: number;
        completion_requirements: string | null;
      }[] = [];

      const foundInvalidActivityTime = (
        currentSettings?.evening_activities ?? []
      ).some((activity) =>
        currentSettings.cutoff_time_for_non_high_priority_activities &&
        activity.cutoff_time_for_doing_activity
          ? moment(
              activity.cutoff_time_for_doing_activity,
              TIME_FORMAT_24H
            ).isBefore(
              moment(
                currentSettings.cutoff_time_for_non_high_priority_activities,
                TIME_FORMAT_24H
              )
            )
          : false
      );

      [
        ...(currentSettings.morning_activities ?? []),
        ...(currentSettings?.break_activities ?? []),
        ...(currentSettings?.evening_activities ?? []),
        ...(currentSettings.standalone_activities ?? [])
      ].forEach(
        ({ name, duration_seconds, completion_requirements }: ActivityType) => {
          flattenNamesDurationsAndCompletionRequirementsArray = [
            ...flattenNamesDurationsAndCompletionRequirementsArray,
            {
              name,
              duration: getValidNumber(duration_seconds),
              completion_requirements: completion_requirements ?? null
            }
          ];
        }
      );

      const areRoutinesDataValid =
        flattenNamesDurationsAndCompletionRequirementsArray.every((data) => {
          return (
            data.name &&
            data.duration &&
            data.duration <= MAXIMUM_ALLOWED_SECONDS
          );
        });

      if (mode === Mode.ROUTINE || mode === Mode.STANDALONE) {
        const isWelcomeVideoUrlFieldValid = currentSettings?.welcome_video_url
          ? isYoutubeURL(String(currentSettings?.welcome_video_url ?? ''))
          : true;
        const validYoutubeURL =
          currentSettings.description_video_url ?? ''
            ? true
            : isYoutubeURL(currentSettings.description_video_url ?? '');

        let isDataValid =
          areRoutinesDataValid &&
          Boolean(
            isWelcomeVideoUrlFieldValid &&
              validYoutubeURL &&
              isTextEditorWithinCharacterLimit &&
              currentSettings?.pack_name &&
              currentSettings?.description
          ) &&
          !foundInvalidActivityTime;

        if (mode === Mode.STANDALONE) {
          isDataValid =
            isDataValid &&
            Boolean(currentSettings.standalone_activities?.length);
        }
        dispatch(updateAreSettingsValid(isDataValid));
      } else if (mode === Mode.FOCUS_MODE) {
        validateFocusModeTemplate();
      } else {
        const isDataValid = areRoutinesDataValid && !foundInvalidActivityTime;
        dispatch(updateAreSettingsValid(isDataValid));
      }
    };
    checkDataValidation();
  }, [currentSettings, mode, currentViewedFocusTemplate]);

  const determineGeneratorBody = () => {
    if (!isSettingEditingFinished || isMobilePlatform()) {
      return <TabBody />;
    } else if (platform !== PLATFORMS.WEB) {
      return <GeneratingSettings />;
    } else if (mode === Mode.DEFAULT || mode === Mode.FREESTYLE) {
      return <Output />;
    } else {
      return <SharePack />;
    }
  };

  return (
    <>
      {determineGeneratorBody()}
      {!isSettingEditingFinished && <TabActions />}
      {(areSettingsSaving || isUserUpsertingPack) && (
        <OverlaySpinner title={t('saving_settings')} />
      )}
    </>
  );
};

export default Tabs;
