import { useEffect, useState, useCallback } from 'react';
import logo from 'assets/images/logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import Back from 'assets/icons/Back';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

export default function Success() {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const [message, setMessage] = useState(t('subscription_updated'));
  const navigate = useNavigate();

  const fetchUpdatedSubscription = useCallback(async () => {
    try {
      // ignoreCache:true forces an update of the token
      await getAccessTokenSilently({ ignoreCache: true });
    } catch (error) {
      setMessage(t('failed_to_get_updated_subscription'));
      Sentry.captureException(error);
    }
  }, [getAccessTokenSilently, t]);

  useEffect(() => {
    // After the user subscribed on Stripe Checkout they will be navigated to this page so that
    // their token can be replaced by an updated token with their new subscription status
    // in the payload data
    fetchUpdatedSubscription();
  }, [fetchUpdatedSubscription]);

  return (
    <div className='w-full h-[calc(100vh/2)] flex flex-col text-center justify-center items-center py-8'>
      <img className='w-[20%]' src={logo} alt='focus bear' />
      <h1 className='text-3xl text-orange-400 py-8'>{message}</h1>
      <button
        onClick={() => {
          navigate(`../${ROUTES.PROFILE}`);
        }}
        className='w-fit h-fit flex items-center gap-2 bg-gray-500 hover:bg-gray-600 rounded-md text-white text-sm sm:text-base 2xl:text-lg px-3 py-1'
      >
        <Back />
        {t('back')}
      </button>
    </div>
  );
}
