import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HABIT_SETTING_FEATURES,
  LOG_QUANTITY_QUESTION_FEATURES,
  MODAL_TYPES,
  TO_DO_SUB_TASK_WHERE
} from 'constants/general';
import {
  ActivityImagesDataType,
  LogQuantityAnswer,
  LogQuantityQuestion,
  LogQuestionDetailsType,
  TodoSubTasksPayload
} from 'interfaces';
import { DaysOfWeek } from 'constants/enum';
import { initialModalState } from 'store/initial-states';
import {
  getCompleteActivityLogQuestionsAnswers,
  generateSubTasks,
  getCompletedLogsByActivityInTimeRange
} from './extra';
import { ActivityType, ChoiceType } from 'interfaces/common.interface';
import { WeeklyFocusBlockSummary } from 'interfaces/user.interface';
import {
  KeywordModalType,
  DeleteKeywordModalType
} from 'interfaces/modal.interface';

const modal = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    updateMoreOptionActivity: (
      state,
      { payload }: PayloadAction<ActivityType>
    ) => {
      state.moreOption.activity = payload;
    },
    updateMoreOptionName: (state, { payload }: PayloadAction<string>) => {
      state.moreOption.activity.name = payload;
    },
    updateMoreOptionDuration: (state, { payload }: PayloadAction<number>) => {
      state.moreOption.activity.duration_seconds = payload;
    },
    updateMoreOptionCompletionRequirement: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.moreOption.activity.completion_requirements = payload;
    },
    updateMoreOptionURLs: (
      state,
      {
        payload: { modal_type, urls }
      }: PayloadAction<{ modal_type: string; urls: string[] }>
    ) => {
      switch (modal_type) {
        case MODAL_TYPES.YOUTUBE_URLS:
          state.moreOption.activity.video_urls = urls;
          break;
        case MODAL_TYPES.ALLOWED_URLS:
          state.moreOption.activity.allowed_urls = urls;
      }
    },
    updateMoreOptionShowYouTubeURLModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.showYouTubeURLModal = payload;
    },
    updateMoreOptionShowAllowedURLModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.showAllowedURLModal = payload;
    },
    updateMoreOptionShowChoicesModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.showChoicesModal = payload;
    },
    updateMoreOptionShowImageURLModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.showImageURLModal = payload;
    },
    updateMoreOptionAllowedApps: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.moreOption.activity.allowed_apps = payload;
    },
    updateMoreOptionFocusMode: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.moreOption.activity.allowed_focus_mode_id = payload;
      } else {
        delete state.moreOption.activity[
          HABIT_SETTING_FEATURES.FOCUS_MODE_ID as keyof ActivityType
        ];
      }
    },
    updateMoreOptionMicroBreak: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.activity.run_micro_breaks = payload;
    },
    updateMoreOptionLogSummaryType: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.moreOption.activity.log_summary_type = payload;
    },
    updateMoreOptionLogQuantity: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.activity.log_quantity = payload;
      if (payload) {
        state.moreOption.activity.log_quantity_questions = [];
      }
    },
    updateMoreOptionLogQuantityQuestions: (
      state,
      { payload }: PayloadAction<LogQuantityQuestion[]>
    ) => {
      state.moreOption.activity.log_quantity_questions = payload;
    },
    initLogQuantityAnswers: (
      state,
      { payload }: PayloadAction<LogQuantityAnswer[]>
    ) => {
      state.logQuantity.log_quantity_answers = payload;
    },
    updateLogQuantityAnswers: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{
        position: number;
        value: { question_id: string; logged_value: number | undefined };
      }>
    ) => {
      state.logQuantity.log_quantity_answers[position] = value;
    },
    updateMoreOptionLogQuantityQuestionFeatures: (
      state,
      {
        payload: { feature, position, value }
      }: PayloadAction<{
        feature: string;
        position: number;
        value: string | LogQuestionDetailsType;
      }>
    ) => {
      const logQuestions =
        state.moreOption.activity?.log_quantity_questions ?? [];
      if (logQuestions.length) {
        switch (feature) {
          case LOG_QUANTITY_QUESTION_FEATURES.QUESTION_DETAILS:
            logQuestions[position] = {
              ...logQuestions[position],
              ...(value as LogQuestionDetailsType),
              min_value: parseInt((value as LogQuestionDetailsType).min_value),
              max_value: parseInt((value as LogQuestionDetailsType).max_value)
            };
            break;
          default:
            logQuestions[position].question = value as string;
        }
      }
      state.moreOption.activity.log_quantity_questions = logQuestions;
    },
    updateMoreOptionAreLogQuestionsInvalid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.areLogQuestionsInvalid = payload;
    },
    updateMoreOptionTakeNotes: (state, { payload }: PayloadAction<string>) => {
      state.moreOption.activity.take_notes = payload;
    },
    updateMoreOptionIsOfficeFriendly: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.activity.is_office_friendly = payload;
    },
    updateMoreOptionIncludeEveryBreak: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.activity.include_in_every_break = payload;
    },
    updateMoreOptionTextInstructions: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.moreOption.activity.text_instructions = payload;
    },
    updateMoreOptionPriority: (state, { payload }: PayloadAction<string>) => {
      state.moreOption.activity.priority = payload;
    },
    updateMoreOptionCurrentCompetencyLevel: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.moreOption.activity.current_competency_level = payload;
    },
    updateMoreOptionDaysOfWeek: (
      state,
      { payload }: PayloadAction<DaysOfWeek[]>
    ) => {
      state.moreOption.activity.days_of_week = payload;
    },
    updateMoreOptionCategory: (state, { payload }: PayloadAction<string>) => {
      state.moreOption.activity.category = payload;
    },
    updateMoreOptionImageURLs: (
      state,
      { payload }: PayloadAction<ActivityImagesDataType[]>
    ) => {
      state.moreOption.activity.image_urls = payload;
    },
    updateMoreOptionChoiceType: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.moreOption.activity.choice_type = payload;
    },
    updateMoreOptionChoices: (
      state,
      { payload }: PayloadAction<ChoiceType[]>
    ) => {
      // log quantity should be false for activities that have choices
      if (payload.length > 0) {
        state.moreOption.activity.log_quantity = false;
      }
      state.moreOption.activity.choices = payload;
    },
    updateIsMoreOptionEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.moreOption.isMoreOptionEdited = payload;
    },
    updateMoreOptionShowSavedDistractingWebsites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.activity.show_saved_distracting_websites = payload;
    },
    updateMoreOptionImpactCategory: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.moreOption.activity.impact_category = payload;
    },
    updateMoreOptionTutorial: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.moreOption.activity.tutorial = payload;
    },
    updateMoreOptionsIsNewActivity: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.isNewActivity = payload;
    },
    updateMoreOptionActivityCutoffTime: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.moreOption.activity.cutoff_time_for_doing_activity = payload;
    },
    updateChoices: (state, { payload }: PayloadAction<ChoiceType[]>) => {
      state.choice.choices = payload;
    },
    updateMoreOptionActivityTags: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.moreOption.activity.tags = payload;
    },
    updateChoicesShowYouTubeURLModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.choice.showChoicesYouTubeURLModal = payload;
    },
    updateChoicesSelectedChoicePosition: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.choice.selectedPosition = payload;
    },
    updateChoicesName: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: string }>
    ) => {
      state.choice.choices[position].name = value;
    },
    updateChoicesDuration: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: number }>
    ) => {
      state.choice.choices[position].duration_seconds = value;
    },
    updateChoicesLogQuantity: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: boolean }>
    ) => {
      state.choice.choices[position].log_quantity = value;
    },
    updateChoicesLogQuantityQuestion: (
      state,
      {
        payload: { choicePosition, questionPosition, value, feature }
      }: PayloadAction<{
        choicePosition: number;
        questionPosition: number;
        value: string | LogQuestionDetailsType;
        feature: string;
      }>
    ) => {
      const logQuestions =
        state.choice.choices[choicePosition].log_quantity_questions ?? [];

      if (logQuestions.length) {
        switch (feature) {
          case LOG_QUANTITY_QUESTION_FEATURES.QUESTION_DETAILS:
            logQuestions[questionPosition] = {
              ...logQuestions[questionPosition],
              ...(value as LogQuestionDetailsType),
              min_value: parseInt((value as LogQuestionDetailsType).min_value),
              max_value: parseInt((value as LogQuestionDetailsType).max_value)
            };
            break;
          default:
            logQuestions[questionPosition].question = value as string;
        }
      }
      state.choice.choices[choicePosition].log_quantity_questions =
        logQuestions;
    },
    updateChoicesLogQuantityQuestions: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: LogQuantityQuestion[] }>
    ) => {
      state.choice.choices[position].log_quantity_questions = data;
    },
    updateChoicesLogSummary: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: string }>
    ) => {
      state.choice.choices[position].log_summary_type = data;
    },
    updateChoicesVideoURLs: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: string[] }>
    ) => {
      state.choice.choices[position].video_urls = value;
    },
    updateChoicesCompletionRequirements: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: string }>
    ) => {
      state.choice.choices[position].completion_requirements = value;
    },
    updateChoicesCompetencyLevel: (
      state,
      {
        payload: { position, value }
      }: PayloadAction<{ position: number; value: number }>
    ) => {
      state.choice.choices[position].competency_level = value;
    },
    updateChoicesAreLogQuestionsInvalid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.choice.areLogQuestionsInvalid = payload;
    },
    updateNoteText: (state, { payload }: PayloadAction<string | undefined>) => {
      state.note.text = payload;
    },
    updateNoteShowNoteModal: (state, { payload }: PayloadAction<boolean>) => {
      state.note.showNoteModal = payload;
    },
    updateNoteIsCurrentActivityPlaying: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.note.isCurrentActivityPlaying = payload;
    },
    updateShowLogQuantityModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.logQuantity.showLogQuantityModal = payload;
    },
    updateLogQuantityAreLogDataValid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.logQuantity.areLogDataValid = payload;
    },
    updateIsChoiceEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.choice.isChoiceEdited = payload;
    },
    updateMoreOptionShowCheckListModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.showCheckListModal = payload;
    },
    updateMoreOptionCheckLists: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.moreOption.activity.check_list = payload;
    },
    updateLogQuantityShowQuestionDetailsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.logQuantity.showQuestionDetailsModal = payload;
    },
    updateMoreOptionLogQuantityDetails: (
      state,
      {
        payload
      }: PayloadAction<{ logQuestion: LogQuantityQuestion; position: number }>
    ) => {
      state.logQuantityDetails = payload;
      state.logQuantity.showQuestionDetailsModal = true;
    },
    updateAddToCalendarShowAddToCalendarModalOpened: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.addToCalendar.showAddToCalendarModalOpened = payload;
    },
    updateAddToCalendarActivity: (
      state,
      { payload }: PayloadAction<ActivityType>
    ) => {
      state.addToCalendar.activity = payload;
    },
    updateFocusSummaryGraphShowFocusSummaryGraphModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.focusSummaryGraph.showFocusSummaryGraphModal = payload;
    },
    updateEisenhowerMatrixIsEisenhowerMatrixModalOpened: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.eisenhowerMatrix.isEisenhowerMatrixModalOpened = payload;
    },
    updateRoutineActivitiesStatsShowRoutineActivitiesStatsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      if (payload) {
        state.routineActivitiesStats.summaries = [];
      }
      state.routineActivitiesStats.showRoutineActivitiesStatsModal = payload;
    },
    updateRoutineActivitiesStatsIsRoutineStatsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.routineActivitiesStats.isRoutineStatsLoading = payload;
    },
    resetRoutineActivitiesStatsSummaries: (state) => {
      state.routineActivitiesStats.summaries = [];
    },
    updateTodoSubTasks: (
      state,
      {
        payload: {
          subTasks,
          isNewTask,
          title,
          taskId,
          where = TO_DO_SUB_TASK_WHERE.DASHBOARD
        }
      }: PayloadAction<TodoSubTasksPayload>
    ) => {
      state.toDoSubTasks.subTasks = subTasks;
      state.toDoSubTasks.isNewTask = isNewTask ?? false;
      if (taskId) {
        state.toDoSubTasks.taskId = taskId;
      }
      if (title) {
        state.toDoSubTasks.task = title;
      }
      state.toDoSubTasks.where = where;
    },
    updateToDoSubTaskName: (
      state,
      { payload: { pos, value } }: PayloadAction<{ pos: number; value: string }>
    ) => {
      state.toDoSubTasks.subTasks[pos].name = value;
    },
    deleteToDoSubTask: (state, { payload }: PayloadAction<number>) => {
      state.toDoSubTasks.subTasks = state.toDoSubTasks.subTasks.filter(
        (_, index) => index !== payload
      );
    },
    updateIsGeneratingSubtasks: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.toDoSubTasks.isGeneratingSubtasks = payload;
    },
    updateIsUpdatingSubTaskStatus: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.toDoSubTasks.isUpdatingSubTaskStatus = payload;
    },
    updateConfirmActionModal: (
      state,
      {
        payload
      }: PayloadAction<{
        showConfirmModal: boolean;
        confirmTitle: string;
        cancelTitle?: string;
        description?: string;
        modalType: string;
        taskId?: string;
      }>
    ) => {
      state.confirmAction = payload;
    },
    updateFocusSummaryMore: (
      state,
      {
        payload
      }: PayloadAction<{
        showFocusSummaryMore: boolean;
        focusSummary: WeeklyFocusBlockSummary | null;
      }>
    ) => {
      state.focusSummaryMore = payload;
    },
    updateShowKeywordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.keywordModal.isKeywordModalOpened = payload;
    },
    updateKeywordModaldata: (
      state,
      { payload }: PayloadAction<KeywordModalType>
    ) => {
      state.keywordModal = payload;
    },
    resetKeywordModalData: (state, { payload }: PayloadAction<string>) => {
      state.keywordModal.id = '';
      state.keywordModal.keyword = '';
      state.keywordModal.title = false;
      state.keywordModal.description = false;
    },
    updateDeleteKeywordModalData: (
      state,
      { payload }: PayloadAction<DeleteKeywordModalType>
    ) => {
      const { id, keyword } = payload;
      state.deleteKeywordModal.id = id;
      state.deleteKeywordModal.keyword = keyword;
    },
    updateIsLongTermGoalsModalOpened: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.longTermGoalsModal.isLongTermGoalsModalOpened = payload;
    },
    updateMoreOptionIsUseApplicationsChecked: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.isUseApplicationsChecked = payload;
    },
    updateMoreOptionIsUseWebsiteChecked: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.isUseWebsiteChecked = payload;
    },
    updateMoreOptionIsUseFocusModeChecked: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.moreOption.isUseFocusModeChecked = payload;
    },
    resetMoreOptionAllowedAppsTab: (state) => {
      state.moreOption.isUseApplicationsChecked = false;
      state.moreOption.isUseWebsiteChecked = false;
      state.moreOption.isUseFocusModeChecked = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        getCompleteActivityLogQuestionsAnswers.fulfilled,
        (state, { payload }) => {
          state.routineActivitiesStats.summaries = payload;
        }
      )
      .addCase(generateSubTasks.fulfilled, (state, { payload }) => {
        state.toDoSubTasks.areSubTasksGenerated = Boolean(
          payload?.subtasks?.length
        );
        state.toDoSubTasks.subTasks = payload?.subtasks ?? [];
        state.toDoSubTasks.task = payload?.task;
        state.toDoSubTasks.isGeneratingSubtasks = false;
      })
      .addCase(
        getCompletedLogsByActivityInTimeRange.fulfilled,
        (state, { payload }) => {
          state.routineActivitiesStats.summaries = payload;
        }
      );
  }
});

export const {
  updateMoreOptionActivity,
  updateMoreOptionName,
  updateMoreOptionDuration,
  updateMoreOptionURLs,
  updateMoreOptionShowYouTubeURLModal,
  updateMoreOptionShowAllowedURLModal,
  updateMoreOptionShowChoicesModal,
  updateMoreOptionShowImageURLModal,
  updateMoreOptionAllowedApps,
  updateMoreOptionFocusMode,
  updateMoreOptionMicroBreak,
  updateMoreOptionLogSummaryType,
  updateMoreOptionLogQuantity,
  updateMoreOptionLogQuantityQuestions,
  initLogQuantityAnswers,
  updateLogQuantityAnswers,
  updateMoreOptionLogQuantityQuestionFeatures,
  updateMoreOptionAreLogQuestionsInvalid,
  updateMoreOptionTakeNotes,
  updateMoreOptionIsOfficeFriendly,
  updateMoreOptionIncludeEveryBreak,
  updateMoreOptionTextInstructions,
  updateMoreOptionPriority,
  updateMoreOptionDaysOfWeek,
  updateMoreOptionCategory,
  updateMoreOptionImageURLs,
  updateMoreOptionChoiceType,
  updateMoreOptionChoices,
  updateIsMoreOptionEdited,
  updateChoices,
  updateChoicesShowYouTubeURLModal,
  updateChoicesName,
  updateChoicesDuration,
  updateChoicesLogQuantity,
  updateChoicesLogQuantityQuestion,
  updateChoicesVideoURLs,
  updateChoicesSelectedChoicePosition,
  updateChoicesCompletionRequirements,
  updateChoicesCompetencyLevel,
  updateNoteText,
  updateNoteShowNoteModal,
  updateNoteIsCurrentActivityPlaying,
  updateShowLogQuantityModal,
  updateMoreOptionCurrentCompetencyLevel,
  updateMoreOptionCompletionRequirement,
  updateLogQuantityAreLogDataValid,
  updateChoicesLogQuantityQuestions,
  updateChoicesAreLogQuestionsInvalid,
  updateChoicesLogSummary,
  updateIsChoiceEdited,
  updateMoreOptionShowCheckListModal,
  updateMoreOptionCheckLists,
  updateLogQuantityShowQuestionDetailsModal,
  updateMoreOptionLogQuantityDetails,
  updateAddToCalendarShowAddToCalendarModalOpened,
  updateAddToCalendarActivity,
  updateMoreOptionShowSavedDistractingWebsites,
  updateFocusSummaryGraphShowFocusSummaryGraphModal,
  updateEisenhowerMatrixIsEisenhowerMatrixModalOpened,
  updateRoutineActivitiesStatsShowRoutineActivitiesStatsModal,
  updateRoutineActivitiesStatsIsRoutineStatsLoading,
  updateMoreOptionImpactCategory,
  resetRoutineActivitiesStatsSummaries,
  updateTodoSubTasks,
  updateToDoSubTaskName,
  deleteToDoSubTask,
  updateIsGeneratingSubtasks,
  updateIsUpdatingSubTaskStatus,
  updateConfirmActionModal,
  updateFocusSummaryMore,
  updateKeywordModaldata,
  updateShowKeywordModal,
  resetKeywordModalData,
  updateDeleteKeywordModalData,
  updateIsLongTermGoalsModalOpened,
  updateMoreOptionTutorial,
  updateMoreOptionActivityCutoffTime,
  updateMoreOptionsIsNewActivity,
  updateMoreOptionActivityTags,
  updateMoreOptionIsUseApplicationsChecked,
  updateMoreOptionIsUseFocusModeChecked,
  updateMoreOptionIsUseWebsiteChecked,
  resetMoreOptionAllowedAppsTab
} = modal.actions;

export default modal.reducer;
