import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchUsersOrderByOptions, OPERATING_SYSTEM } from 'constants/enum';
import {
  createSurvey,
  getActivitiesByUserIdOrStripeIdOrActivityType,
  getSurveys,
  getUserByIdOrStripeIdOrEmail,
  getUserDevices,
  getUserInfoAndFocusModes,
  getUsers
} from './extra';
import {
  FIRST_DEVICE_INFO_INDEX,
  NO_ELEMENT_FOUND,
  NUMBERS,
  PAGINATE_ITEMS
} from 'constants/general';
import { initialAdminState } from 'store/initial-states';
import { increment } from 'utils/helpers';
import { DEFAULT_PLATFORM_DEVICES } from 'assets/data';
import { DBUser } from 'interfaces/common.interface';
import { DeviceInfo, SearchByPayload, SurveyType } from 'interfaces';

const admin = createSlice({
  name: 'admin',
  initialState: initialAdminState,
  reducers: {
    getNextPage: (state) => {
      state.skip = state.skip + PAGINATE_ITEMS;
    },
    getPrevPage: (state) => {
      state.skip = state.skip - PAGINATE_ITEMS;
    },
    clearFocusModesAndActivities: (state) => {
      state.focusModes = [];
      state.userActivities = [];
    },
    updateOrderBy: (
      state,
      { payload }: PayloadAction<FetchUsersOrderByOptions>
    ) => {
      state.orderBy = payload;
    },
    updateIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    resetPagination: (state) => {
      state.skip = NUMBERS.ZERO;
    },
    updateIsFetchingActivities: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingActivities = payload;
    },
    updateIsFetchingFocusModes: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingFocusModes = payload;
    },
    updateUserActivitiesFilter: (state, { payload }: PayloadAction<string>) => {
      state.userActivitiesFilter = payload;
    },
    updateActivityOrFocusModeSearchResult: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.activityOrFocusModeSearchResult = payload;
    },
    updateShowRequestDetailsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showRequestDetailsModal = payload;
    },
    updateAccessRequestedUserId: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.accessRequestedUserId = payload;
    },
    updateIsAccessRequesting: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccessRequesting = payload;
    },
    updateIsAccessRequestGranted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAccessRequestGranted = payload;
    },
    updateSearchBy: (state, { payload }: PayloadAction<SearchByPayload>) => {
      state.searchBy = payload;
    },
    updateIsSearchByValid: (
      state,
      {
        payload
      }: PayloadAction<{
        user_id: boolean;
        stripe_customer_id: boolean;
        email: boolean;
      }>
    ) => {
      state.isSearchByValid = payload;
    },
    updateIsFetchingUserDetails: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingUserDetails = payload;
    },
    updateIsDeviceFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeviceFetching = payload;
    },
    updateIsCreatingSurvey: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingSurvey = payload;
    },
    updateIsUpdatingSurvey: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingSurvey = payload;
    },
    updateIsFetchingSurveys: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingSurveys = payload;
    },
    updateShowSurveyDetailModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showSurveyDetailModal = payload;
    },
    updateSelectedSurvey: (
      state,
      { payload }: PayloadAction<SurveyType | null>
    ) => {
      state.selectedSurvey = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        const defaultPlatformDevices =
          payload?.data?.map((user: DBUser) => ({
            ...user,
            devices: DEFAULT_PLATFORM_DEVICES
          })) ?? [];
        if (payload?.shouldAppend) {
          state.users.push(...defaultPlatformDevices);
        } else {
          state.users = defaultPlatformDevices;
        }
        state.isLoading = false;
      })
      .addCase(getUserInfoAndFocusModes.fulfilled, (state, { payload }) => {
        state.focusModes = payload?.focus_modes ?? [];
        state.isFetchingFocusModes = false;
      })
      .addCase(
        getActivitiesByUserIdOrStripeIdOrActivityType.fulfilled,
        (state, { payload }) => {
          state.userActivities = payload;
          state.isFetchingActivities = false;
        }
      )
      .addCase(getUserByIdOrStripeIdOrEmail.fulfilled, (state, { payload }) => {
        state.users = payload ?? [];
        state.isFetchingUserDetails = false;
      })
      .addCase(getUserDevices.fulfilled, (state, { payload }) => {
        const osMap = {
          [OPERATING_SYSTEM.ANDROID]: 'android',
          [OPERATING_SYSTEM.IOS]: 'iOS',
          [OPERATING_SYSTEM.MAC]: 'macOS',
          [OPERATING_SYSTEM.WIN]: 'windows'
        };
        const index =
          payload?.length &&
          state.users.findIndex(
            (user) => user.id === payload?.[FIRST_DEVICE_INFO_INDEX]?.user_id
          );
        if (index !== NO_ELEMENT_FOUND) {
          const devices = { ...state.users[index].devices };
          payload?.forEach((device_info: DeviceInfo) => {
            const os = device_info.operating_system as keyof typeof osMap;
            const osKey = osMap[os] as keyof typeof devices;
            if (osKey) {
              devices[osKey] = increment(devices[osKey]);
            }
          });
          state.users[index].devices = devices;
        }
        state.isDeviceFetching = false;
      })
      .addCase(createSurvey.fulfilled, (state, { payload }) => {
        state.isCreatingSurvey = false;
      })
      .addCase(getSurveys.fulfilled, (state, { payload }) => {
        state.surveys.push(...payload);
        state.isFetchingSurveys = false;
      });
  }
});

export const {
  getNextPage,
  getPrevPage,
  clearFocusModesAndActivities,
  updateOrderBy,
  updateIsLoading,
  resetPagination,
  updateIsFetchingActivities,
  updateIsFetchingFocusModes,
  updateUserActivitiesFilter,
  updateActivityOrFocusModeSearchResult,
  updateShowRequestDetailsModal,
  updateAccessRequestedUserId,
  updateIsAccessRequesting,
  updateIsAccessRequestGranted,
  updateIsFetchingUserDetails,
  updateIsSearchByValid,
  updateSearchBy,
  updateIsDeviceFetching,
  updateIsCreatingSurvey,
  updateIsFetchingSurveys,
  updateIsUpdatingSurvey,
  updateShowSurveyDetailModal,
  updateSelectedSurvey
} = admin.actions;

export default admin.reducer;
