import DropDown from 'components/common/DropDown';
import { PRIORITY_OPTION } from 'constants/general';
import { DropDownSelectedValue } from 'interfaces';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateMoreOptionActivityCutoffTime,
  updateMoreOptionPriority
} from 'store/reducer/modal/slice';

const Priority = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const activity = useAppSelector((state) => state.modal.moreOption.activity);
  const isGuiltFreeActivity = useMemo(
    () => activity.show_saved_distracting_websites,
    [activity]
  );

  const priority = useMemo(
    () =>
      (activity?.priority ?? PRIORITY_OPTION.HIGH) === PRIORITY_OPTION.HIGH &&
      !isGuiltFreeActivity
        ? { label: t('must_do'), value: PRIORITY_OPTION.HIGH }
        : { label: t('optional'), value: PRIORITY_OPTION.STANDARD },

    [isGuiltFreeActivity, activity?.priority]
  );

  return (
    <DropDown
      isDisabled={isGuiltFreeActivity}
      title={t('priority')}
      value={priority}
      handleChange={(data) => {
        const result = data as SingleValue<DropDownSelectedValue>;
        result && dispatch(updateMoreOptionPriority(result.value));
        result?.value === PRIORITY_OPTION.STANDARD &&
          dispatch(updateMoreOptionActivityCutoffTime(undefined));
      }}
      options={[
        { label: t('must_do'), value: PRIORITY_OPTION.HIGH },
        { label: t('optional'), value: PRIORITY_OPTION.STANDARD }
      ]}
      containerStyles='min-w-[150px] w-full sm:w-fit ~text-xs/base'
    />
  );
};

export default React.memo(Priority);
