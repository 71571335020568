import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import lazyDelayedImport from 'utils/lazyDelayedImport';
import IntegrationCallback from 'dashboard/to-do/integrations/IntegrationCallback';
import Layout from 'dashboard/layout';
import Login from 'pages/auth/Login';
import Success from 'pages/other/Success';
import NotFoundPage from 'pages/other/NotFoundPage';
import ErrorPage from 'pages/other/ErrorPage';
import ForbiddenPage from 'pages/other/ForbiddenPage';

const TeamAcceptInvitation = lazyDelayedImport(
  () => import('pages/team/TeamAcceptInvitation')
);
const TeamPage = lazyDelayedImport(() => import('pages/team'));
const Home = lazyDelayedImport(() => import('pages/home'));
const Subscription = lazyDelayedImport(() => import('pages/subscription'));
const Motivation = lazyDelayedImport(() => import('pages/motivation'));
const ProfilePage = lazyDelayedImport(() => import('pages/profile'));
const StatsPage = lazyDelayedImport(() => import('pages/stats'));
const SettingsPage = lazyDelayedImport(() => import('pages/settings'));
const MarketPage = lazyDelayedImport(() => import('pages/marketplace'));
const HabitPackDetailsPage = lazyDelayedImport(
  () => import('pages/habit-pack-details-page')
);
const MyPacksAndFocusTemplates = lazyDelayedImport(
  () => import('pages/my-packs-and-focus-templates')
);
const SharePackPage = lazyDelayedImport(
  () => import('pages/marketplace/SharePackPage')
);
const PackWelcomePage = lazyDelayedImport(
  () => import('pages/marketplace/PackWelcomePage')
);
const Admin = lazyDelayedImport(() => import('pages/admin'));
const FocusTemplateDetailsPage = lazyDelayedImport(
  () => import('pages/marketplace/FocusTemplateDetailsPage')
);
const FocusTemplateWelcomePage = lazyDelayedImport(
  () => import('pages/marketplace/FocusTemplateWelcomePage')
);
const RoutinePlayer = lazyDelayedImport(() => import('pages/routinePlayer'));
const ChatBot = lazyDelayedImport(() => import('pages/chat-bot'));
const ToDoProcrastinate = lazyDelayedImport(() => import('pages/to-do'));

const AppRoutes = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route
        path={ROUTES.HOME}
        element={isAuthenticated ? <Layout /> : <Navigate to={ROUTES.LOGIN} />}
      >
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
        <Route path={ROUTES.CHAT} element={<ChatBot />} />
        <Route path={`${ROUTES.TEAM}/:id`} element={<TeamPage />} />
        <Route path={ROUTES.STATS} element={<StatsPage />} />
        <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />
        <Route path={ROUTES.MARKETPLACE} element={<MarketPage />} />
        <Route
          path={ROUTES.HABIT_PACK_DETAILS}
          element={<HabitPackDetailsPage />}
        />
        <Route
          path={ROUTES.FOCUS_TEMPLATE_DETAILS}
          element={<FocusTemplateDetailsPage />}
        />
        <Route
          path={ROUTES.HABIT_PACK_DASHBOARD}
          element={<PackWelcomePage />}
        />
        <Route
          path={ROUTES.FOCUS_TEMPLATE_DASHBOARD}
          element={<FocusTemplateWelcomePage />}
        />
        <Route
          path={ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES}
          element={<MyPacksAndFocusTemplates />}
        />
        <Route path={ROUTES.SHARE_PACK} element={<SharePackPage />} />
        <Route path={ROUTES.SUCCESS} element={<Success />} />
        <Route path={ROUTES.ADMIN} element={<Admin />} />
        <Route path={ROUTES.ROUTINE_PLAYER} element={<RoutinePlayer />} />
        <Route path={ROUTES.MOTIVATIONAL_SUMMARY} element={<Motivation />} />
        <Route path={ROUTES.TODO} element={<ToDoProcrastinate />} />
        <Route path={ROUTES.TEAM} element={<TeamPage />} />
        <Route
          path={ROUTES.PLATFORM_CALLBACK}
          element={<IntegrationCallback />}
        />
        <Route path={ROUTES.MANAGE_SUBSCRIPTION} element={<Subscription />} />
      </Route>
      <Route path={ROUTES.ACCEPT_INVITE} element={<TeamAcceptInvitation />} />
      <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={ROUTES.ERROR} element={<ErrorPage />} />
      <Route path={ROUTES.FORBIDDEN} element={<ForbiddenPage />} />
    </Routes>
  );
};

export default AppRoutes;
