import Tooltip from 'components/common/Tooltip';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { themeSelector, timingSelector } from 'store/reducer/setting/selectors';
import { changeBreakingInterval } from 'store/reducer/setting/slice';
import { isDarkModeActivated } from 'utils/validators';

const BreakFrequency = () => {
  const dispatch = useAppDispatch();
  const { break_after_minutes } = useAppSelector(timingSelector);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );

  const hasError = () => {
    if (break_after_minutes || break_after_minutes === 0) {
      return [break_after_minutes < 1, t('errors.break_time')];
    } else {
      return [true, t('errors.empty_time')];
    }
  };

  return (
    <div className='w-full flex gap-x-10 gap-y-2 flex-wrap'>
      <div className='w-fit flex items-center gap-1 '>
        <p className='text-sm sm:text-base font-medium'>
          {t('break_frequency')}
        </p>
        <Tooltip message={t('explanation_break_interval')} place='top' />
      </div>
      <div className='w-28 flex flex-col gap-1 relative'>
        <input
          className={`w-full py-1 px-2 text-center rounded-md text-xs sm:text-sm font-medium ${
            shouldActivateDarkMode
              ? 'bg-gray-400'
              : 'bg-focusBear focus:bg-white'
          } border outline-none text-black border-gray-400 z-0`}
          type='number'
          min={1}
          value={parseInt(String(break_after_minutes ?? ''))}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(changeBreakingInterval(parseInt(value)));
          }}
        />
        {hasError()[0] ? (
          <div className='absolute -bottom-4 left-0 min-w-max text-right text-xs text-red-500 font-medium'>
            {hasError()[1]}
          </div>
        ) : (
          <div className='absolute -bottom-4 right-0 text-xs font-medium'>
            {t('minutes')}
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakFrequency;
