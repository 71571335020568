import focusBearApi from '../services/axios-config';
import Endpoints from 'constants/endpoints';
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PRICE,
  SUBSCRIPTION_PRICE_ID
} from 'constants/general';
import i18n from 'services/i18n';
import { Entitlement } from 'interfaces/initial-states.interface';

export const getStripeCheckoutURL = async (
  price_id: string,
  team_name?: string
) => {
  const response = await focusBearApi.post(
    Endpoints.STRIPE.CREATE_CHECKOUT_SESSION,
    { price_id, team_name }
  );
  window.location.href = response.data.url;
};

export const getSubscriptionManagementPortal = async () => {
  const response = await focusBearApi.post(
    Endpoints.STRIPE.CREATE_PORTAL_SESSION
  );
  window.location.href = response.data.url;
};

export const isTrialOrPromoActive = (entitlements: Entitlement[]) =>
  Object.values(entitlements ?? []).every(
    (entitlement) =>
      entitlement.product_identifier.includes('trial') ||
      entitlement.product_identifier.includes('promo')
  );

export const getSubscriptionPlanInfo = (planId: string) => {
  if (planId === SUBSCRIPTION_PLAN.TEAM) {
    return {
      title: i18n.t('subscription_plan.team_subscription'),
      description: i18n.t('subscription_plan.team_description'),
      features: [
        i18n.t('subscription_plan.add_remove_users'),
        i18n.t('subscription_plan.productive_yet_private'),
        i18n.t('subscription_plan.project_management_integrations'),
        i18n.t('subscription_plan.team_leader_board')
      ],
      price: {
        amount: SUBSCRIPTION_PRICE.TEAM,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.TEAM
    };
  } else if (planId === SUBSCRIPTION_PLAN.ORGANIZATION_LICENSES) {
    return {
      title: 'Focus Bear organisation license for 5 years for 180 licenses',
      description:
        'Includes access to desktop and mobile apps for 180 licenses for 5 years',
      features: [],
      price: {
        amount: SUBSCRIPTION_PRICE.ORGANIZATION,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.ORGANIZATION
    };
  } else if (planId === SUBSCRIPTION_PLAN.PERSONAL_ANNUAL) {
    return {
      title: 'Personal annual subscription',
      description: '',
      features: [],
      price: {
        amount: SUBSCRIPTION_PRICE.PERSONAL_ANNUAL,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.PERSONAL_ANNUAL
    };
  }
  return {
    title: i18n.t('subscription_plan.personal_subscription'),
    description: i18n.t('subscription_plan.personal_description'),
    features: [
      i18n.t('subscription_plan.unlimited_ai_assisted_focus_modes'),
      i18n.t('subscription_plan.unlimited_pomodoro_modes'),
      i18n.t('subscription_plan.unlimited_meeting_modes'),
      i18n.t('subscription_plan.unlimited_automatic_goal_tracking')
    ],
    price: {
      amount: SUBSCRIPTION_PRICE.PERSONAL_MONTHLY,
      postfix: i18n.t('subscription_plan.personal_plan_postfix')
    },
    price_id: SUBSCRIPTION_PRICE_ID.PERSONAL_MONTHLY
  };
};
