import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import DocumentMetaData from 'components/DocumentMetaData';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { PAGES, ROUTES } from 'constants/routes';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export default function ForbiddenPage() {
  const navigate = useNavigate();
  return (
    <section className='flex flex-col items-center justify-center gap-10 min-h-screen bg-main bg-whiteLace text-center p-4'>
      <DocumentMetaData pageName={PAGES.FORBIDDEN} />
      <h1 className='~text-6xl/8xl font-bold text-sunRayFirst my-8'>403</h1>
      <div className='flex flex-col gap-3'>
        <h2 className='~text-2xl/4xl font-semibold text-sunRayFirst'>
          {t('access_forbidden')}
        </h2>
        <p className='~text-base/lg text-gray-600'>
          {t('you_don_t_have_permission_to_view_this_page')}
        </p>
      </div>
      <ButtonFocusBear
        onClick={() => navigate(ROUTES.HOME, { replace: true })}
        title={t('back_to_homepage')}
        variant={BTN_FB_VARIANT.PRIMARY}
        size={BTN_FB_SIZE.LARGE}
      />
    </section>
  );
}
