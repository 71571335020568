import InputTime from 'components/common/inputs/InputTime';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cutoffTimeForNonHighPriorityActivitiesSelector } from 'store/reducer/setting/selectors';
import { updateCutoffTimeForNonHighPriority } from 'store/reducer/setting/slice';
import { v4 as uuid } from 'uuid';

const EveningRoutineStartTime = () => {
  const dispatch = useAppDispatch();
  const {
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation: { isValid, errorMessages }
  } = useAppSelector(cutoffTimeForNonHighPriorityActivitiesSelector);
  const [resetKey, setResetKey] = useState(uuid()); // Unique key to forcefully reset the input when any value is cleared

  return (
    <InputTime
      key={resetKey}
      selectedDate={cutoff_time_for_non_high_priority_activities ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateCutoffTimeForNonHighPriority(value));
        if (!value) {
          setResetKey(uuid());
        }
      }}
      errorMessages={errorMessages.cutoffTime}
      wrapperStyles='inline-flex ml-2.5'
      validateTime={() => !isValid.cutoffTime}
    />
  );
};

export default EveningRoutineStartTime;
