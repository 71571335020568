import { IconProps } from 'interfaces';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validators';
import { themeSelector } from 'store/reducer/setting/selectors';

const Dropdown = ({
  styles = 'w-5 h-5',
  fill = COLOR.GRAY_700,
  ...rest
}: IconProps) => {
  return (
    <svg
      className={styles}
      fill={
        isDarkModeActivated(useAppSelector(themeSelector)) ? COLOR.WHITE : fill
      }
      viewBox='-6.5 0 32 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M18.813 11.406l-7.906 9.906c-0.75 0.906-1.906 0.906-2.625 0l-7.906-9.906c-0.75-0.938-0.375-1.656 0.781-1.656h16.875c1.188 0 1.531 0.719 0.781 1.656z'></path>{' '}
      </g>
    </svg>
  );
};

export default Dropdown;
