import classNames from 'classnames';
import COLOR from 'constants/color';
import { REACT_SELECT_DROPDOWN } from 'constants/general';
import useDebouncedResize from 'hooks/useDebouncedResize';
import { DropDownSelectedValue } from 'interfaces';
import { memo, useMemo } from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';
import { isDarkThemeActivated } from 'utils/settings.util';
import { isEmbeddedTodoList } from 'utils/validators';

type SelectedValueType = DropDownSelectedValue | DropDownSelectedValue[];
interface DropDownProps {
  title?: string;
  value?: SelectedValueType;
  options: DropDownSelectedValue[];
  handleChange: (data?: SelectedValueType) => void;
  isClearable?: boolean;
  containerStyles?: string;
  labelStyles?: string;
  hasError?: boolean;
  ErrorMessage?: JSX.Element;
  isDisabled?: boolean;
  zIndex?: number;
  placeholder?: string;
  isMulti?: boolean;
  isLoading?: boolean;
  defaultMenuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
}

const getDropDownStyles = (
  zIndex: number,
  isMulti: boolean,
  shouldActivateDarkMode: boolean
) => ({
  menuPortal: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex,
    fontSize: REACT_SELECT_DROPDOWN.FONT_SIZE,
    color: COLOR.BLACK
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'pointer'
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE
  }),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_700 : COLOR.GRAY_4OO
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE,
    color: isMulti && shouldActivateDarkMode ? COLOR.WHITE : COLOR.BLACK
  }),
  singleValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_800 : COLOR.GRAY_4OO
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_700 : COLOR.GRAY_3OO
  }),
  multiValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.WHITE : COLOR.BLACK
  }),
  clearIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE,
    color: shouldActivateDarkMode ? COLOR.GRAY_3OO : COLOR.GRAY_4OO,
    cursor: 'pointer',
    ':hover': {
      color: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_5OO
    }
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'pointer',
    color: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_5OO,
    ':hover': {
      color: shouldActivateDarkMode ? COLOR.GRAY_800 : COLOR.GRAY_700
    }
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.GRAY_600,
    ':hover': {
      backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_4OO,
      color: shouldActivateDarkMode ? COLOR.WHITE : COLOR.GRAY_800
    }
  })
});

const DropDown = ({
  title,
  value,
  options,
  handleChange,
  isClearable = false,
  containerStyles,
  labelStyles,
  hasError,
  ErrorMessage,
  isDisabled = false,
  zIndex = REACT_SELECT_DROPDOWN.Z_INDEX.DEFAULT,
  placeholder,
  isMulti = false,
  isLoading = false,
  defaultMenuIsOpen = false,
  closeMenuOnSelect
}: DropDownProps) => {
  const [shouldEmbeddedFocusIU, shouldActivateDarkTheme, styles] = useMemo(
    () => [
      isEmbeddedTodoList(window.location.pathname),
      isDarkThemeActivated(),
      getDropDownStyles(zIndex, isMulti, isDarkThemeActivated())
    ],
    []
  );

  useDebouncedResize();

  return (
    <div
      className={classNames({
        [`${containerStyles}`]: Boolean(containerStyles),
        'flex flex-col': !containerStyles,
        'text-white': shouldActivateDarkTheme && !containerStyles,
        'text-black': !shouldActivateDarkTheme && !containerStyles,
        'w-full md:w-[20vw]': shouldEmbeddedFocusIU && !containerStyles,
        'w-full md:w-1/2': !shouldEmbeddedFocusIU && !containerStyles
      })}
    >
      {title ? (
        <p
          className={`${
            labelStyles ?? 'w-full truncate ~text-xs/sm font-medium mb-0.5'
          } ${shouldActivateDarkTheme ? 'text-white' : 'text-black'}`}
        >
          {title}
          {hasError ? ErrorMessage : null}
        </p>
      ) : null}
      <Select
        isDisabled={isDisabled}
        className='w-full flex flex-col gap-2 md:gap-4z'
        value={value}
        menuPortalTarget={document.body}
        styles={styles}
        options={options}
        onChange={(data) => {
          const selectedDays = data as SelectedValueType;
          handleChange(selectedDays);
        }}
        isClearable={isClearable}
        isSearchable={false}
        placeholder={placeholder}
        isMulti={isMulti}
        isLoading={isLoading}
        openMenuOnClick={true}
        defaultMenuIsOpen={defaultMenuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect ?? true}
      />
    </div>
  );
};

export default memo(DropDown);
