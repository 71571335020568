import { DEFAULT_CONFIRM_ACTION_MODAL } from 'assets/data';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { LOCAL_STORAGE, MODAL_TYPES } from 'constants/general';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  deleteFocusTemplate,
  removeFocusTemplateFromMarketPlace
} from 'store/reducer/focus-mode/extra';
import { updateConfirmActionModal } from 'store/reducer/modal/slice';
import { updateIsUserLoggingOut } from 'store/reducer/user/slice';
import { removeLocalStorage } from 'utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import {
  deleteHabitPack,
  removePackFromMarketplace
} from 'store/reducer/habit-pack/extra';
import { deleteKeyword } from 'store/reducer/to-do/extra';
import { t } from 'i18next';
import ButtonFocusBear from './buttons/ButtonFocusBear';
import { BTN_FB_VARIANT } from 'constants/enum';
import { updatePlayerTasks } from 'store/reducer/to-do/slice';
import { usePostHog } from 'posthog-js/react';

const ConfirmActionModal = () => {
  const postHog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const {
    modal: {
      confirmAction: {
        cancelTitle,
        confirmTitle,
        description,
        modalType,
        taskId
      }
    },
    focusMode: { currentViewedFocusTemplate, isFocusTemplateDeleting },
    habitPack: { currentViewedPack, isHabitPacksDeleting, isUserUpsertingPack },
    setting: { areSettingsSaving },
    modal: {
      deleteKeywordModal: { id, platform }
    },
    toDo: {
      player: { tasks }
    }
  } = useAppSelector((state) => state);

  const handleConfirm = async () => {
    if (
      modalType === MODAL_TYPES.CONFIRM_ACTION_DELETE_FOCUS_TEMPLATE &&
      currentViewedFocusTemplate?.id
    ) {
      await dispatch(deleteFocusTemplate(currentViewedFocusTemplate.id));
      navigate(`../${ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES}`);
    } else if (
      modalType === MODAL_TYPES.CONFIRM_ACTION_DELETE_HABIT_PACK &&
      currentViewedPack?.id
    ) {
      await dispatch(
        deleteHabitPack({
          packId: currentViewedPack.id,
          user_id: currentViewedPack.user_id
        })
      );
      navigate(`../${ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES}`);
    } else if (
      modalType ===
        MODAL_TYPES.CONFIRM_ACTION_REMOVE_HABIT_PACK_FROM_MARKET_PLACE &&
      currentViewedPack
    ) {
      dispatch(removePackFromMarketplace(currentViewedPack));
    } else if (
      modalType ===
        MODAL_TYPES.CONFIRM_ACTION_REMOVE_FOCUS_TEMPLATE_FROM_MARKET_PLACE &&
      currentViewedFocusTemplate
    ) {
      dispatch(removeFocusTemplateFromMarketPlace(currentViewedFocusTemplate));
    } else if (modalType === MODAL_TYPES.CONFIRM_ACTION_LOGOUT) {
      dispatch(updateIsUserLoggingOut(true));
      postHog.reset(true);
      removeLocalStorage([
        LOCAL_STORAGE.BEARER_TOKEN,
        LOCAL_STORAGE.REDIRECTION_COMPLETED,
        LOCAL_STORAGE.SALES_IQ
      ]);
      logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI });
    } else if (modalType === MODAL_TYPES.DELETE_KEYWORD_MODAL) {
      dispatch(deleteKeyword({ id, platform }));
    } else if (modalType === MODAL_TYPES.CONFIRM_ACTION_DELETE_PLAYER_TASK) {
      dispatch(updatePlayerTasks(tasks.filter((task) => task.id !== taskId)));
    }
    dispatch(updateConfirmActionModal(DEFAULT_CONFIRM_ACTION_MODAL));
  };

  const isWaiting =
    isFocusTemplateDeleting ||
    isHabitPacksDeleting ||
    isUserUpsertingPack ||
    areSettingsSaving;

  return (
    <ModalOverlay zIndex='z-[99999]'>
      <ModalWrapper>
        {description ? (
          <h1 className='text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold pt-4'>
            {description}
          </h1>
        ) : null}
        {isWaiting ? (
          <span className='w-6 h-6 border-t-2 border-orange-400 rounded-full animate-spin my-4'></span>
        ) : (
          <div className='flex gap-x-10 py-4'>
            <ButtonFocusBear
              onClick={() =>
                dispatch(updateConfirmActionModal(DEFAULT_CONFIRM_ACTION_MODAL))
              }
              title={cancelTitle ?? t('btn_cancel')}
              variant={BTN_FB_VARIANT.SECONDARY}
            />
            <ButtonFocusBear title={confirmTitle} onClick={handleConfirm} />
          </div>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default ConfirmActionModal;
