import DropDown from 'components/common/DropDown';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import { MultiValue } from 'react-select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFilterDaysOfWeek } from 'store/reducer/filter/slice';
import {
  daysOfWeekOptions,
  updateSelectedDaysOfWeek
} from 'utils/settings.util';
import { isDarkModeActivated } from 'utils/validators';

const FilterItemDaysOfWeek = () => {
  const dispatch = useAppDispatch();
  const {
    filter: { daysOfWeek },
    setting: { themeMode }
  } = useAppSelector((state) => state);

  const selectedDays = updateSelectedDaysOfWeek(daysOfWeek ?? []);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='min-w-[200px] w-fit h-fit text-sm font-medium relative'>
      <label
        className={`absolute -top-2.5 left-1/2 -translate-x-1/2 z-10 bg-gray-600 text-[10px] px-1.5 rounded-b-md ${
          !shouldActivateDarkMode && 'text-white'
        }`}
      >
        {t('days_of_activity')}
      </label>
      <DropDown
        value={selectedDays}
        options={daysOfWeekOptions}
        handleChange={(data) => {
          const result = data as MultiValue<DropDownSelectedValue>;
          dispatch(updateFilterDaysOfWeek(result.map((item) => item.value)));
        }}
        isMulti
        containerStyles='w-full text-xs font-semibold'
      />
    </div>
  );
};

export default FilterItemDaysOfWeek;
