import { useAuth0 } from '@auth0/auth0-react';
import EmailVerificationBanner from 'components/EmailVerificationBanner';
import { PropsWithChildren } from 'react';

const NavigationWrapper = ({ children }: PropsWithChildren<object>) => {
  const { user } = useAuth0();

  return (
    <header className='w-full flex flex-col sticky top-0 shadow-md backdrop-blur-md z-[60]'>
      {!user?.email_verified ? <EmailVerificationBanner /> : null}
      <div className='min-h-[1.5rem] lg:min-h-[80px] px-4 bg-transparent flex justify-between items-center ~lg:text-sm/lg'>
        {children}
      </div>
    </header>
  );
};

export default NavigationWrapper;
