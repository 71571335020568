import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ActivityType } from 'interfaces/common.interface';

const PageContent = ({ activities }: { activities: ActivityType[] }) => {
  const { t } = useTranslation();
  const getActivityTime = (habit: ActivityType) => {
    const duration =
      habit.duration_seconds >= 60
        ? t('time_minutes', {
            count: Math.trunc(habit.duration_seconds / 60)
          })
        : t('time_seconds', { count: habit.duration_seconds });

    return duration;
  };

  return (
    <View style={styles.activityContent}>
      {(activities ?? []).map((habit, index: number) => {
        const time = getActivityTime(habit);
        return (
          <View key={index} style={styles.activityContainer}>
            <View key={index} style={styles.activityItem} wrap={false}>
              <Text style={styles.activityItemText}>
                {index + 1}. {habit.name}
              </Text>
              <Text style={styles.activityItemText}>{time}</Text>
            </View>
            {habit.text_instructions && (
              <Text style={styles.activityInstructions}>
                {habit.text_instructions}
              </Text>
            )}
            {habit.image_urls && habit.image_urls?.length > 0 && (
              <View style={styles.activityImagesContainer}>
                {habit.image_urls.map((image, index) => (
                  <Image
                    style={styles.activityImage}
                    src={image.url}
                    key={index}
                  />
                ))}
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  activityContainer: {
    borderBottom: '1px solid #000',
    paddingVertical: '5px'
  },
  activityContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center'
  },
  activityItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '4px'
  },
  activityItemText: {
    fontSize: '14px',
    marginTop: '3px'
  },
  activityImagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '5px 0px'
  },
  activityImage: {
    width: '150px',
    paddingRight: '10px'
  },
  activityInstructions: {
    fontSize: '12px',
    width: '100%',
    textAlign: 'left'
  }
});

export default PageContent;
