import Dropdown from 'assets/icons/Dropdown';
import { PropsWithChildren, useEffect, useState } from 'react';
import { createAppSelector, useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validators';
import COLOR from 'constants/color';
import { THEME_OPTION } from 'constants/enum';
import classNames from 'classnames';

interface AccordionProps {
  title?: string | JSX.Element;
  open?: boolean;
  wrapperStyles?: string;
  headerBackground?: string;
  headerStyles?: string;
  iconColor?: string;
  contentBackground?: string;
}

const Accordion = ({
  title,
  children,
  open = false,
  wrapperStyles,
  headerBackground = 'bg-gray-500',
  headerStyles = 'text-xs',
  iconColor = COLOR.WHITE,
  contentBackground = 'bg-gray-200'
}: PropsWithChildren<AccordionProps>) => {
  const [isOpen, setIsOpen] = useState(open);
  const shouldActiveDarkMode = isDarkModeActivated(
    useAppSelector(
      createAppSelector([(state) => state.setting.themeMode], (theme) =>
        theme.slice()
      )
    ) as THEME_OPTION
  );

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div className={`w-full h-fit flex flex-col rounded-md ${wrapperStyles}`}>
      <div
        className={classNames(
          'w-full h-fit flex items-center justify-between rounded-t-md px-2.5 py-1.5',
          {
            'bg-gray-800': shouldActiveDarkMode,
            [headerBackground]: !shouldActiveDarkMode,
            [headerStyles]: true
          }
        )}
      >
        <div>{title}</div>
        <button
          className={`${isOpen ? 'rotate-180' : 'rotate-0'} duration-200`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Dropdown fill={iconColor} />
        </button>
      </div>
      <div
        className={`w-full ${
          isOpen
            ? `h-fit overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 p-3`
            : 'h-0 p-0 overflow-hidden'
        } ${
          shouldActiveDarkMode ? 'bg-gray-600' : contentBackground
        } transition-all duration-200 rounded-b-md`}
      >
        {isOpen && children}
      </div>
    </div>
  );
};

export default Accordion;
