import React from 'react';

interface Props {
  children: React.ReactNode;
}

const DetailsPageActionsWrapper = ({ children }: Props) => (
  <div className='w-full md:w-[20%] lg:w-[25%] flex flex-row md:flex-col flex-wrap md:flex-nowrap justify-center items-center h-fit ~py-4/8 gap-x-6 gap-y-6'>
    {children}
  </div>
);

export default DetailsPageActionsWrapper;
