import CloseCircle from 'assets/icons/CloseCircle';
import Hamburger from 'assets/icons/Hamburger';
import logo from 'assets/images/logo.png';
import COLOR from 'constants/color';
import { ROUTES } from 'constants/routes';
import { ATTRIB } from 'constants/test';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavMenusMiddle from '../navBar/NavMenusMiddle';
import LanguageDropDown from './LanguageDropDown';
import NavigationWrapper from './NavigationWrapper';

export default function MobileNavigation() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <NavigationWrapper>
      <nav className='flex justify-between items-center py-2 px-4 h-fit w-full bg-transparent shadow-md backdrop-blur-md'>
        <button
          data-action={ATTRIB.TEST.OPEN_SIDE_NAVBAR}
          onClick={() => setIsMenuOpen(true)}
          className='flex w-8 h-8 items-center justify-center focus:outline-none text-orange-400 bg-white hover:bg-orange-100 border-3 rounded'
        >
          <Hamburger />
        </button>
        <LanguageDropDown />
      </nav>
      {/* Popup menu */}
      <div
        className={`w-full h-screen fixed top-0 left-0 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } duration-200 ease-in-out z-20`}
      >
        <nav className='flex flex-col w-5/6 max-w-sm h-full py-6 px-6 bg-white border-r overflow-y-auto text-lg'>
          <div className='flex items-center my-4 relative'>
            <button
              data-action={ATTRIB.TEST.CLOSE_SIDE_NAVBAR}
              onClick={() => closeMenu()}
              className='navbar-close absolute right-0'
            >
              <CloseCircle styles='w-6 h-6' fill={COLOR.BLACK} />
            </button>
            <button
              data-action={ATTRIB.TEST.HOME}
              onClick={() => {
                closeMenu();
                navigate(ROUTES.HOME);
              }}
              className='w-44 md:w-fit h-fit'
            >
              <img src={logo} alt='focus bear' className='w-full h-full' />
            </button>
          </div>
          <NavMenusMiddle />
        </nav>
      </div>
    </NavigationWrapper>
  );
}
