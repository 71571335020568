import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsTextEditorWithinCharacterLimit } from 'store/reducer/setting/slice';
import {
  RICH_TEXT_EDITOR_HEIGHT,
  TEMPLATES_TEXT_MAX_CHARACTER_LENGTH
} from 'constants/general';
import { stateFromHTML } from 'draft-js-import-html';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { TextEditorProps } from 'interfaces';
import { DEFAULT_TEXT_EDITOR_TOOLBAR } from 'assets/data';
import {
  updateShouldResetNewTaskForm,
  updateShouldUpdateTextEditorContent
} from 'store/reducer/to-do/slice';
import { isDarkModeActivated } from 'utils/validators';

export default function TextEditor({
  textMarkup,
  onTextChange,
  setHasTextLimitBeenReached,
  required = false,
  height = RICH_TEXT_EDITOR_HEIGHT.DEFAULT,
  maximumCharacters = TEMPLATES_TEXT_MAX_CHARACTER_LENGTH,
  placeholder
}: TextEditorProps) {
  const [editorContent, setEditorContent] = useState(EditorState.createEmpty());
  const dispatch = useAppDispatch();
  const {
    toDo: { shouldResetNewTaskForm, shouldUpdateTextEditorContent },
    setting: { themeMode }
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (textMarkup) {
      const contentState = stateFromHTML(textMarkup);
      setEditorContent(EditorState.createWithContent(contentState));
    }
  }, []);

  useEffect(() => {
    if (shouldResetNewTaskForm) {
      setEditorContent(EditorState.createEmpty());
      dispatch(updateShouldResetNewTaskForm(false));
    }
  }, [shouldResetNewTaskForm]);

  useEffect(() => {
    if (shouldUpdateTextEditorContent) {
      const contentState = stateFromHTML(textMarkup);
      setEditorContent(EditorState.createWithContent(contentState));
      dispatch(updateShouldUpdateTextEditorContent(false));
    }
  }, [textMarkup]);

  const onEditorStateChange = (editorValue: EditorState) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );
    const textLength = editorValue.getCurrentContent().getPlainText().length;
    setEditorContent(editorValue);
    onTextChange(editorStateInHtml);
    dispatch(
      changeIsTextEditorWithinCharacterLimit(textLength < maximumCharacters)
    );
    setHasTextLimitBeenReached?.(textLength > maximumCharacters);
  };

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <Editor
      toolbar={DEFAULT_TEXT_EDITOR_TOOLBAR}
      editorStyle={{ height }}
      wrapperClassName='w-full flex flex-col gap-0.5'
      editorClassName={`${
        shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'
      } text-black px-3 rounded scrollbar-thin scrollbar-thumb-gray-200 text-sm ${
        required && !editorContent.getCurrentContent().getPlainText()
          ? 'border border-red-400'
          : 'border-none'
      }`}
      toolbarClassName={`w-full rounded text-xs ${
        shouldActivateDarkMode && 'bg-gray-400 text-black'
      }`}
      editorState={editorContent}
      onEditorStateChange={onEditorStateChange}
      placeholder={placeholder}
    />
  );
}
