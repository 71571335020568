import { THEME_OPTION } from 'constants/enum';
import { createAppSelector } from 'store/hooks';

export const routineSuggestionsSelector = createAppSelector(
  [
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.habitPack.isSendingInstalledHabitPackId,
    (state) => state.routineSuggestions.areSuggestedHabitPacksInstalled,
    (state) => state.routineSuggestions.showEditSuggestionsModal,
    (state) => state.setting.language,
    (state) => state.routineSuggestions.showAvailableGoalsModal
  ],
  (
    themeMode,
    isTokenAddedToInterceptor,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal,
    language,
    showAvailableGoalsModal
  ) => {
    return {
      themeMode,
      isTokenAddedToInterceptor,
      isSendingInstalledHabitPackId,
      areSuggestedHabitPacksInstalled,
      showEditSuggestionsModal,
      language,
      showAvailableGoalsModal
    };
  }
);

export const routineSuggestionsWizardSelector = createAppSelector(
  [
    (state) => state.routineSuggestions.routineSuggestionPayload,
    (state) => state.setting.platform,
    (state) => state.user.details?.email,
    (state) => state.user.details?.id,
    (state) => state.routineSuggestions.areSuggestedHabitPacksInstalled,
    (state) => state.routineSuggestions.routineSuggestions?.slice(),
    (state) => state.routineSuggestions.isInstallingRoutineSuggestions
  ],
  (
    routineSuggestionPayload,
    platform,
    userEmail,
    userId,
    areSuggestedHabitPacksInstalled,
    routineSuggestions,
    isInstallingRoutineSuggestions
  ) => ({
    routineSuggestionPayload,
    platform,
    userEmail,
    userId,
    areSuggestedHabitPacksInstalled,
    routineSuggestions,
    isInstallingRoutineSuggestions
  })
);

export const routineGoalsSelector = createAppSelector(
  [
    (state) => state.routineSuggestions.routineSuggestionPayload.user_goals,
    (state) => state.routineSuggestions.selectedGoals
  ],
  (user_goals, selectedGoals) => {
    return {
      user_goals,
      selectedGoals
    };
  }
);

export const listOfSelectedGoalsSelector = createAppSelector(
  [
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.routineSuggestions.selectedGoals
  ],
  (isLogTermGoalUpdating, selectedGoals) => {
    return {
      isLogTermGoalUpdating,
      selectedGoals
    };
  }
);

export const routineOptionsSelector = createAppSelector(
  [
    (state) => state.routineSuggestions.isFetchingRoutineSuggestions,
    (state) => state.routineSuggestions.routineSuggestionPayload,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  ) => ({
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  })
);

export const routineAvailableGoalsSelector = createAppSelector(
  [
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.routineSuggestions.routineSuggestionPayload,
    (state) => state.routineSuggestions.availableGoals,
    (state) => state.routineSuggestions.selectedGoals,
    (state) => state.user.details?.long_term_goals ?? []
  ],
  (
    isLogTermGoalUpdating,
    routineSuggestionPayload,
    availableGoals,
    selectedGoals,
    currentLongTermGoals
  ) => {
    return {
      isLogTermGoalUpdating,
      routineSuggestionPayload,
      availableGoals,
      selectedGoals,
      currentLongTermGoals
    };
  }
);

export const routineSelectionSelector = createAppSelector(
  [(state) => state.routineSuggestions.routineSuggestionPayload],
  (routineSuggestionPayload) => routineSuggestionPayload
);

export const suggestionsActionsSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.currentSettings,
    (state) => state.routineSuggestions.isInstallingRoutineSuggestions,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.routineSuggestions.routineSuggestions?.slice()
  ],
  (
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail,
    routineSuggestions
  ) => ({
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail,
    routineSuggestions
  })
);

export const suggestionsSelector = createAppSelector(
  [
    (state) => state.routineSuggestions.routineSuggestions?.slice(),
    (state) => state.routineSuggestions.routineSuggestionPayload.routines,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (routineSuggestions, routines, userId, userEmail, platform) => ({
    routineSuggestions,
    routines,
    userId,
    userEmail,
    platform
  })
);

export const routineActivitiesSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.routineSuggestions.routineSuggestionPayload.routines,
    (state) => state.routineSuggestions.routineSuggestions,
    (state) => state.routineSuggestions.isInstallingRoutineSuggestions
  ],
  (activeTab, routines, routineSuggestions) => {
    return {
      activeTab,
      routines,
      routineSuggestions
    };
  }
);
