import { createContext } from 'react';
import CustomRoutineActivities from './CustomRoutineActivities';
import CustomRoutineName from './CustomRoutineName';
import CustomRoutineOptions from './CustomRoutineOptions';
import { CustomRoutineType } from 'interfaces/setting.interface';

interface CustomRoutineProps {
  customRoutine: CustomRoutineType;
  position: number;
}
export interface CustomRoutineContextType extends CustomRoutineType {
  position: number;
}

export const CustomRoutineContext = createContext<
  CustomRoutineContextType | undefined
>(undefined);

const CustomRoutine = ({ customRoutine, position }: CustomRoutineProps) => {
  return (
    <div className='w-full flex flex-col items-center gap-10 ~p-2/5'>
      <CustomRoutineContext.Provider value={{ ...customRoutine, position }}>
        <CustomRoutineName />
        <CustomRoutineOptions />
        <CustomRoutineActivities />
      </CustomRoutineContext.Provider>
    </div>
  );
};

export default CustomRoutine;
