import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateIsFetchingRoutineSuggestions } from './slice';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import { RootState } from 'store';
import { POST_HOG_EVENT } from 'constants/events';
import * as Sentry from '@sentry/react';
import { captureEvents } from 'utils/events';

export const getRoutineSuggestionActivities = createAsyncThunk(
  'routine-suggestions/get_routine_suggestion_activities',
  async (
    payload: {
      user_goals: string[];
      routine_duration: number;
      routine?: string;
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(updateIsFetchingRoutineSuggestions(true));
      const { data } = await focusBearApi.post(
        Endpoints.ACTIVITY_LIBRARY_ROUTINE_SUGGESTIONS,
        payload
      );
      if (!data?.length) {
        const {
          setting: { platform },
          user: { details }
        } = getState() as RootState;
        captureEvents(
          POST_HOG_EVENT.ROUTINE_SUGGESTIONS_NO_SUGGESTIONS_GENERATED,
          details?.email,
          { platform, userId: details?.id, userGoals: payload.user_goals }
        );
      }
      return data ?? [];
    } catch (error) {
      dispatch(updateIsFetchingRoutineSuggestions(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
