import RotatingCircle from 'assets/icons/RotatingCircle';
import { t } from 'i18next';
import ModalOverlay from './shared/ModalOverlay';
import { createAppSelector, useAppSelector } from 'store/hooks';
import COLOR from 'constants/color';
import classNames from 'classnames';
import { isDarkModeActivated } from 'utils/validators';
import { THEME_OPTION } from 'constants/enum';

interface Props {
  title?: string;
  containerStyles?: string;
  messageStyles?: string;
  fillSpinner?: string;
}

const OverlaySpinner = ({
  title,
  fillSpinner = COLOR.ORANGE_400,
  messageStyles = 'text-sunRayFirst',
  containerStyles = 'w-screen h-screen flex items-center justify-center'
}: Props) => {
  const message = title ?? t('updating');
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(
      createAppSelector(
        [(state) => state.setting.themeMode.slice() as THEME_OPTION],
        (theme) => theme
      )
    )
  );

  return (
    <ModalOverlay
      styles={classNames({
        [containerStyles]: true,
        'bg-gray-900/80': !shouldActivateDarkMode,
        'bg-gray-600/80': shouldActivateDarkMode
      })}
      zIndex='z-[99999]'
    >
      <div className='w-fit h-fit flex items-center gap-3 relative bottom-10'>
        <RotatingCircle fill={fillSpinner} />
        <div
          className={classNames('text-sm md:text-base xl:text-lg', {
            [messageStyles]: !shouldActivateDarkMode,
            'text-white': shouldActivateDarkMode
          })}
        >
          {message}
        </div>
      </div>
      <span className='sr-only'>{message}</span>
    </ModalOverlay>
  );
};

export default OverlaySpinner;
