import InfoCircle from 'assets/icons/InfoCircle';
import classNames from 'classnames';
import { useMemo } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

interface TooltipProps {
  message?: string;
  place?: PlacesType;
  icon?: JSX.Element;
  iconStyles?: string;
  iconFillDarkMode?: string;
  additionalWrapperStyles?: string;
  isCustomMessage?: boolean;
  popupStyles?: string;
}

const Tooltip = ({
  message,
  place,
  icon,
  iconStyles,
  iconFillDarkMode,
  additionalWrapperStyles,
  isCustomMessage = false,
  popupStyles
}: TooltipProps) => {
  const id = useMemo(() => uuid(), []);
  return (
    <span
      className={`text-xs md:text-sm ${additionalWrapperStyles ?? 'flex items-center'}`}
    >
      <span data-tooltip-id={id} className='cursor-pointer'>
        {icon ?? (
          <InfoCircle styles={iconStyles} fillDarkMode={iconFillDarkMode} />
        )}
      </span>
      <ReactTooltip
        id={id}
        place={place}
        className={classNames('max-w-[80vw] z-[99999]', {
          [`${popupStyles}`]: Boolean(popupStyles)
        })}
      >
        {isCustomMessage ? (
          <span>
            {message?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </span>
        ) : (
          message
        )}
      </ReactTooltip>
    </span>
  );
};

export default Tooltip;
