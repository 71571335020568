import { createAsyncThunk } from '@reduxjs/toolkit';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import { RootState } from 'store';
import * as Sentry from '@sentry/react';
import { HTTP_STATS_CODE, PAGINATE_ITEMS } from 'constants/general';
import {
  resetPagination,
  updateOrderBy,
  updateIsFetchingActivities,
  updateIsFetchingFocusModes,
  updateIsFetchingUserDetails,
  updateIsLoading,
  updateIsFetchingSurveys,
  updateIsUpdatingSurvey,
  updateIsCreatingSurvey
} from './slice';
import {
  GetActivitiesByUserIdOrStripeIdOrActivityTypePayload,
  CreateSurveyPayload,
  UpdateSurveyPayload
} from 'interfaces';
import isUUID from 'validator/es/lib/isUUID';
import { FetchUsersOrderByOptions } from 'constants/enum';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import {
  updatePaginationNavigation,
  updatePaginationCurrentPage,
  updatePaginationTotalPages
} from '../pagination/slice';
import { decrement } from 'utils/helpers';

export const getUsers = createAsyncThunk(
  'admin/get_user_list',
  async (
    {
      orderBy,
      shouldAppend = false
    }: { orderBy: string; shouldAppend?: boolean },
    { dispatch, getState }
  ) => {
    try {
      const take = `${PAGINATE_ITEMS}`;
      const {
        admin: { skip }
      } = getState() as RootState;
      dispatch(updateIsLoading(true));
      const params = orderBy
        ? {
            take,
            skip,
            orderBy
          }
        : {
            take,
            skip
          };
      const { data } = await focusBearApi.get(Endpoints.USER_LIST, {
        params
      });
      return { shouldAppend, data };
    } catch (error) {
      dispatch(updateIsLoading(false));
      toast.error(i18n.t('admin.could_not_fetch_users'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getUserInfoAndFocusModes = createAsyncThunk(
  'admin/get_user_info_and_focus_modes',
  async (user_id: string, { dispatch }) => {
    try {
      dispatch(updateIsFetchingFocusModes(true));
      const { data } = await focusBearApi.get(
        Endpoints.USER_INFO_AND_FOCUS_MODES,
        {
          params: { id: user_id }
        }
      );
      return data;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getActivitiesByUserIdOrStripeIdOrActivityType = createAsyncThunk(
  'admin/get_activities_by_userId_or_stripeId_or_ActivityType',
  async (
    {
      user_id,
      stripe_customer_id,
      page_num = PAGINATE_ITEMS
    }: GetActivitiesByUserIdOrStripeIdOrActivityTypePayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateIsFetchingActivities(true));
      let params: GetActivitiesByUserIdOrStripeIdOrActivityTypePayload = {
        page_num
      };
      if (user_id && isUUID(user_id)) {
        params = { ...params, user_id };
      }
      if (stripe_customer_id) {
        params = { ...params, stripe_customer_id };
      }
      const { data } = await focusBearApi.get(Endpoints.ACTIVITY, {
        params
      });
      return data ?? [];
    } catch (error) {
      toast.error(i18n.t('admin.could_not_fetch_users'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getUserByIdOrStripeIdOrEmail = createAsyncThunk(
  'admin/get_user_by_id_or_stripe_id_or_email',
  async (
    params: { user_id?: string; stripe_customer_id?: string; email?: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsFetchingUserDetails(true));
      dispatch(updateOrderBy(FetchUsersOrderByOptions.NONE));
      dispatch(resetPagination());
      const { data } = await focusBearApi.get(Endpoints.USER, {
        params
      });
      return data ? [data] : [];
    } catch (error) {
      toast.error(i18n.t('admin.could_not_fetch_users'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getUserDevices = createAsyncThunk(
  'admin/get_user_devices',
  async (user_id: string) => {
    try {
      const { data } = await focusBearApi.get(Endpoints.ADMIN_USER_DEVICE, {
        params: {
          user_id
        }
      });
      return data ?? [];
    } catch (error) {
      toast.error(i18n.t('admin.could_not_fetch_user_devices', { user_id }));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const createSurvey = createAsyncThunk(
  'admin/create_survey',
  async (payload: CreateSurveyPayload, { dispatch, getState }) => {
    try {
      const { currentPage, itemsPerPage } = (getState() as RootState)
        .pagination;
      dispatch(updateIsCreatingSurvey(true));
      const { data, status } = await focusBearApi.post(
        Endpoints.SURVEY,
        payload
      );
      status === HTTP_STATS_CODE.CREATED &&
        dispatch(getSurveys({ page: currentPage, take: itemsPerPage }));
      return data ?? null;
    } catch (error) {
      dispatch(updateIsCreatingSurvey(false));
      Sentry.captureException(JSON.stringify);
    }
  }
);

export const getSurveys = createAsyncThunk(
  'admin/get_surveys',
  async (
    payload: {
      page: number;
      order?: string;
      take?: number;
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(updateIsFetchingSurveys(true));
      const { data } = await focusBearApi.get(Endpoints.SURVEY, {
        params: {
          ...payload
        }
      });
      dispatch(
        updatePaginationNavigation({
          hasNextPage: data?.meta?.hasNextPage,
          hasPreviousPage: data?.meta?.hasPreviousPage
        })
      );
      if (!data?.data?.length) {
        const { currentPage, totalPages } = (getState() as RootState)
          .pagination;
        dispatch(updatePaginationCurrentPage(decrement(currentPage)));
        dispatch(updatePaginationTotalPages(decrement(totalPages)));
      }
      return data?.data ?? [];
    } catch (error) {
      dispatch(updateIsFetchingSurveys(false));
      Sentry.captureException(JSON.stringify);
    }
  }
);

export const updateSurvey = createAsyncThunk(
  'admin/update_survey',
  async (payload: UpdateSurveyPayload, { dispatch, getState }) => {
    try {
      const { currentPage, itemsPerPage } = (getState() as RootState)
        .pagination;
      dispatch(updateIsUpdatingSurvey(true));
      const { status } = await focusBearApi.patch(Endpoints.SURVEY, payload);
      status === HTTP_STATS_CODE.SUCCESS &&
        dispatch(getSurveys({ page: currentPage, take: itemsPerPage }));
      dispatch(updateIsUpdatingSurvey(false));
    } catch (error) {
      dispatch(updateIsUpdatingSurvey(false));
      Sentry.captureException(JSON.stringify);
    }
  }
);
