import DropDown from 'components/common/DropDown';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import { HabitPackType } from 'interfaces/common.interface';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { routineExploreHabitPacksSelector } from 'store/reducer/setting/selectors';
import { formatOnboardingPackTitle } from 'utils/routine-suggestion.util';
import SelectedHabitPackDetails from './SelectedHabitPackDetails';

const ExploreHabitPacks = () => {
  const { onboardingHabitPacks, areHabitPacksLoading } = useAppSelector(
    routineExploreHabitPacksSelector
  );
  const [selectedPack, setSelectedPack] = useState<HabitPackType | undefined>();

  useEffect(() => {
    const defaultSelectedPack = onboardingHabitPacks?.length
      ? onboardingHabitPacks[0]
      : undefined;
    setSelectedPack(defaultSelectedPack);
  }, [onboardingHabitPacks]);

  const options = useMemo(
    () =>
      onboardingHabitPacks
        ?.sort((packA, packB) => packA.duration - packB.duration)
        ?.map((pack) => ({
          label: formatOnboardingPackTitle(pack.duration, pack.pack_name),
          value: pack.id
        })),
    [onboardingHabitPacks]
  );

  return (
    <div className='min-w-full h-full flex flex-col items-center gap-2 p-2 sm:p-4 md:p-6 overflow-y-auto scrollbar-thin'>
      <p className='w-full sm:w-11/12 md:w-10/12 text-sm px-2 text-center'>
        {t('routine_suggestion.choose_a_habit_pack')}
      </p>
      <div className='w-full flex flex-col gap-2 items-center'>
        <DropDown
          value={
            selectedPack
              ? {
                  label: formatOnboardingPackTitle(
                    selectedPack.duration,
                    selectedPack.pack_name
                  ),
                  value: selectedPack.id
                }
              : undefined
          }
          options={options}
          handleChange={(data) => {
            const selected = data as DropDownSelectedValue;
            setSelectedPack(
              onboardingHabitPacks.find((pack) => pack.id === selected?.value)
            );
          }}
          isLoading={areHabitPacksLoading}
        />
        {selectedPack && (
          <SelectedHabitPackDetails onboardingHabitPack={selectedPack} />
        )}
      </div>
    </div>
  );
};

export default ExploreHabitPacks;
