import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCourseCompletion } from 'store/reducer/course/extra';
import { courseSummarySelector } from 'store/reducer/course/selectors';
import { ITEM_INDEX_NOT_FOUND } from 'constants/general';

const CourseSummary = () => {
  const dispatch = useAppDispatch();
  const { selectedCourse, userInfo } = useAppSelector(courseSummarySelector);

  const handleCourseCompletion = useCallback(() => {
    const isCourseCompleted =
      selectedCourse?.enrollments?.findIndex(
        ({ user_id, course_id, finished }) =>
          finished &&
          selectedCourse.id === course_id &&
          user_id === userInfo?.id
      ) !== ITEM_INDEX_NOT_FOUND;

    !isCourseCompleted &&
      selectedCourse &&
      dispatch(updateCourseCompletion(selectedCourse.id));
  }, [selectedCourse]);

  return (
    <div className='min-w-full w-full lg:w-1/2 min-h-[50vh] lg:min-h-[35vh] flex flex-col gap-6 items-center justify-center font-semibold animate-fadeIn'>
      <p className='text-2xl text-center'>
        {t('course.you_have_successfully_completed_the_course')}
      </p>
      <ButtonFocusBear
        onClick={() => handleCourseCompletion()}
        title={t('course.back_to_enrolled_courses')}
      />
    </div>
  );
};

export default CourseSummary;
