import { ENTITLEMENT, SORT_OPTION } from 'constants/enum';
import { SUBSCRIPTION_PERIOD, SUBSCRIPTION_PLAN } from 'constants/general';
import { t } from 'i18next';
import { Team, TeamMember, TeamsMembers } from 'interfaces';
import { Entitlement } from 'interfaces/initial-states.interface';
import { ActiveEntitlement } from 'interfaces/subscription.interface';
import moment from 'moment';
import { isUUID } from 'validator';

export const getAvailableTeamSpots = (team: Team | null) =>
  team ? team.team_size_limit - team.team_size : 0;

export const isTeamOwnerOrAdmin = (activeEntitlements: ActiveEntitlement[]) =>
  activeEntitlements.some((activeEntitlement) =>
    [ENTITLEMENT.TEAM_OWNER, ENTITLEMENT.TEAM_ADMIN].includes(
      activeEntitlement.entitlement
    )
  );

export const getSortedMembers = (
  members: TeamMember[],
  column?: string,
  sortType: SORT_OPTION = SORT_OPTION.ASC
) => {
  const compare = (a: number, b: number) =>
    sortType === SORT_OPTION.DESC ? b - a : a - b;

  const columnMapping: { [key: string]: (member: TeamMember) => number } = {
    [t('admin_team.morning_streak')]: (member) =>
      member.morning_routines_streak,
    [t('admin_team.evening_streak')]: (member) =>
      member.evening_routines_streak,
    [t('admin_team.focus_mode_streak')]: (member) => member.focus_modes_streak,
    [t('avg_focus_modes_90_days')]: (member) =>
      member.focus_modes_percent_number_day_of_stats_completed,
    [t('avg_morning_90_days')]: (member) =>
      member.morning_percent_number_day_of_stats_completed,
    [t('avg_micro_break_90_days')]: (member) =>
      member.micro_percent_number_day_of_stats_completed,
    [t('avg_evening_90_days')]: (member) =>
      member.evening_percent_number_day_of_stats_completed
  };

  if (!column) {
    return members;
  }
  const getColumnValue = columnMapping[column];
  return members.sort((a, b) => compare(getColumnValue(a), getColumnValue(b)));
};

export const checkSubscriptionValidity = (entitlement?: Entitlement) => {
  const hasActiveSubscription = moment(entitlement?.expires_date).isSameOrAfter(
    moment(),
    'second'
  );
  const expiryDate = moment(entitlement?.purchase_date).add(
    entitlement?.product_identifier === SUBSCRIPTION_PLAN.PERSONAL_MONTHLY
      ? SUBSCRIPTION_PERIOD.MONTHLY
      : SUBSCRIPTION_PERIOD.ANNUAL,
    'days'
  );
  const hasUnfinishedPersonalSubscriptionPeriod = [
    SUBSCRIPTION_PLAN.PERSONAL_MONTHLY,
    SUBSCRIPTION_PLAN.PERSONAL_ANNUAL
  ].includes(entitlement?.product_identifier ?? '')
    ? moment().isSameOrBefore(expiryDate, 'day')
    : false;

  return {
    hasActiveSubscription,
    hasUnfinishedPersonalSubscriptionPeriod,
    expiryDate
  };
};

export const isValidInvitationUserId = (
  user_id: string,
  teamMembers: TeamsMembers
) => ({
  isValidUserId: isUUID(user_id),
  isUserInTeam: [...teamMembers.admins, ...teamMembers.members].some(
    (member) => member.id === user_id
  )
});
