import CloseCircle from 'assets/icons/CloseCircle';
import classNames from 'classnames';
import Button from 'components/common/buttons/Button';
import ModalOverlay from 'components/shared/ModalOverlay';
import COLOR from 'constants/color';
import { t } from 'i18next';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routineAvailableGoalsSelector } from 'store/reducer/routine-suggestions/selectors';
import { updateShowAvailableGoalsModal } from 'store/reducer/routine-suggestions/slice';
import ModalContent from './ModalContent';
import ModalActions from './ModalActions';
import { AvailableGoalsModalContext } from 'pages/routine-suggestions';
import { updateLongTermGoals } from 'store/reducer/user/extra';
import { getUniqueGoals } from 'utils/routine-suggestion.util';
import { isDarkThemeActivated } from 'utils/settings.util';

interface WrapperProps extends PropsWithChildren<object> {
  handleModalClose: () => void;
}

const Wrapper = ({ children, handleModalClose }: WrapperProps) => {
  const shouldActivateDarkTheme = isDarkThemeActivated();

  return (
    <div
      className={classNames(
        'relative top-1/2 -translate-y-1/2 m-auto flex w-11/12 sm:w-10/12 md:w-3/4 h-fit flex-col gap-1 rounded-lg animate-fadeIn',
        {
          'bg-gray-700': shouldActivateDarkTheme,
          'bg-whiteLace': !shouldActivateDarkTheme
        }
      )}
    >
      <p className='~text-sm/lg ~p-4/6'>
        {t(
          'routine_suggestion.are_there_other_goals_you_want_to_achieve_with_focus_bear'
        )}
      </p>
      {children}
      <Button
        className='bg-sunRay rounded-full w-fit absolute -top-2 -right-2'
        icon={<CloseCircle fill={COLOR.BLACK} fillDarkMode={COLOR.RED} />}
        onClick={handleModalClose}
      />
    </div>
  );
};

const AvailableGoalsModal: FC = () => {
  const dispatch = useAppDispatch();
  const { goals, setGoals } = useContext(AvailableGoalsModalContext);
  const { selectedGoals, currentLongTermGoals } = useAppSelector(
    routineAvailableGoalsSelector
  );

  useEffect(() => {
    setGoals?.((prev) => ({ ...prev, selected: selectedGoals }));
  }, [selectedGoals]);

  const handleModalClose = useCallback(() => {
    dispatch(updateShowAvailableGoalsModal(false));
    const newGoals = goals.new;
    const hasNewLongTermGoals = currentLongTermGoals?.length
      ? newGoals.some((goal) => !currentLongTermGoals.includes(goal))
      : newGoals.length;

    if (hasNewLongTermGoals) {
      const uniqueGoals = getUniqueGoals([
        ...currentLongTermGoals,
        ...newGoals
      ]);
      dispatch(updateLongTermGoals(uniqueGoals));
    }
  }, [goals, currentLongTermGoals]);

  return (
    <ModalOverlay styles='w-full h-full bg-gray-600/90'>
      <Wrapper handleModalClose={handleModalClose}>
        <ModalContent />
        <ModalActions handleModalClose={handleModalClose} />
      </Wrapper>
    </ModalOverlay>
  );
};

export default AvailableGoalsModal;
