import * as Sentry from '@sentry/react';
import { MOBILE_APP_EVENT, PLATFORMS_MESSAGE_HANDLER } from 'constants/events';
import { WEBVIEW_CUSTOM_FUNCTION_TIMEOUT } from 'constants/general';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { initEmbeddedServicesSelector } from 'store/reducer/setting/selectors';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { safeStringify } from 'utils/helpers';
import { isDarkThemeActivated } from 'utils/settings.util';
import { v4 as uuid } from 'uuid';

const InitEmbeddedServices = () => {
  const { t } = useTranslation();
  const { isTokenAddedToInterceptor, platform } = useAppSelector(
    initEmbeddedServicesSelector
  );
  const [resetKey, setResetKey] = useState(uuid());

  useEffect(() => {
    if (isTokenAddedToInterceptor) {
      return;
    }
    let firstExecutionSkipped = false;

    const intervalId = setInterval(() => {
      if (!firstExecutionSkipped) {
        firstExecutionSkipped = true;
        return;
      }

      if (isTokenAddedToInterceptor) {
        clearInterval(intervalId);
        return;
      }

      setResetKey(uuid());
      sendDataToPlatform(
        platform,
        PLATFORMS_MESSAGE_HANDLER.INVOKE_FUNCTION,
        undefined,
        MOBILE_APP_EVENT.INVOKE_FUNCTION
      );
      Sentry.captureMessage(
        `Timeout while waiting for custom function invocation ${intervalId}`,
        {
          level: 'error',
          extra: {
            data: safeStringify({ path: window.location.pathname, intervalId })
          }
        }
      );
    }, WEBVIEW_CUSTOM_FUNCTION_TIMEOUT);

    return () => clearInterval(intervalId);
  }, [isTokenAddedToInterceptor]);

  const initEmbeddedWebviewLoadingTitle = useMemo(() => {
    const titles = [
      'fetching_your_settings_hold_tight',
      'connecting_to_the_Focus_Bear_control_panel',
      'calibrating_your_productivity_dashboard',
      'debugging_distractions_stand_by'
    ];
    const randomIndex = Math.floor(Math.random() * titles.length);
    return t(titles[randomIndex]);
  }, [t, resetKey]);

  return (
    <div className='fixed w-screen h-screen bg-bear bg-no-repeat bg-center bg-fixed bg-contain'>
      <div
        className={`w-full h-full flex items-center justify-center ${
          isDarkThemeActivated() ? 'bg-gray-600/95 text-white' : 'bg-white/95'
        }`}
      >
        <p
          key={resetKey}
          className='w-fit h-fit animate-pulse ~text-lg/3xl ~px-2/8 text-center'
        >
          {initEmbeddedWebviewLoadingTitle ??
            t('initializing_embedded_services')}
        </p>
      </div>
    </div>
  );
};

export default InitEmbeddedServices;
