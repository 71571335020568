import {
  DEFAULT_CURRENT_ACTIVITY_PROPS,
  DEFAULT_ONBOARDING_STATS,
  DEFAULT_USER_DETAILS,
  DEFAULT_SETTINGS,
  DEFAULT_MORE_OPTION_ACTIVITY,
  DEFAULT_LOCAL_DEVICE_SETTING,
  DEFAULT_TEAM_MEMBERS,
  DEFAULT_TO_DO_SUBTASKS,
  DEFAULT_NEW_TASK,
  DEFAULT_CONFIRM_ACTION_MODAL,
  DEFAULT_ROUTINES_SUGGESTIONS,
  DEFAULT_ROUTINE_TIME_VALIDATION,
  DEFAULT_GOAL_SUGGESTIONS,
  DEFAULT_SYNCED_EXTERNAL_PLATFORMS
} from 'assets/data';
import {
  ActivityTableFilter,
  ChatBotState,
  ChatRoles,
  FEED_BACK_QUESTION,
  FONT_OPTION,
  FetchUsersOrderByOptions,
  Mode,
  THEME_OPTION
} from 'constants/enum';
import {
  DASHBOARD,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_ROUTINE_DURATION,
  DEFAULT_TAB_LAYOUT_TAB,
  DEFAULT_TO_DO_PLAYER,
  EMPTY_STRING,
  HABIT_PACK_FOCUS_TEMPLATES_TAB_INDEX,
  LANGUAGE,
  MARKET_TAB_INDEX,
  NUMBERS,
  PAGE,
  PLATFORMS,
  ROUTINE_PLAYER_FEATURE,
  SEARCH_RESULT,
  TABS,
  TO_DO_TAB
} from 'constants/general';
import {
  AdminSlice,
  ChatBotSlice,
  FilterSlice,
  FocusModeSlice,
  HabitPackSlice,
  GeneralSlice,
  InquirySlice,
  ModalSlice,
  SettingSlice,
  UserSlice,
  CacheSlice,
  ProcrastinateSlice,
  ToDoSlice,
  PlatformIntegrationSlice,
  TeamSlice,
  PaginationSlice,
  CourseSlice,
  RoutineSuggestionsSlice,
  SubscriptionSlice
} from 'interfaces/initial-states.interface';
import i18n from 'services/i18n';
import { getRandomAiTone } from 'utils/helpers';

export const initialUserState: UserSlice = {
  details: DEFAULT_USER_DETAILS,
  morningCompletionPercent: 0,
  eveningCompletionPercent: 0,
  weeklySummaryStats: [],
  isMorningStatsUpdating: false,
  isEveningStatsUpdating: false,
  isWeeklySummaryUpdating: false,
  isUserAdmin: false,
  isCurrentActivityPropsUpdating: true,
  currentActivityProps: DEFAULT_CURRENT_ACTIVITY_PROPS,
  last_completed_activity_log: null,
  isMorningRoutineCompleted: false,
  isEveningRoutineCompleted: false,
  isBreakingRoutineCompleted: false,
  completed_routines: [],
  onboardingStats: DEFAULT_ONBOARDING_STATS,
  areOnboardingStatsLoading: false,
  areUserDetailsLoading: false,
  motivationalSummary: EMPTY_STRING,
  isMotivationalSummaryLoading: false,
  isMotivationalSummaryStopGenerating: true,
  isUpdatingUserPrivacyPolicy: false,
  isDailySummaryFetching: false,
  dailySummary: {
    focusSummary: [],
    daySummaryAVG: [],
    daySummarySUM: [],
    daySummaryDuration: []
  },
  isLogTermGoalUpdating: false,
  isGuestUser: false,
  isWeeklyFocusBlockSummaryUpdating: false,
  weeklyFocusBlockSummary: [],
  isUsernameUpdating: false,
  isUserLoggingOut: false,
  isWeeklyStatsUpdating: true,
  openAIPrompt: EMPTY_STRING,
  isSavingUserFeedback: false,
  showThankYouModal: false,
  isLocalDeviceSettingUpdating: false,
  localDeviceSetting: DEFAULT_LOCAL_DEVICE_SETTING,
  showTeamNameModal: false,
  isUpdatingUserProfileDescription: false,
  isUpdatingUserProfileImage: false,
  isCancelingSubscription: false,
  showCancelReasonModal: false,
  isFetchingLogTermGoal: false,
  synced_external_platforms: DEFAULT_SYNCED_EXTERNAL_PLATFORMS,
  isFetchingSyncedExternalPlatforms: false,
  isSendingEmailVerification: false
};

export const initialSettingState: SettingSlice = {
  currentSettings: DEFAULT_SETTINGS,
  previousSettings: DEFAULT_SETTINGS,
  activityLibrary: [],
  previousActivityLibrary: [],
  isSettingLoading: false,
  isSettingLoaded: false,
  isEmbeddedWebViewLoading: false,
  mode: Mode.DEFAULT,
  platform: PLATFORMS.WEB,
  suggestions: [],
  isValidSettings: true,
  loadingSchema: false,
  showChoice: !window.location.href.includes(DASHBOARD),
  showTabs: window.location.href.includes(DASHBOARD),
  showFreeStyle: false,
  showDropProcessor: false,
  tabs: TABS.DEFAULT,
  customRoutine: EMPTY_STRING,
  activeHabitPackFocusTemplatesTab:
    HABIT_PACK_FOCUS_TEMPLATES_TAB_INDEX.ACTIVITY_LIBRARY,
  error: {
    message: ''
  },
  isYouTubeURLMetadataFetching: true,
  isShowMoreOptionsOpened: false,
  moreOptions: {
    position: -1,
    type: ''
  },
  isTokenAddedToInterceptor: false,
  areLibraryActivitiesLoading: false,
  isRoutineSummaryFetching: false,
  currentPlayingActivity: null,
  nextPlayingActivity: null,
  routinePlayerActivities: { type: null, activities: [] },
  routineSummary: [],
  isDeviceRegistering: false,
  isSavingRoutineCompletedActivity: false,
  isShowRoutineModal: false,
  isFetchingNextRoutineActivity: false,
  isTextEditorWithinCharacterLimit: true,
  activeTabIndex: 0,
  isSettingEditingFinished: false,
  areSettingsValid: true,
  activeMarketTab: MARKET_TAB_INDEX.HABIT_PACKS,
  areSettingsSaving: false,
  language: LANGUAGE.GB,
  isUnlockHabitPackModalShown: false,
  showPrivacyPolicyModal: false,
  showSkipActivityModal: false,
  routinePlayerStep: ROUTINE_PLAYER_FEATURE.ACTIVITY_CATEGORIES,
  leaders: {
    user_rank: null,
    users_rankings: []
  },
  isLeaderBoardFetching: true,
  isRequestDataConfirmationModalShown: false,
  isRequestDataProcessing: false,
  isAccountDeleteConfirmationModalShown: false,
  isAccountDeleting: false,
  isAccountDeleted: false,
  isUserSettingsModified: false,
  themeMode: THEME_OPTION.LIGHT,
  font: FONT_OPTION.SANS,
  isNewHabitAppended: false,
  isActivityLibraryUpdating: false,
  flags: [],
  isPlatformCoursesSyncing: false,
  validationErrors: [],
  feedback: {
    question: '',
    metadata: {},
    type: FEED_BACK_QUESTION.TEXT_AND_RATING
  },
  isUserOnboarding: false,
  routineTimeValidation: DEFAULT_ROUTINE_TIME_VALIDATION,
  aiTone: getRandomAiTone(),
  isSearchingDevice: false
};

export const initialHabitPackState: HabitPackSlice = {
  areHabitPacksLoading: false,
  marketplaceApprovedPacks: [],
  installedHabitPacks: [],
  userCreatedHabitPacks: [],
  featuredHabitPacks: [],
  isOnHabitPacksTab: false,
  currentViewedPack: null,
  sharedViewedPack: null,
  isCurrentHabitPackFetching: false,
  isSharedHabitPackFetching: true,
  isUserInstalledPacksFetching: true, // it will prevent a blink effect of the content until this value become true
  installingPack: false,
  uninstallingPack: false,
  isUserUpsertingPack: false,
  isHabitPackAllowedToBeEdited: false,
  isHabitPackPdfShowed: false,
  initialCurrentViewedPack: null,
  isHabitPacksDeleting: false,
  onboardingHabitPacks: [],
  isSendingInstalledHabitPackId: false
};

export const initialFocusModeState = (): FocusModeSlice => ({
  currentViewedFocusTemplate: null,
  userCreatedFocusTemplates: [],
  marketplaceApprovedFocusTemplates: [],
  areFocusTemplatesLoading: false,
  isUserInstalledFocusTemplatesFetching: true,
  userInstalledFocusTemplates: [],
  isFocusTemplateInstalling: false,
  isFocusTemplateUninstalling: false,
  isFocusTemplateFetching: false,
  sharedFocusTemplate: null,
  isSharedFocusTemplateFetching: true,
  initialCurrentViewedFocusTemplate: null,
  areTagsFetching: false,
  tags: [],
  isMarketFocusTemplatesFetching: false,
  isFocusTemplateDeleting: false
});

export const initialModalState: ModalSlice = {
  moreOption: {
    activity: DEFAULT_MORE_OPTION_ACTIVITY,
    showYouTubeURLModal: false,
    showAllowedURLModal: false,
    showChoicesModal: false,
    showImageURLModal: false,
    areLogQuestionsInvalid: false,
    isMoreOptionEdited: false,
    showCheckListModal: false,
    isNewActivity: false,
    isUseApplicationsChecked: false,
    isUseWebsiteChecked: false,
    isUseFocusModeChecked: false
  },
  choice: {
    choices: [],
    showChoicesYouTubeURLModal: false,
    selectedPosition: NUMBERS.ZERO,
    areLogQuestionsInvalid: false,
    isChoiceEdited: false
  },
  note: {
    text: undefined,
    showNoteModal: false,
    isCurrentActivityPlaying: false
  },
  logQuantity: {
    showLogQuantityModal: false,
    log_quantity_answers: [],
    areLogDataValid: false,
    showQuestionDetailsModal: false
  },
  logQuantityDetails: {
    position: null,
    logQuestion: null
  },
  addToCalendar: {
    showAddToCalendarModalOpened: false,
    activity: null
  },
  focusSummaryGraph: {
    showFocusSummaryGraphModal: false
  },
  eisenhowerMatrix: {
    isEisenhowerMatrixModalOpened: false
  },
  routineActivitiesStats: {
    activities: [],
    showRoutineActivitiesStatsModal: false,
    isRoutineStatsLoading: false,
    summaries: []
  },
  toDoSubTasks: DEFAULT_TO_DO_SUBTASKS,
  confirmAction: DEFAULT_CONFIRM_ACTION_MODAL,
  focusSummaryMore: {
    showFocusSummaryMore: false,
    focusSummary: null
  },
  keywordModal: {
    id: '',
    keyword: '',
    platform: '',
    isKeywordModalOpened: false,
    title: false,
    description: false
  },
  deleteKeywordModal: {
    id: '',
    keyword: '',
    platform: '',
    isDeleteKeywordModalOpened: false
  },
  longTermGoalsModal: {
    isLongTermGoalsModalOpened: false
  }
};

export const initialAdminState: AdminSlice = {
  users: [],
  focusModes: [],
  userActivities: [],
  skip: NUMBERS.ZERO,
  orderBy: FetchUsersOrderByOptions.ALL,
  searchBy: {
    user_id: EMPTY_STRING,
    stripe_customer_id: EMPTY_STRING,
    email: EMPTY_STRING,
    revenue_cat_status: EMPTY_STRING,
    updated_at: EMPTY_STRING
  },
  isLoading: true,
  isFetchingActivities: false,
  isFetchingFocusModes: false,
  userActivitiesFilter: ActivityTableFilter.ALL,
  activityOrFocusModeSearchResult: SEARCH_RESULT.NONE,
  showRequestDetailsModal: false,
  accessRequestedUserId: EMPTY_STRING,
  isAccessRequesting: false,
  isAccessRequestGranted: false,
  isFetchingUserDetails: false,
  isSearchByValid: {
    user_id: false,
    stripe_customer_id: false,
    email: false
  },
  isDeviceFetching: false,
  isCreatingSurvey: false,
  isUpdatingSurvey: false,
  isFetchingSurveys: false,
  showSurveyDetailModal: false,
  surveys: [],
  selectedSurvey: null
};

export const initialChatBotState: ChatBotSlice = {
  message: EMPTY_STRING,
  chatHistory: [
    {
      role: ChatRoles.ASSISTANT,
      content: i18n.t('chatbot.introduction')
    }
  ],
  aiGeneratedContent: null,
  chatBotState: ChatBotState.IDLE,
  isChatBotGenerating: false
};

export const initialFilterState: FilterSlice = {
  searchWord: EMPTY_STRING,
  priority: EMPTY_STRING,
  daysOfWeek: [],
  isSettingToolbarShown: false,
  isAdminToolbarShown: true
};

export const initialInquiryState: InquirySlice = {
  is_inquiry_modal_shown: false,
  installation_path: {
    desktop: true,
    mobile: false
  },
  reminder: {
    desktop_app: true,
    calendar: false
  }
};

export const initialGeneralState: GeneralSlice = {
  motivationalDelayCounter: NUMBERS.ZERO,
  shouldStopMotivationalDelayCounter: false,
  tabLayout: {
    activeTab: DEFAULT_TAB_LAYOUT_TAB
  }
};

export const initialCacheState: CacheSlice = {
  isYouTubeURLMetadataUpdating: false,
  isYouTubeURLMetadataFetching: false,
  youtube_urls_metadata: {
    videos_metadata: []
  }
};

export const initialProcrastinateState: ProcrastinateSlice = {
  website: {
    data: EMPTY_STRING,
    isValid: false
  },
  savedWebsites: [],
  isSavedWebsitesCreating: false,
  isSavedWebsitesFetching: false,
  isSavedWebsitesUpdating: false,
  isSavedWebsitesDeleting: false,
  selectedWebsiteIDs: []
};

export const initialToDoState: ToDoSlice = {
  tasks: [],
  completedTasks: [],
  googleCalendarData: {
    calendars: [],
    keywords: []
  },
  microsoftCalendarData: {
    calendars: [],
    keywords: []
  },
  isCreatingToDo: false,
  isFetchingToDo: false,
  isUpdatingToDo: false,
  isDeletingMultipleToDo: false,
  shouldResetNewTaskForm: false,
  shouldUpdateTextEditorContent: false,
  editing: {
    active: false
  },
  isUpdatingStatus: false,
  showEmbeddedCreateToDoModal: false,
  selectedTaskIDs: [],
  isTaskSubmitting: false,
  activeTabIndex: TO_DO_TAB.TO_DO,
  showTodoSubTasksModal: false,
  newTask: DEFAULT_NEW_TASK(),
  newTodoPlayerTask: null,
  incomingTasks: [],
  player: DEFAULT_TO_DO_PLAYER,
  showBrainDumpTasksModal: false,
  isConvertingBrianDump: false,
  isAddingConvertedTasksToPlayer: false
};

export const initialPlatformIntegrationState: PlatformIntegrationSlice = {
  areProjectsLoading: true,
  isProjectSynching: false,
  projects: [],
  areExternalStatusesSyncing: false,
  showPlatformProjects: false,
  statusToCountAsDone: [],
  currentProjectId: EMPTY_STRING,
  currentPlatform: null,
  projectsToBeSynced: [],
  showProjectTasksModal: false,
  projectTasksModal: {
    tasks: []
  },
  isLoadingProjectTasks: true,
  isSynchingProjectTasks: false
};

export const initialTeamState: TeamSlice = {
  teams: [],
  selectedTeam: null,
  areTeamsLoading: false,
  areMembersLoading: false,
  teamMembers: DEFAULT_TEAM_MEMBERS,
  isInvitingUser: false,
  isAssigningAdmin: false,
  isRemovingAdmin: false,
  isRemovingMember: false,
  isUpdatingTeamName: false,
  isDeletingTeam: false,
  showTeamOwnerActionModal: false,
  isAcceptingInvitation: false,
  isInvitationAccepted: false,
  isUpdatingExpiryDate: false,
  selectedMemberId: EMPTY_STRING,
  selectedMembersId: [],
  searchMember: EMPTY_STRING,
  showTeamOwnerMassUpdateModal: false,
  isExpiryDateMassUpdating: false,
  isMemberTypeMassUpdating: false,
  hasInvitationAlreadyAccepted: false,
  isAddingMemberManually: false
};

export const initialPaginationState: PaginationSlice = {
  paginationType: null,
  currentPage: PAGE.FIRST,
  totalPages: PAGE.INIT_TOTAL,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  hasPreviousPage: false,
  hasNextPage: false
};

export const initialCourseState: CourseSlice = {
  details: null,
  isLoadingCourseDetails: true,
  showCourseLessonsModal: false,
  isCreatingLessonCompletion: false,
  isLessonCompletionSuccess: false,
  isFetchingTutorials: false,
  tutorials: [],
  other_courses: [],
  enrolled_courses: [],
  isFetchingOtherCourses: false,
  isFetchingEnrolledCourses: false,
  isCreatingCourseEnrolment: false,
  showCourseDetailsModal: false,
  isUpdatingCourseCompletion: false
};

export const initialRoutineSuggestions: RoutineSuggestionsSlice = {
  isFetchingRoutineSuggestions: false,
  routineSuggestions: null,
  routineSuggestionPayload: {
    user_goals: [],
    routine_duration: DEFAULT_ROUTINE_DURATION,
    routines: DEFAULT_ROUTINES_SUGGESTIONS
  },
  areSuggestedHabitPacksInstalled: false,
  isInstallingRoutineSuggestions: false,
  showEditSuggestionsModal: false,
  showAvailableGoalsModal: false,
  selectedGoals: [],
  availableGoals: DEFAULT_GOAL_SUGGESTIONS
};

export const initialSubscriptionState: SubscriptionSlice = {
  stripPlans: [],
  isFetchingStripPlans: false,
  isCheckingSubscription: false,
  subscriptionInfo: {},
  isSubscriptionChecking: false,
  subscriptions: [],
  activeEntitlements: [],
  entitlements: []
};
