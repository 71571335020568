import Option from 'assets/icons/Option';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsInquiryModalShown } from 'store/reducer/inquiry/slice';
import { getPackIds } from 'utils/helpers';
import LanguageDropDown from '../layout/LanguageDropDown';

const NavMenusRight = () => {
  const dispatch = useAppDispatch();
  const { currentViewedPack, installedHabitPacks } = useAppSelector(
    (state) => state.habitPack
  );
  const { pathname } = useLocation();
  const installedPacksIds = getPackIds(installedHabitPacks);
  const userHasPackInstalled =
    currentViewedPack?.id && installedPacksIds.includes(currentViewedPack?.id);

  return (
    <div className='w-fit flex items-center gap-4'>
      {pathname.includes('habit-packs/') && userHasPackInstalled && (
        <button
          className='w-fit h-fit'
          onClick={() => {
            dispatch(updateIsInquiryModalShown(true));
          }}
        >
          <Option />
        </button>
      )}
      <LanguageDropDown />
    </div>
  );
};

export default NavMenusRight;
