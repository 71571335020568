import { PropsWithChildren } from 'react';
import { isDarkThemeActivated } from 'utils/settings.util';

export const ModalWrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkTheme = isDarkThemeActivated();
  return (
    <div
      className={`relative top-[15%] -translate-y-[15%] m-auto w-[90%] h-fit flex flex-col gap-2 pb-1 shadow-lg rounded-md ${
        shouldActivateDarkTheme ? 'bg-gray-700' : 'bg-white'
      }`}
    >
      {children}
    </div>
  );
};

export const ModalContentWrapper = ({
  children
}: PropsWithChildren<object>) => (
  <div className='w-full h-fit flex flex-col gap-10 sm:gap-6 px-5 max-h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400'>
    {children}
  </div>
);

export const ModalContentHeaderWrapper = ({
  children
}: PropsWithChildren<object>) => (
  <div className='flex flex-col sm:flex-row items-center gap-2 relative'>
    {children}
  </div>
);
