import * as Sentry from '@sentry/react';
import {
  HTTP_STATS_CODE,
  LOCAL_STORAGE,
  PLATFORM_INDEX,
  TO_DO_TAB
} from 'constants/general';
import { ROUTES } from 'constants/routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import focusBearApi from 'services/axios-config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateActiveTabIndex } from 'store/reducer/to-do/slice';
import { updateSyncedExternalPlatforms } from 'store/reducer/user/slice';
import { getLocalStorage } from 'utils/helpers';
import { getPlatformEndpoint } from 'utils/todo-integration.util';
import CallbackComponent from './CallbackComponent';

const IntegrationCallback = () => {
  const { platform } = useParams<string>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state);

  useEffect(() => {
    handleCallback();
  }, []);

  const getParams = () => {
    const token = new URLSearchParams(window.location.hash.substring(1))?.get(
      'token'
    );
    if (token) {
      searchParams.append('code', token);
    }
    return searchParams.toString();
  };

  const handleError = () => {
    toast.error(t('to_do_procrastinate.could_not_connect_the_platform'));
    navigate(ROUTES.NOT_FOUND);
  };

  const handleCallback = async () => {
    try {
      const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
      const endpoint = getPlatformEndpoint(platform);
      if (!token || !endpoint) {
        handleError();
      } else {
        const platform = endpoint?.toLowerCase().split('/')[PLATFORM_INDEX];
        const { status } = await focusBearApi.get(
          `${endpoint}/callback?${getParams()}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN)}`
            }
          }
        );
        if (status === HTTP_STATS_CODE.SUCCESS) {
          toast.success(
            t('to_do_procrastinate.platform_successfully_connected')
          );
          dispatch(
            updateSyncedExternalPlatforms({
              ...user?.synced_external_platforms,
              [platform]: true
            })
          );
        } else {
          handleError();
        }
      }
      dispatch(updateActiveTabIndex(TO_DO_TAB.INTEGRATIONS));
      navigate(ROUTES.TODO);
    } catch (error) {
      handleError();
      Sentry.captureException(JSON.stringify(error));
    }
  };

  return platform ? <CallbackComponent platform={platform} /> : null;
};

export default IntegrationCallback;
