import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { FocusModeTemplateType } from 'interfaces/common.interface';
import {
  getFocusModeTags,
  getFocusTemplateById,
  getMarketplaceApprovedFocusTemplates,
  getUserCreatedFocusTemplates,
  getUserInstalledFocusTemplates
} from './extra';
import { initialFocusModeState } from 'store/initial-states';
import { Tag } from 'interfaces';
import { FOCUS_TEMPLATE_FEATURE } from 'constants/general';

const focusMode = createSlice({
  name: 'focus-mode',
  initialState: initialFocusModeState,
  reducers: {
    setCurrentViewedFocusTemplate: (
      state,
      action: PayloadAction<FocusModeTemplateType>
    ) => {
      state.currentViewedFocusTemplate = action.payload;
    },
    updateCurrentViewedFocusTemplate: (
      state,
      {
        payload: { focusTemplateFeature, value }
      }: PayloadAction<{
        focusTemplateFeature: string;
        value: string | string[] | boolean;
      }>
    ) => {
      let currentViewedFocusTemplate = current(
        state.currentViewedFocusTemplate
      );
      switch (focusTemplateFeature) {
        case FOCUS_TEMPLATE_FEATURE.NAME:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            name: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.DESCRIPTION:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            description: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.DESCRIPTION_VIDEO_URL:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            description_video_url: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.WELCOME_MESSAGE:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            welcome_message: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.WELCOME_VIDEO_URL:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            welcome_video_url: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.AUTHOR_NAME:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            author_name: value as string
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.ALLOWED_URLS:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            allowed_urls: value as string[]
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.ALLOWED_APPS:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            allowed_apps: value as string[]
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.IS_FEATURED:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            is_featured: value as boolean
          };
          break;
        case FOCUS_TEMPLATE_FEATURE.FEATURE_FOR_ONBOARDING:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            featured_for_onboarding: value as boolean
          };
          break;
        default:
          currentViewedFocusTemplate = {
            ...currentViewedFocusTemplate,
            marketplace_approval_status: value as boolean
          };
      }
      state.currentViewedFocusTemplate = currentViewedFocusTemplate;
    },
    updateIsUserInstalledFocusTemplatesFetching: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUserInstalledFocusTemplatesFetching = action.payload;
    },
    resetFocusModeState: () => initialFocusModeState(),
    updateIsFocusTemplateInstalling: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFocusTemplateUninstalling = payload;
    },
    updateIsFocusTemplateUninstalling: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFocusTemplateUninstalling = payload;
    },
    updateIsFocusTemplateFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFocusTemplateFetching = payload;
    },
    updateSharedFocusTemplate: (state, { payload }) => {
      state.sharedFocusTemplate = payload;
    },
    updateIsSharedFocusTemplateFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSharedFocusTemplateFetching = payload;
    },
    updateAreFocusTemplatesLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areFocusTemplatesLoading = payload;
    },
    updateInitialCurrentViewedFocusTemplate: (
      state,
      { payload }: PayloadAction<FocusModeTemplateType | null>
    ) => {
      state.initialCurrentViewedFocusTemplate = payload;
    },
    updateAreTagsFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.areTagsFetching = payload;
    },
    updateTags: (state, { payload }: PayloadAction<Tag[]>) => {
      state.tags = payload;
    },
    updateIsMarketFocusTemplatesFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isMarketFocusTemplatesFetching = payload;
    },
    updateIsFocusTemplateDeleting: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFocusTemplateDeleting = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCreatedFocusTemplates.fulfilled, (state, { payload }) => {
        state.userCreatedFocusTemplates = payload;
        state.areFocusTemplatesLoading = false;
      })
      .addCase(getUserCreatedFocusTemplates.rejected, (state) => {
        state.areFocusTemplatesLoading = false;
      })
      .addCase(
        getMarketplaceApprovedFocusTemplates.fulfilled,
        (state, action) => {
          state.marketplaceApprovedFocusTemplates = action.payload ?? [];
          state.isMarketFocusTemplatesFetching = false;
        }
      )
      .addCase(getMarketplaceApprovedFocusTemplates.rejected, (state) => {
        state.isMarketFocusTemplatesFetching = false;
      })
      .addCase(
        getUserInstalledFocusTemplates.fulfilled,
        (state, { payload }) => {
          state.userInstalledFocusTemplates = payload;
          state.isUserInstalledFocusTemplatesFetching = false;
        }
      )
      .addCase(getUserInstalledFocusTemplates.rejected, (state) => {
        state.isUserInstalledFocusTemplatesFetching = false;
      })
      .addCase(getFocusTemplateById.fulfilled, (state, { payload }) => {
        state.currentViewedFocusTemplate = payload;
        state.isFocusTemplateFetching = false;
      })
      .addCase(getFocusTemplateById.rejected, (state) => {
        state.isFocusTemplateFetching = false;
      })
      .addCase(getFocusModeTags.fulfilled, (state, { payload }) => {
        state.tags = payload;
        state.areTagsFetching = false;
      });
  }
});

export const {
  resetFocusModeState,
  updateIsUserInstalledFocusTemplatesFetching,
  setCurrentViewedFocusTemplate,
  updateIsFocusTemplateInstalling,
  updateIsFocusTemplateUninstalling,
  updateIsFocusTemplateFetching,
  updateSharedFocusTemplate,
  updateIsSharedFocusTemplateFetching,
  updateAreFocusTemplatesLoading,
  updateInitialCurrentViewedFocusTemplate,
  updateAreTagsFetching,
  updateTags,
  updateIsMarketFocusTemplatesFetching,
  updateIsFocusTemplateDeleting,
  updateCurrentViewedFocusTemplate
} = focusMode.actions;
export default focusMode.reducer;
