import { TOAST } from 'constants/general';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { copyActivitySelector } from 'store/reducer/setting/selectors';
import { copyActivity } from 'store/reducer/setting/slice';
import { getCopiedActivity } from 'utils/settings.util';
import { increment } from 'utils/helpers';

const CopyActivity = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    activity,
    moreOptions: { type, position }
  } = useAppSelector(copyActivitySelector);

  const handleClick = useCallback(() => {
    dispatch(
      copyActivity({
        activity: getCopiedActivity(activity),
        position: increment(position),
        type
      })
    );
    toast.info(t('copied'), { autoClose: TOAST.AUTO_CLOSE.COPIED });
  }, [activity]);

  return (
    <button
      className='w-fit max-w-[80vw] sm:max-w-[30vw] h-fit bg-gray-500 hover:bg-gray-600 rounded-md px-3 py-1 text-white text-xs truncate'
      onClick={handleClick}
    >
      {t('copy_activity', { activity_name: activity?.name })}
    </button>
  );
};

export default CopyActivity;
