import CourseDetails from 'components/course/CourseDetails';
import InitEmbeddedServices from 'dashboard/InitEmbeddedServices';
import { useAppSelector } from 'store/hooks';

import * as Sentry from '@sentry/react';
import { loadAccessTokenForCourse } from 'utils/focusBear_apps';
import { useEffect } from 'react';
import { coursePageSelector } from 'store/reducer/course/selectors';

const Course = () => {
  const isTokenAddedToInterceptor = useAppSelector(coursePageSelector);

  useEffect(() => {
    loadAccessTokenForCourse();
  }, []);

  return !isTokenAddedToInterceptor ? (
    <InitEmbeddedServices />
  ) : (
    <CourseDetails />
  );
};

export default Sentry.withProfiler(Course);
