import React, { useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { PAGES } from 'constants/routes';
import { DetailsPageWrapperContext } from '../wrappers/DetailsPageWrapper';

const DetailsPageHeader = () => {
  const {
    habitPack: { currentViewedPack },
    focusMode: { currentViewedFocusTemplate }
  } = useAppSelector((state) => state);
  const { page } = useContext(DetailsPageWrapperContext);
  const name =
    page === PAGES.HABIT_PACK
      ? currentViewedPack?.pack_name ?? ''
      : currentViewedFocusTemplate?.name ?? '';
  const author =
    page === PAGES.HABIT_PACK
      ? currentViewedPack?.creator_name ?? ''
      : currentViewedFocusTemplate?.author_name ?? '';
  return (
    <div className='w-full h-fit lg:w-3/4 text-center flex flex-col sm:flex-row justify-center items-center gap-2 py-4 self-end'>
      <span className='~text-xl/2xl capitalize line-clamp-2'>{name}</span>
      <span className='w-0.5 h-6 bg-sunRayFirst hidden sm:inline-block'></span>
      <span className='text-sunRayFirst ~text-sm/lg'>{author}</span>
    </div>
  );
};

export default React.memo(DetailsPageHeader);
