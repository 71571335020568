import { IconProps } from 'interfaces';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validators';
import { themeSelector } from 'store/reducer/setting/selectors';

const MoveTop = ({
  fill = COLOR.BLACK,
  styles = 'w-auto h-4 md:h-5'
}: IconProps) => {
  return (
    <svg
      fill={
        !isDarkModeActivated(useAppSelector(themeSelector)) ? fill : COLOR.WHITE
      }
      viewBox='0 0 1920 1920'
      xmlns='http://www.w3.org/2000/svg'
      className={styles}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path
          d='M338.824 57.966v222.155H0v1581.29h225.882V506.117h112.942v222.268l406.814-335.21L338.824 57.967Zm1355.407 448.15h225.882V280.122H1694.23v225.996Zm-338.937 0h225.995V280.122h-225.995v225.996Zm-338.823 0h225.882V280.122H1016.47v225.996ZM677.76 957.882h1242.353V732H677.76v225.882Zm0 451.765h1242.353v-225.995H677.76v225.995Zm0 451.765h1242.353v-225.883H677.76v225.883Z'
          fillRule='evenodd'
        ></path>
      </g>
    </svg>
  );
};

export default MoveTop;
