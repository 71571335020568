import {
  HABIT_SETTING_FEATURES,
  KEY_BOARD,
  MAXIMUM_ALLOWED_MINUTES,
  NUMBERS
} from 'constants/general';
import { ATTRIB } from 'constants/test';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { tabBodyRowDurationSelector } from 'store/reducer/setting/selectors';
import {
  updateIsUnlockHabitPackModalShown,
  updateSettingFeatures
} from 'store/reducer/setting/slice';
import {
  checkActivityDurationAgainstRelaxFocusModeDuration,
  isDarkThemeActivated
} from 'utils/settings.util';
import {
  convertToMinutes,
  convertToSeconds,
  getValidNumber,
  validateDuration
} from 'utils/helpers';
import { TabBodyRowContext } from '.';

const TabBodyRowDuration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  } = useAppSelector(tabBodyRowDurationSelector);
  const {
    type,
    position,
    currentActivity,
    isEditingAllowed,
    customRoutinePosition
  } = useContext(TabBodyRowContext);
  const [duration, setDuration] = useState({
    minutes: String(
      convertToMinutes(currentActivity?.duration_seconds ?? NUMBERS.ZERO)
    ),
    seconds: String(
      (currentActivity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY
    )
  });

  useEffect(() => {
    if (activity?.id === currentActivity?.id) {
      setDuration({
        minutes: String(
          convertToMinutes(activity?.duration_seconds ?? NUMBERS.ZERO)
        ),
        seconds: String(
          (activity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY
        )
      });
    }
  }, [activity.duration_seconds]);

  const handleDuration = useCallback(
    (value: string, isEditingInSeconds?: boolean) => {
      let total_duration: number | undefined = undefined;
      const parsedValue = Math.floor(parseInt(value, 10));
      const isValidInput = Number.isInteger(parsedValue) && parsedValue >= 0;

      if (isEditingInSeconds) {
        if (isValidInput) {
          total_duration = convertToSeconds(
            convertToMinutes(currentActivity?.duration_seconds ?? NUMBERS.ZERO),
            getValidNumber(value)
          );
          setDuration({
            seconds: (total_duration % NUMBERS.SIXTY)?.toString(),
            minutes: convertToMinutes(total_duration).toString()
          });
        } else {
          total_duration = getValidNumber(duration.minutes);
          setDuration((prev) => ({ ...prev, seconds: '' }));
        }
      } else {
        if (isValidInput) {
          total_duration = convertToSeconds(
            getValidNumber(value),
            getValidNumber(duration.seconds)
          );
          setDuration((prev) => ({
            ...prev,
            minutes: convertToMinutes(total_duration as number).toString()
          }));
        } else {
          total_duration = getValidNumber(duration.seconds);
          setDuration((prev) => ({ ...prev, minutes: '' }));
        }
      }

      if (total_duration) {
        validateDuration(total_duration);
        cutoff_time &&
          checkActivityDurationAgainstRelaxFocusModeDuration(
            total_duration,
            user_focus_modes,
            cutoff_time,
            shutdown_time,
            evening_activities,
            currentActivity
          );
      }
      dispatch(
        updateSettingFeatures({
          type: type,
          position: position,
          feature: HABIT_SETTING_FEATURES.DURATION,
          value: total_duration ?? 0,
          customRoutinePosition
        })
      );
    },
    [
      currentActivity?.duration_seconds,
      duration.minutes,
      duration.seconds,
      position,
      dispatch,
      type,
      cutoff_time,
      user_focus_modes,
      shutdown_time,
      evening_activities
    ]
  );

  const isDurationValid = useMemo(() => {
    const duration = currentActivity?.duration_seconds ?? NUMBERS.ZERO;
    return (
      duration > NUMBERS.ZERO &&
      duration <= convertToSeconds(MAXIMUM_ALLOWED_MINUTES)
    );
  }, [currentActivity?.duration_seconds]);

  const shouldActivateDarkTheme = useMemo(
    () => isDarkThemeActivated(),
    [themeMode]
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === KEY_BOARD.HYPHEN.KEY ||
        event.key === KEY_BOARD.LETTER_E.KEY
      ) {
        event.preventDefault(); // Prevent typing negative values or exponent notation
      }
    },
    []
  );

  return (
    <div
      className='w-full md:w-2/5 lg:w-[35%] 2xl:w-1/2 h-fit flex items-start justify-start gap-2'
      style={{ marginTop: '-5px' }}
    >
      <div className='w-fit md:w-1/4 flex flex-col relative'>
        <p className='text-[0.6rem] md:text-xs pb-0.5 font-medium'>
          {t('duration')}
        </p>
        <input
          data-test={ATTRIB.TEST.INPUT_HABIT_DURATION_MINUTE}
          data-locator={ATTRIB.LOCATOR.HABIT_DURATION_MINUTE}
          id={ATTRIB.TEST.INPUT_HABIT_DURATION_MINUTE}
          readOnly={!isEditingAllowed}
          className={`min-w-16 w-full pl-2 py-2 rounded text-sm font-medium outline-none text-black text-center border ${
            isDurationValid ? 'border-gray-200' : 'border-red-400'
          } ${shouldActivateDarkTheme ? 'bg-gray-400' : 'bg-white'}`}
          style={{
            height: '2rem',
            lineHeight: '2rem' // Ensure alignment
          }}
          type='number'
          value={duration.minutes}
          onChange={({ target: { value } }) => handleDuration(value)}
          step={NUMBERS.ONE}
          placeholder='10'
          onFocus={() => {
            !isEditingAllowed &&
              dispatch(updateIsUnlockHabitPackModalShown(true));
          }}
          onKeyDown={handleOnKeyDown}
        />
        <div className='absolute -bottom-3.5 xl:-bottom-4 left-1 text-[9px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {t('minutes')}
        </div>
      </div>
      <div
        className='flex items-center justify-center mx-1'
        style={{ height: '2rem', lineHeight: '2rem', marginTop: '1.1rem' }}
      >
        <span className='text-2xl font-medium'>:</span>
      </div>
      <div
        className='w-fit md:w-1/4 flex flex-col relative'
        style={{ marginTop: '1.1rem' }}
      >
        <input
          data-test={ATTRIB.TEST.INPUT_HABIT_DURATION_SECOND}
          data-locator={ATTRIB.LOCATOR.HABIT_DURATION_SECOND}
          id={ATTRIB.TEST.INPUT_HABIT_DURATION_SECOND}
          readOnly={!isEditingAllowed}
          className={`min-w-16 w-full pl-2 py-2 rounded text-sm font-medium outline-none text-black text-center border ${
            isDurationValid ? 'border-gray-200' : ' border-red-400'
          } ${shouldActivateDarkTheme ? 'bg-gray-400' : 'bg-white'}`}
          style={{
            height: '2rem',
            lineHeight: '2rem' // Ensure alignment
          }}
          type='number'
          value={duration.seconds}
          onChange={({ target: { value } }) => handleDuration(value, true)}
          step={NUMBERS.ONE}
          min={NUMBERS.ZERO}
          placeholder='30'
          onFocus={() => {
            !isEditingAllowed &&
              dispatch(updateIsUnlockHabitPackModalShown(true));
          }}
          onKeyDown={handleOnKeyDown}
        />
        <div className='absolute -bottom-3.5 xl:-bottom-4 left-1 text-[9px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {`${t('seconds')}`}
        </div>
      </div>
    </div>
  );
};

export default TabBodyRowDuration;
