import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ChoicesModal from '../ChoicesModal';
import { FLAG, Mode, THEME_OPTION } from 'constants/enum';
import URLModal from '../URLModal';
import {
  ACTIVITY_TYPES,
  EMPTY_STRING,
  KEY_BOARD,
  LOG_SUMMARY_OPTION,
  MAXIMUM_ALLOWED_MINUTES,
  MODAL_TYPES,
  MORE_OPTION_TAB,
  NUMBERS,
  PLATFORMS,
  SCREEN
} from 'constants/general';
import {
  updateIsShowMoreOptionsOpened,
  updateActivityFromMoreOptions
} from 'store/reducer/setting/slice';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import {
  convertToMinutes,
  convertToSeconds,
  getRoutineActivitiesOfCurrentSettings,
  getValidNumber,
  validateDuration
} from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsYouTubeURLMetadataUpdating } from 'store/reducer/cache/slice';
import { fetchYoutubeURLMetadata } from 'store/reducer/cache/extra';
import YouTubeVideos from './YouTubeVideos';
import AllowedApps from './AllowedApps';
import LogData from './LogData';
import Advanced from './Advanced';
import ImagesModal from '../ImagesModal';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import {
  updateIsMoreOptionEdited,
  updateMoreOptionAreLogQuestionsInvalid,
  updateMoreOptionDuration,
  updateMoreOptionLogSummaryType,
  updateMoreOptionName
} from 'store/reducer/modal/slice';
import Timing from './Timing';
import { ATTRIB } from 'constants/test';
import _ from 'lodash';
import { SettingsPageContext } from 'components/setting-generator';
import { Video_Metadata } from 'interfaces/setting.interface';
import Warning from 'assets/icons/Warning';
import moment from 'moment';
import CheckListsModal from './Settings/CheckListModal';
import Settings from './Settings';
import Accordion from 'components/common/Accordion';
import Instructions from './Instructions';
import { updateTutorials } from 'store/reducer/course/slice';
import { DEFAULT_MORE_OPTION_TABS } from 'assets/data';
import { t } from 'i18next';
import NerdFace from 'assets/icons/NerdFace';
import SmilingFaceWithSunglasses from 'assets/icons/SmilingFaceWithSunglasses';
import Tags from './Tags';
import {
  checkActivityDurationAgainstRelaxFocusModeDuration,
  isDarkThemeActivated,
  isMobilePlatform
} from 'utils/settings.util';
import { TabBodyRowContext } from '../tabBodyRow';
import { isActivityCutoffTimeValid } from 'utils/validators';
import Tooltip from 'components/common/Tooltip';
import {
  desktopViewModalContentBodySelector,
  modalContentHeaderHabitDurationSelector,
  modalContentHeaderHabitNameSelector,
  modalContentHeaderSelector,
  modalContentSelector,
  moreOptionModalActionsSelector,
  moreOptionModalSelector
} from 'store/reducer/modal/more-options.modal.selectors';
import {
  ModalContentHeaderWrapper,
  ModalContentWrapper,
  ModalWrapper
} from './moreOptionsWrapper';

export const DEFAULT_MORE_OPTIONS_TABS_CONTENTS = {
  [MORE_OPTION_TAB.TIMING]: <Timing />,
  [MORE_OPTION_TAB.YOUTUBE_VIDEOS]: <YouTubeVideos />,
  [MORE_OPTION_TAB.ALLOWED_APPS]: <AllowedApps />,
  [MORE_OPTION_TAB.LOG_DATA]: <LogData />,
  [MORE_OPTION_TAB.SETTINGS]: <Settings />,
  [MORE_OPTION_TAB.ADVANCED]: <Advanced />,
  [MORE_OPTION_TAB.INSTRUCTIONS]: <Instructions />
};

interface DesktopViewModalContentBodyProps {
  more_options_tabs: {
    title: string | JSX.Element;
    test_id: string;
    tab_content_index: string;
  }[];
}

const ModalActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    moreOption: {
      activity,
      showYouTubeURLModal,
      showAllowedURLModal,
      areLogQuestionsInvalid,
      showChoicesModal,
      isMoreOptionEdited
    },
    moreOptions: { type, position },
    currentSettings,
    mode,
    activityLibrary,
    tutorials
  } = useAppSelector(moreOptionModalActionsSelector);
  const { customRoutinePosition } = useContext(TabBodyRowContext);

  useEffect(() => {
    checkOptionsChanges();
  }, [activity]);

  useEffect(() => {
    validateLogQuestionsChanges();
  }, [showChoicesModal]);

  const isDurationValid =
    activity?.duration_seconds &&
    activity?.duration_seconds <= convertToSeconds(MAXIMUM_ALLOWED_MINUTES);
  const isActivityNameValid = Boolean(activity?.name);
  const isCutoffTimeValid = isActivityCutoffTimeValid(
    activity.cutoff_time_for_doing_activity,
    currentSettings.cutoff_time_for_non_high_priority_activities
  );

  const isSavingNotAllowed =
    showYouTubeURLModal ||
    showAllowedURLModal ||
    showChoicesModal ||
    areLogQuestionsInvalid ||
    !isDurationValid ||
    !isActivityNameValid ||
    !isCutoffTimeValid;

  const handleSave = () => {
    dispatch(
      updateActivityFromMoreOptions({
        type,
        position,
        value: activity,
        customRoutinePosition
      })
    );
    dispatch(updateIsShowMoreOptionsOpened(false));

    dispatch(
      updateTutorials(
        tutorials.filter((tutorial) => tutorial.id !== activity.tutorial)
      )
    );
  };

  const validateLogQuestionsChanges = () => {
    const questions = activity?.log_quantity_questions ?? [];
    const areQuestionsInvalid =
      questions.filter((item) => item.question === EMPTY_STRING).length >
      NUMBERS.ZERO;
    dispatch(
      updateMoreOptionAreLogQuestionsInvalid(
        questions.length ? areQuestionsInvalid : false
      )
    );
  };

  const checkOptionsChanges = () => {
    const routineActivities =
      mode === Mode.LIBRARY
        ? activityLibrary
        : getRoutineActivitiesOfCurrentSettings(type, currentSettings);
    const isEdited = !_.isEqual(activity, routineActivities[position]);
    dispatch(updateIsMoreOptionEdited(isEdited));
  };

  return isMoreOptionEdited ? (
    <button
      data-test={ATTRIB.TEST.SAVE_MORE_OPTIONS}
      disabled={isSavingNotAllowed}
      onClick={handleSave}
      className={`w-fit h-fit text-sm sm:text-base font-medium px-4 md:px-7 py-1 rounded-md self-end ${
        isSavingNotAllowed
          ? 'text-gray-500 bg-transparent border-2 border-gray-500 cursor-not-allowed'
          : 'text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300'
      } mr-6 my-1.5`}
    >
      {t('save')}
    </button>
  ) : (
    <></>
  );
};

const ModalContentHeaderHabitName = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity, themeMode } = useAppSelector(
    modalContentHeaderHabitNameSelector
  );
  const isActivityNameValid = Boolean(activity?.name);

  const doesActivityUseTimer = Boolean(!activity?.completion_requirements);
  return (
    <div
      className={`w-full ${
        doesActivityUseTimer && 'md:w-3/4 lg:w-4/5 xl:w-5/6'
      } h-fit flex flex-col justify-center`}
    >
      <h6 className='text-xs pb-0.5 font-medium text-left'>{t('habit')}</h6>
      <input
        className={`py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-black ${
          isActivityNameValid ? 'border-none' : 'border border-red-400'
        } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-gray-100'}`}
        type='text'
        value={activity?.name ?? ''}
        onChange={({
          target: { value }
        }: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(updateMoreOptionName(value));
        }}
        placeholder={t('placeholder.reading')}
      />
    </div>
  );
};

const ModalContentHeaderHabitDuration = () => {
  const dispatch = useAppDispatch();
  const {
    activity,
    themeMode,
    cutoff_time,
    evening_activities,
    shutdown_time,
    user_focus_modes
  } = useAppSelector(modalContentHeaderHabitDurationSelector);
  const [duration, setDuration] = useState({
    minutes: String(
      convertToMinutes(activity?.duration_seconds ?? NUMBERS.ZERO)
    ),
    seconds: String(
      (activity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY
    )
  });

  const shouldActivateDarkTheme = useMemo(
    () => isDarkThemeActivated(),
    [themeMode]
  );

  const handleDuration = useCallback(
    (value: string, isEditingInSeconds?: boolean) => {
      let total_duration: number | undefined = undefined;
      const parsedValue = Math.floor(parseInt(value, 10));
      const isValidInput = Number.isInteger(parsedValue) && parsedValue >= 0;

      if (isEditingInSeconds) {
        if (isValidInput) {
          total_duration = convertToSeconds(
            convertToMinutes(activity?.duration_seconds ?? NUMBERS.ZERO),
            getValidNumber(value)
          );
          setDuration({
            seconds: (total_duration % NUMBERS.SIXTY)?.toString(),
            minutes: convertToMinutes(total_duration).toString()
          });
        } else {
          total_duration = getValidNumber(duration.minutes);
          setDuration((prev) => ({ ...prev, seconds: '' }));
        }
      } else {
        if (isValidInput) {
          total_duration = convertToSeconds(
            getValidNumber(value),
            getValidNumber(duration.seconds)
          );
          setDuration((prev) => ({
            ...prev,
            minutes: convertToMinutes(total_duration as number).toString()
          }));
        } else {
          total_duration = getValidNumber(duration.seconds);
          setDuration((prev) => ({ ...prev, minutes: '' }));
        }
      }

      if (total_duration) {
        validateDuration(total_duration);
        cutoff_time &&
          checkActivityDurationAgainstRelaxFocusModeDuration(
            total_duration,
            user_focus_modes,
            cutoff_time,
            shutdown_time,
            evening_activities,
            activity
          );
      }
      dispatch(updateMoreOptionDuration(total_duration ?? 0));
    },
    [activity?.duration_seconds, duration.minutes, duration.seconds]
  );

  const isDurationValid = useMemo(() => {
    const duration = activity?.duration_seconds ?? NUMBERS.ZERO;
    return (
      duration > NUMBERS.ZERO &&
      duration <= convertToSeconds(MAXIMUM_ALLOWED_MINUTES)
    );
  }, [activity?.duration_seconds]);

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === KEY_BOARD.HYPHEN.KEY ||
        event.key === KEY_BOARD.LETTER_E.KEY
      ) {
        event.preventDefault(); // Prevent typing negative values or exponent notation
      }
    },
    []
  );

  return (
    <div className='w-full md:w-1/4 lg:w-1/5 xl:w-1/6 h-fit flex items-end gap-2'>
      <div className='w-1/2 flex flex-col relative'>
        <div className='text-xs pb-0.5 font-medium '>{t('duration')}</div>
        <input
          className={`w-full py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-center text-black border ${
            isDurationValid ? 'border-gray-200' : 'border-red-400'
          } ${shouldActivateDarkTheme ? 'bg-gray-400' : 'bg-gray-100'}`}
          type='number'
          value={duration.minutes}
          onChange={({ target: { value } }) => handleDuration(value)}
          step={1}
          placeholder='10'
          onKeyDown={handleOnKeyDown}
        />
        <div className='absolute -bottom-3 lg:-bottom-4 right-0 text-[10px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {t('minutes')}
        </div>
      </div>
      <div className='w-1/2 relative'>
        <input
          className={`w-full py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-center text-black border ${
            shouldActivateDarkTheme ? 'bg-gray-400' : 'bg-gray-100'
          } ${isDurationValid ? 'border-gray-200' : 'border border-red-400'} `}
          type='number'
          value={duration.seconds}
          onChange={({ target: { value } }) => handleDuration(value, true)}
          step={NUMBERS.ONE}
          placeholder='30'
          onKeyDown={handleOnKeyDown}
        />
        <div className='absolute -bottom-3 lg:-bottom-4 right-0 text-[10px] lg:text-xs 2xl:text-xs font-medium'>
          {t('seconds')}
        </div>
      </div>
    </div>
  );
};

const ModalContentHeader = () => {
  const { activity, flags, platform, customRoutine } = useAppSelector(
    modalContentHeaderSelector
  );

  const doesActivityUseTimer = Boolean(!activity?.completion_requirements);
  const isGeekMode = flags.includes(FLAG.GEEK_MODE);

  return (
    <ModalContentHeaderWrapper>
      <ModalContentHeaderHabitName />
      {doesActivityUseTimer && <ModalContentHeaderHabitDuration />}
      {platform !== PLATFORMS.WEB && (
        <span className='absolute md:relative top-1 md:top-0 -right-4 md:right-0 w-fit px-4'>
          {!customRoutine &&
            (isGeekMode ? <NerdFace /> : <SmilingFaceWithSunglasses />)}
        </span>
      )}
    </ModalContentHeaderWrapper>
  );
};

const DesktopViewModalContentBody = ({
  more_options_tabs
}: DesktopViewModalContentBodyProps) => {
  const dispatch = useAppDispatch();
  const { themeMode, activity } = useAppSelector(
    desktopViewModalContentBodySelector
  );

  useEffect(() => {
    const choices = activity?.choices ?? [];
    if (choices.length) {
      dispatch(updateMoreOptionLogSummaryType(LOG_SUMMARY_OPTION.SUMMATION));
    }
  }, [activity.choices]);

  return (
    <Tabs
      className='w-full h-fit max-h-[60vh] pb-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300'
      selectedTabClassName={`${
        themeMode === THEME_OPTION.DARK
          ? 'bg-gray-800 border-b-none'
          : 'bg-white border-t border-gray-200'
      } rounded-t`}
    >
      <TabList className='text-sm font-medium my-2 border-b border-gray-200'>
        {more_options_tabs.map((tab) => (
          <Tab key={tab.test_id} data-test={tab.test_id}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      {more_options_tabs.map((tab, idx) => (
        <TabPanel key={`${tab.test_id}${idx}`}>
          {DEFAULT_MORE_OPTIONS_TABS_CONTENTS[tab.tab_content_index]}
        </TabPanel>
      ))}
      <TabPanel>
        <Timing />
      </TabPanel>
    </Tabs>
  );
};

const ModalContent = () => {
  const { activityType, platform, flags, mode, videos_metadata, activity } =
    useAppSelector(modalContentSelector);
  let found_duration: number;
  const settingPageContext = useContext(SettingsPageContext);
  const shouldActiveMobileView =
    (settingPageContext.width ?? SCREEN.WIDTH.SMALL) <= SCREEN.WIDTH.SMALL;

  const more_options_tabs = DEFAULT_MORE_OPTION_TABS.filter((tab) => {
    if (flags.includes(FLAG.GEEK_MODE)) {
      return activityType !== ACTIVITY_TYPES.BREAKING && !isMobilePlatform()
        ? true
        : tab.title !== MORE_OPTION_TAB.ALLOWED_APPS;
    }

    return (
      (isMobilePlatform() &&
        [MORE_OPTION_TAB.TIMING, MORE_OPTION_TAB.YOUTUBE_VIDEOS].includes(
          tab.title
        )) ||
      platform === PLATFORMS.WEB
    );
  });

  const getTitle = (title: string) => {
    const isMetaDataDurationLonger =
      more_options_tabs.some(
        (tab) => tab.title === MORE_OPTION_TAB.YOUTUBE_VIDEOS
      ) &&
      videos_metadata.some((metadata) => {
        const duration =
          metadata.duration?.split(':').length === 2
            ? `00:${metadata.duration}`
            : metadata.duration;
        if (
          activity?.video_urls?.includes(metadata.video_url) &&
          moment.duration(duration).asSeconds() < activity?.duration_seconds
        ) {
          found_duration = moment.duration(duration).asSeconds();
          return true;
        }
        return false;
      });
    return title === MORE_OPTION_TAB.YOUTUBE_VIDEOS &&
      isMetaDataDurationLonger ? (
      <span className='flex gap-2'>
        <span className='min-w-max'> {t(title)}</span>
        <Tooltip
          icon={<Warning />}
          message={t('activity_duration_warning', {
            video_duration: found_duration,
            activity_duration: activity.duration_seconds
          })}
          popupStyles='bg-sunRayFirst text-black'
          place={shouldActiveMobileView ? 'top' : 'left'}
        />
      </span>
    ) : (
      t(title)
    );
  };

  return (
    <ModalContentWrapper>
      <ModalContentHeader />
      {shouldActiveMobileView ? (
        <div className='w-full h-fit flex flex-col gap-4 py-2'>
          {/* MobileViewModalContentBody */}
          {more_options_tabs.map((tab, idx) => (
            <Accordion
              key={`${idx}-${tab.test_id}`}
              title={getTitle(tab.title)}
              headerStyles='text-white ~text-xs/sm'
            >
              {DEFAULT_MORE_OPTIONS_TABS_CONTENTS[tab.title]}
            </Accordion>
          ))}
        </div>
      ) : (
        <DesktopViewModalContentBody
          more_options_tabs={more_options_tabs.map((tab) => ({
            ...tab,
            title: getTitle(tab.title),
            tab_content_index: tab.title
          }))}
        />
      )}
      {mode === Mode.ROUTINE && <Tags />}
    </ModalContentWrapper>
  );
};

const MoreOptions = () => {
  const dispatch = useAppDispatch();
  const {
    videos_metadata,
    activity,
    showYouTubeURLModal,
    showAllowedURLModal,
    showChoicesModal,
    showImageURLModal,
    showCheckListModal,
    showChoicesYouTubeURLModal
  } = useAppSelector(moreOptionModalSelector);

  useEffect(() => {
    const allowedURLsWithMetaData = videos_metadata?.filter(
      (metadata: Video_Metadata) =>
        !activity?.video_urls?.includes(metadata.video_url)
    );
    if (videos_metadata.length === 0 || allowedURLsWithMetaData.length !== 0) {
      dispatch(updateIsYouTubeURLMetadataUpdating(true));
      dispatch(fetchYoutubeURLMetadata(activity?.video_urls ?? []));
    }
  }, []);

  return (
    <>
      <ModalOverlay zIndex='z-[60]'>
        <ModalWrapper>
          <ModalHeader title={activity?.name ?? t('more_options')} />
          <ModalContent />
          <ModalActions />
        </ModalWrapper>
      </ModalOverlay>
      {showYouTubeURLModal && <URLModal modalType={MODAL_TYPES.YOUTUBE_URLS} />}
      {showAllowedURLModal && <URLModal modalType={MODAL_TYPES.ALLOWED_URLS} />}
      {showChoicesYouTubeURLModal && (
        <URLModal modalType={MODAL_TYPES.CHOICE_YOUTUBE} />
      )}
      {showCheckListModal && (
        <CheckListsModal modalType={MODAL_TYPES.CHECK_LIST} />
      )}
      {showChoicesModal && <ChoicesModal />}
      {showImageURLModal && <ImagesModal />}
    </>
  );
};

export default MoreOptions;
