/* eslint-disable */
import Cover from 'assets/images/bear.png';
import classNames from 'classnames';
import { CourseLesson } from 'interfaces';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { createHtml, getYoutubeURLId } from 'utils/helpers';
import { isDarkModeActivated } from 'utils/validators';
import { CourseLessonsModalContext } from '.';
import PlayFilled from 'assets/icons/PlayFilled';

const CourseLessonItem = ({
  lesson,
  index
}: {
  lesson: CourseLesson;
  index: number;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const {
    lessonInfo: { currentLessonIndex }
  } = useContext(CourseLessonsModalContext);
  const divRef = useRef<HTMLDivElement>(null);

  const getTransformValues = useMemo(
    () => `translateX(-${currentLessonIndex * 100}%)`,
    [currentLessonIndex]
  );

  const loadVideo = useCallback(
    (videoId: string, title: string) =>
      `<iframe width='100%' height='100%' src='https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1' allow='autoplay; encrypted-media' allowfullscreen rel='0' fs='0' title=${title}></iframe>`,
    []
  );

  useEffect(() => {
    return () => {
      setIsPlaying(false);
      if (divRef.current) {
        divRef.current.innerHTML = '';
      }
    };
  }, [currentLessonIndex]);

  return (
    <div
      key={lesson.id}
      style={{ transform: getTransformValues }}
      className={classNames(
        'min-w-full w-full max-h-[94%] flex flex-col lg:flex-row transition ease-out duration-300',
        {
          'bg-gray-100': !shouldActivateDarkMode,
          'bg-gray-600': shouldActivateDarkMode
        }
      )}
    >
      {lesson?.url ? (
        <div className='relative w-full lg:w-1/2 min-h-[50vh] lg:min-h-[35vh]'>
          <div
            ref={divRef}
            className='w-full h-full flex flex-col relative bg-cover bg-no-repeat bg-center'
            style={{
              backgroundImage: `url('https://img.youtube.com/vi/${getYoutubeURLId(lesson.url)}/hqdefault.jpg')`
            }}
          ></div>
          {!isPlaying ? (
            <button
              onClick={() => {
                if (divRef.current) {
                  divRef.current.innerHTML = loadVideo(
                    getYoutubeURLId(lesson.url),
                    lesson.title
                  );
                  setIsPlaying(true);
                }
              }}
              className='shadow-lg rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer bg-whiteLace hover:bg-sunRayFourth animate-pulse hover:animate-none'
            >
              <PlayFilled styles='w-12 sm:w-14 md:w-16 h-auto ' />
            </button>
          ) : null}
        </div>
      ) : (
        <img
          src={Cover}
          className='w-full lg:w-1/2 object-cover bg-orange-200 rounded-l-md px-4'
          alt={lesson.title}
        />
      )}
      <div className='w-full lg:w-1/2 h-full flex flex-col items-center justify-center px-4 pt-4 lg:pt-0 gap-0 xs:gap-1 sm:gap-2'>
        <p className='w-full h-fit flex items-center justify-center font-semibold text-sm sm:text-base md:text-xl lg:text-2xl px-0 md:px-2 text-center'>
          {lesson.title}
        </p>
        <div className='w-full h-1/2 lg:h-fit lg:max-h-full overflow-y-auto bg-transparent scrollbar-thin scrollbar-thumb-gray-500 px-4 py-2 text-xs sm:text-sm md:text-base'>
          {createHtml(lesson.content)}
        </div>
      </div>
    </div>
  );
};

export default CourseLessonItem;
