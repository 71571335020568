import { SENTRY } from 'constants/general';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

export const SENTRY_CONFIG = {
  dsn: SENTRY.DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ],
  tracesSampleRate: SENTRY.TRACE_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY.REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY.REPLAYS_ON_ERROR_SAMPLE_RATE
};
