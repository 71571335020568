import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import { fetchYoutubeURLMetadata } from './extra';
import { initialCacheState } from 'store/initial-states';
import { CACHE_CATEGORY, addYouTubeURLMetaDataIntoCache } from 'utils/cache';
import {
  Video_Metadata,
  YouTubeURLMetadataType
} from 'interfaces/setting.interface';

const cache = createSlice({
  name: 'cache',
  initialState: initialCacheState,
  reducers: {
    updateIsYouTubeURLMetadataFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isYouTubeURLMetadataFetching = payload;
    },
    updateYouTubeURLMetadata: (
      state,
      { payload }: PayloadAction<YouTubeURLMetadataType>
    ) => {
      state.youtube_urls_metadata = payload;
    },
    updateIsYouTubeURLMetadataUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isYouTubeURLMetadataUpdating = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchYoutubeURLMetadata.fulfilled, (state, { payload }) => {
        if (payload) {
          const {
            youtube_urls_metadata: { videos_metadata }
          } = current(state);
          const currentVideosMetadata = _.cloneDeep(videos_metadata);
          payload.videos_metadata.forEach((metadata: Video_Metadata) => {
            const index = currentVideosMetadata.findIndex(
              (meta) => meta.video_url === metadata.video_url
            );
            if (index === -1) {
              currentVideosMetadata.push(metadata);
            } else {
              currentVideosMetadata[index].title = metadata.title;
              currentVideosMetadata[index].duration = metadata.duration;
            }
          });
          state.youtube_urls_metadata = {
            ...payload,
            videos_metadata: currentVideosMetadata
          };
          addYouTubeURLMetaDataIntoCache(
            CACHE_CATEGORY.YOUTUBE_URL,
            new Response(JSON.stringify(payload))
          );
        }
        state.isYouTubeURLMetadataFetching = false;
        state.isYouTubeURLMetadataUpdating = false;
      })
      .addCase(fetchYoutubeURLMetadata.rejected, (state) => {
        state.isYouTubeURLMetadataFetching = false;
        state.isYouTubeURLMetadataUpdating = false;
      })
});

export const {
  updateYouTubeURLMetadata,
  updateIsYouTubeURLMetadataFetching,
  updateIsYouTubeURLMetadataUpdating
} = cache.actions;
export default cache.reducer;
