import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validators';

const Spinner = ({
  title,
  loaderStyles = 'w-6 xl:w-10 h-6 xl:h-10',
  minHeight = 'h-[50vh]',
  borderColor = 'border-white'
}: {
  title?: string;
  loaderStyles?: string;
  minHeight?: string;
  borderColor?: string;
}) => {
  const { t } = useTranslation();
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  return (
    <div
      className={`w-full ${
        minHeight ?? 'h-[65vh]'
      } flex items-center justify-center gap-2`}
    >
      <div
        className={`${loaderStyles} rounded-full animate-spin border-r-2 ${
          shouldActivateDarkMode ? borderColor : 'border-black'
        }`}
      ></div>
      <div className='text-lg text-orange-400 font-semibold'>
        {t(title ?? '')}
      </div>
    </div>
  );
};

export default Spinner;
