import { IconProps } from 'interfaces';
import COLOR from 'constants/color';
import { isDarkModeActivated } from 'utils/validators';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';

const Edit = ({
  fill = COLOR.BLACK,
  fillDarkMode,
  styles = 'w-5 h-auto'
}: IconProps) => (
  <svg
    className={styles}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
    <g
      id='SVGRepo_tracerCarrier'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></g>
    <g id='SVGRepo_iconCarrier'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z'
        fill={
          isDarkModeActivated(useAppSelector(themeSelector))
            ? fillDarkMode || fill
            : fill
        }
      ></path>
    </g>
  </svg>
);

export default Edit;
