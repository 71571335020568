import { isProductionEnvironment } from './helpers';

export function registerServiceWorks() {
  if (
    window.location.origin !== 'null' &&
    'serviceWorker' in navigator &&
    isProductionEnvironment
  ) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(
        (registration) => {
          console.log(
            'Caching serviceWorker registration successful with scope: ',
            registration.scope
          );
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
}
