import {
  Page,
  Document,
  StyleSheet,
  Text,
  View,
  Image
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import PageContent from './PageContent';
import logo from 'assets/images/logo.png';
import { HabitPackType } from 'interfaces/common.interface';
import { PACK_OPTIONS } from 'constants/general';
import COLOR from 'constants/color';

interface HabitPackPdfDocumentProps {
  habitPack: HabitPackType | null;
}

const HabitPackPdfDocument = ({ habitPack }: HabitPackPdfDocumentProps) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size='A4' style={styles.pageWrapper}>
        <View style={styles.header} fixed>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.title}>{habitPack?.pack_name}</Text>
        </View>
        <View style={styles.infoWrapper}>
          <View style={styles.info}>
            <View style={styles.infoLeftItemWrapper}>
              <Text style={styles.infoLeftItemTitle}>{`${t(
                'duration'
              )}:`}</Text>
              <Text style={styles.infoLeftItemValue}>
                {`${habitPack?.duration} ${t('seconds')}`}
              </Text>
            </View>
            <View style={styles.infoRightItemWrapper}>
              <Text style={styles.infoRightItemTitle}>{`${t(
                'pdf.author'
              )}:`}</Text>
              <Text style={styles.infoRightItemValue}>
                {habitPack?.creator_name}
              </Text>
            </View>
          </View>
          <View style={styles.info}>
            <View style={styles.infoRoutineWrapper}>
              <Text style={styles.infoRoutineWrapperLeft}>{`${t(
                'pdf.morning_routine_duration'
              )}:`}</Text>
              <Text style={styles.infoRoutineWrapperRight}>
                {`${habitPack?.morning_routine_duration_seconds} ${t(
                  'minutes'
                )}`}
              </Text>
            </View>
            <View style={styles.infoRoutineWrapper}>
              <Text style={styles.infoRoutineWrapperLeft}>{`${t(
                'pdf.evening_routine_duration'
              )}:`}</Text>
              <Text style={styles.infoRoutineWrapperRight}>
                {`${habitPack?.evening_routine_duration_seconds} ${t(
                  'minutes'
                )}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.detailsWrapper}>
          <Text style={styles.detailsTitle}>{t('pdf.welcome_message')}</Text>
          <Text>{habitPack?.welcome_message_plain_text ?? ''}</Text>
          <Text style={styles.video_link}>
            {habitPack?.welcome_video_url ?? ''}
          </Text>
        </View>
        <View style={styles.detailsWrapper}>
          <Text style={styles.detailsTitle}>{t('Description')}</Text>
          <Text>{habitPack?.description_plain_text ?? ''}</Text>
          <Text style={styles.video_link}>
            {habitPack?.description_video_url ?? ''}
          </Text>
        </View>
        {habitPack?.pack_type === PACK_OPTIONS.STANDALONE ? (
          <View style={styles.contentWrapper} break>
            <Text style={styles.activityHeader}>
              {t('market.standalone_activities')}
            </Text>
            <PageContent activities={habitPack?.standalone_activities ?? []} />
          </View>
        ) : (
          <>
            <View style={styles.contentWrapper} break>
              <Text style={styles.activityHeader}>{t('morning_habits')}</Text>
              <PageContent activities={habitPack?.morning_activities ?? []} />
            </View>
            <View style={styles.contentWrapper} break>
              <Text style={styles.activityHeader}>{t('break_activities')}</Text>
              <PageContent activities={habitPack?.break_activities ?? []} />
            </View>
            <View style={styles.contentWrapper} break>
              <Text style={styles.activityHeader}>{t('evening_habits')}</Text>
              <PageContent activities={habitPack?.evening_activities ?? []} />
            </View>
          </>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 40px'
  },
  contentWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '16px 4px'
  },
  activityHeader: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: '8px',
    FontStyle: 'bold'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  header: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 0'
  },
  logo: {
    width: '200px',
    height: '33px',
    marginBottom: '14px'
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    textAlign: 'center'
  },
  infoWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '15px'
  },
  info: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  infoLeftItemWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
    fontSize: '14px'
  },
  infoLeftItemTitle: {
    width: '25%',
    fontWeight: 600
  },
  infoLeftItemValue: {
    width: '75%',
    fontWeight: 600,
    color: COLOR.BLUE_600
  },
  infoRightItemWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    fontSize: '14px'
  },
  infoRightItemTitle: {
    width: '20%',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  infoRightItemValue: {
    width: '80%',
    fontWeight: 600,
    color: COLOR.BLUE_600
  },
  infoLeftItemRoutineTitle: {
    width: '70%',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  infoLeftItemRoutineValue: {
    width: '30%',
    fontWeight: 600,
    color: COLOR.BLUE_600
  },
  infoRoutineWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row'
  },
  infoRoutineWrapperLeft: {
    fontSize: '14px',
    paddingRight: '5px'
  },
  infoRoutineWrapperRight: {
    fontSize: '14px',
    textAlign: 'right',
    color: COLOR.BLUE_600
  },
  detailsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    padding: '10px 0'
  },
  detailsTitle: {
    fontWeight: 'extrabold',
    paddingBottom: '10px',
    textDecoration: 'underline'
  },
  video_link: {
    padding: '10px 0',
    fontSize: '13px',
    color: COLOR.BLUE_600
  }
});

export default HabitPackPdfDocument;
