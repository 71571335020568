import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const injectRoutineSuggestionMiddleware: Middleware =
  (store) => (next) => (action) => {
    if (action.type === 'setting/configureTabs') {
      const state = store.getState() as RootState;

      action = {
        ...action,
        payload: {
          ...action.payload,
          routineSuggestionPayload:
            state.routineSuggestions.routineSuggestionPayload,
          routineSuggestions: state.routineSuggestions.routineSuggestions
        }
      };
    }

    return next(action);
  };
