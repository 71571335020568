import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentMetaData from 'components/DocumentMetaData';
import { PAGES, ROUTES } from 'constants/routes';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className='bg-main w-full h-screen flex flex-col gap-10 justify-center items-center bg-whiteLace p-4'>
      <DocumentMetaData pageName={PAGES.NOT_FOUND} />
      <h1 className='~text-6xl/8xl font-bold text-sunRayFirst my-6'>404</h1>
      <h2 className='!text-2xl/4xl text-sunRayFirst leading-tight font-bold tracking-tighter'>
        {t('page_not_found')}
      </h2>
      <p className='text-lg md:text-xl w-11/12 sm:w-9/12 md:w-5/12 text-center'>
        {t('could_not_find_page')}
      </p>
      <ButtonFocusBear
        onClick={() => navigate(ROUTES.HOME, { replace: true })}
        title={t('back_to_homepage')}
        size={BTN_FB_SIZE.LARGE}
      />
    </section>
  );
};

export default NotFoundPage;
