import { Mode } from 'constants/enum';
import { HABIT_QUESTIONS, TAB } from 'constants/general';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { tabBodyHeaderSelector } from 'store/reducer/setting/selectors';
import EveningRoutineStartTime from './EveningRoutineStartTime';
import TabBodyHeaderWidgets from './TabBodyHeaderWidgets';

const TabBodyHeaderWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit flex items-center relative'>{children}</div>
);

interface TabBodyHeaderProps {
  title: string;
}

const TabBodyHeader = ({ title }: TabBodyHeaderProps) => {
  const { t } = useTranslation();
  const { tabs, activeTabIndex, mode } = useAppSelector(tabBodyHeaderSelector);
  const shouldDisplayFilterWidget = [
    TAB.MORNING,
    TAB.MICRO_BREAKS,
    TAB.EVENING,
    TAB.ACTIVITY
  ].includes(tabs[activeTabIndex]);

  return (
    <TabBodyHeaderWrapper>
      {mode !== Mode.CUSTOM && (
        <p className='w-9/12 h-fit ~text-md/xl'>
          {t(title)}
          {title === HABIT_QUESTIONS.EVENING_ROUTINE && (
            <EveningRoutineStartTime />
          )}
        </p>
      )}
      {shouldDisplayFilterWidget && <TabBodyHeaderWidgets />}
    </TabBodyHeaderWrapper>
  );
};

export default TabBodyHeader;
