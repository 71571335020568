import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { updatePackMarketPlaceRequestStatus } from 'store/reducer/habit-pack/extra';
import { copyPackLink } from 'utils/helpers';
import { HabitPackType } from 'interfaces/common.interface';
import { MarketplaceRequestStatus } from 'constants/enum';
import { toast } from 'react-toastify';
import { MODAL_TYPES, TOAST_AUTO_CLOSE_TIMEOUT } from 'constants/general';
import { updateConfirmActionModal } from 'store/reducer/modal/slice';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-full flex flex-col items-center gap-y-6'>{children}</div>
);

export default function PackMarketplaceControls({
  habitPack
}: {
  readonly habitPack: HabitPackType;
}) {
  const [infoText, setInfoText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const approvalNotRequested =
    habitPack.marketplace_request === MarketplaceRequestStatus.UNREQUESTED ||
    habitPack.marketplace_request === null;

  useEffect(() => {
    const initializeButtonText = () => {
      if (habitPack.marketplace_approval_status) {
        setInfoText(t('packs.remove_text'));
        setButtonText(t('packs.btn_remove'));
      } else if (
        habitPack.marketplace_request === MarketplaceRequestStatus.REQUESTED
      ) {
        setInfoText(t('packs.post_request_text'));
        setButtonText(t('packs.btn_cancel_request'));
      } else if (
        approvalNotRequested &&
        !habitPack.marketplace_approval_status
      ) {
        setInfoText(t('packs.pre_request_text'));
        setButtonText(t('packs.btn_publish'));
      } else {
        return;
      }
    };
    initializeButtonText();
  }, [habitPack, t]);

  const handleMarketplaceRequestAction = () => {
    if (habitPack.marketplace_approval_status) {
      dispatch(
        updateConfirmActionModal({
          modalType:
            MODAL_TYPES.CONFIRM_ACTION_REMOVE_HABIT_PACK_FROM_MARKET_PLACE,
          confirmTitle: t('packs.btn_remove'),
          showConfirmModal: true
        })
      );
    } else {
      dispatch(
        updatePackMarketPlaceRequestStatus({
          habitPack,
          status:
            habitPack.marketplace_request === MarketplaceRequestStatus.REQUESTED
              ? MarketplaceRequestStatus.UNREQUESTED
              : MarketplaceRequestStatus.REQUESTED
        })
      );
    }
  };

  return (
    <Wrapper>
      <p className='text-sm md:text-md w-[90%] md:w-[70%]'>{infoText}</p>
      <button
        onClick={handleMarketplaceRequestAction}
        className='w-3/4 sm:w-1/2 md:w-[50%] px-2 py-1 md:px-2 md:py-1 text-xs sm:text-base rounded-xl border-2 border-blue-500 text-blue-500  font-bold hover:text-white hover:bg-blue-500'
      >
        {buttonText}
      </button>
      <p className='w-[70%] text-sm md:text-md'>
        {t('packs.share_using_link')}
      </p>
      <button
        onClick={() => {
          copyPackLink(habitPack.id);
          toast.info(t('packs.copied'), {
            position: 'bottom-center',
            autoClose: TOAST_AUTO_CLOSE_TIMEOUT,
            theme: 'colored'
          });
        }}
        className='text-orange-500 underline'
      >
        {t('packs.copy_link')} 🔗
      </button>
    </Wrapper>
  );
}
