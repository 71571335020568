export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const MARKETPLACE_URL = 'https://dashboard.focusbear.io/marketplace';
export const SUBSCRIPTION_INFO_KEY =
  'https://eyst-backend-prod-8th3.onrender.com/user';

export const FIRST_LOGIN_KEY = 'https://dashboard.focusbear.io/first_login';

export const TOKEN_ROLES_KEY = 'https://api.focusbear.io/roles';

export const DOWNLOAD_LINK = {
  MAC_APP: 'https://downloads.focusbear.io/FocusBearSetupMac.pkg',
  WINDOWS_APP: 'https://downloads.focusbear.io/FocusBearSetupWindows.exe',
  MOBILE: {
    ANDROID: 'https://play.google.com/store/apps/details?id=com.focusbear',
    IOS: 'https://testflight.apple.com/join/FAJLKBqa'
  }
};

export const SCHEMA_LINK =
  'https://focus-bear.github.io/assets/config/settings-schema.json';

export const ROUTES = {
  LOGIN: '/login',
  HOME: '/',
  SUBSCRIPTION: '/subscription',
  PROFILE: '/profile',
  TEAM: '/team/:id',
  STATS: '/stats',
  SETTINGS: '/settings',
  MARKETPLACE: '/marketplace',
  MY_PACKS_AND_FOCUS_TEMPLATES: '/my-packs-and-focus-templates',
  SHARE_PACK: '/share',
  SUCCESS: '/success',
  ADMIN: '/admin',
  ERROR: '/error',
  NOT_FOUND: '*',
  HABIT_PACK_DETAILS: '/habit-packs/:id',
  HABIT_PACK_SHARE: '/habit-packs/share/:id',
  HABIT_PACK_DASHBOARD: '/habit-packs/welcome-page',
  FOCUS_TEMPLATE_DASHBOARD: '/focus-mode-templates/welcome-page',
  FOCUS_TEMPLATE_DETAILS: '/focus-mode-templates/:id',
  FOCUS_TEMPLATE_SHARE: '/focus-mode-templates/share/:id',
  ROUTINE_PLAYER: 'routine-player',
  CHAT: '/chat',
  BACK_ONE_STEP: -1,
  MOTIVATIONAL_SUMMARY: '/motivational-summary',
  WEBVIEW_STATS: '/webview/stats',
  WEBVIEW_CHAT: '/webview/chat',
  WEBVIEW_TODO_LIST: '/webview/todo-list',
  WEBVIEW_MOTIVATIONAL_SUMMARY: '/webview/motivational-summary',
  WEBVIEW_MOBILE_MOTIVATIONAL_SUMMARY: '/webview-mobile/motivational-summary',
  WEBVIEW_TOOLS_TODO_LIST: '/webview/tools-todo-list',
  TODO: '/todo',
  WEBVIEW_SURVEY: '/webview/survey',
  WEBVIEW_FOCUS_END: '/webview/focus-end',
  ACCEPT_INVITE: 'accept-invite',
  PLATFORM_CALLBACK: '/callback/:platform',
  ZOHO_CALLBACK: '/callback/zoho',
  MONDAY_CALLBACK: '/callback/monday',
  CLICK_UP_CALLBACK: '/callback/clickup',
  ASANA_CALLBACK: '/callback/asana',
  JIRA_CALLBACK: '/callback/jira',
  TRELLO_CALLBACK: '/callback/trello',
  GOOGLE_CALLBACK: '/callback/google',
  MICROSOFT_CALLBACK: '/callback/microsoft',
  LOG_OUT: '/log-out', // @Description: it is dummy route & used only for checking the navbar dropdown children
  MANAGE_SUBSCRIPTION: '/manage-subscription',
  WEBVIEW_COURSE: '/webview/course',
  WEBVIEW_TO_DO_PLAYER: '/webview/todo-player',
  WEBVIEW_MANAGE_SUBSCRIPTION: '/webview/manage-subscription',
  WEBVIEW_ROUTINE_SUGGESTION: '/webview/routine-suggestion',
  WEBVIEW_ENROLLED_COURSES: '/webview/enrolled-courses',
  WEBVIEW_GET_SUPPORT: '/webview/get-support',
  FORBIDDEN: '/forbidden'
};

export const VALID_REDIRECT_PATHS = [
  ROUTES.SUCCESS,
  ROUTES.SUBSCRIPTION,
  ROUTES.SETTINGS,
  ROUTES.MARKETPLACE,
  ROUTES.SHARE_PACK,
  ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES,
  ROUTES.ADMIN,
  ROUTES.STATS,
  ROUTES.PROFILE,
  ROUTES.TODO,
  ROUTES.ROUTINE_PLAYER,
  ROUTES.MANAGE_SUBSCRIPTION,
  ROUTES.HOME,
  ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION
];

export const PAGES = {
  DASHBOARD: 'Dashboard',
  SUBSCRIPTION: 'Subscription',
  TEAM: 'Team',
  STATS: 'Statistics',
  SETTINGS: 'Settings',
  MARKETPLACE: 'Marketplace',
  MY_PACKS_AND_FOCUS_TEMPLATES: 'My Packs & Focus Templates',
  SHARE: 'Share',
  ADMIN: 'Administration',
  NOT_FOUND: '404',
  ERROR: 'Error',
  WELCOME: 'Welcome',
  HABIT_PACK: 'Habit Pack',
  FOCUS_TEMPLATE: 'Focus Template',
  PROFILE: 'Profile',
  TO_DO_LIST: 'To Do List',
  FORBIDDEN: '403'
};

export const PRIVACY_POLICY = {
  EN: '/en/privacy-policy',
  ES: '/es/politica-de-privacidad'
};

export const EMBEDDED_PAGE = 'https://settings.focusbear.io';
export const LATE_NO_MORE = 'https://focusbear.io/en/late-no-more';
export const FOCUS_BEAR_LANDING_PAGE = 'https://www.focusbear.io';
export const FOCUS_BEAR_DASHBOARD = 'https://dashboard.focusbear.io';
export const POST_HOG_API_HOST = 'https://us.i.posthog.com';
