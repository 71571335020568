import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { LANGUAGE } from 'constants/general';
import { LanguageCodes } from 'constants/enum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeCurrentSettings,
  updateLanguage,
} from 'store/reducer/setting/slice';

export default function LanguageDropDown() {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const {
    setting: { language, currentSettings },
    habitPack: { currentViewedPack },
    focusMode: { currentViewedFocusTemplate },
  } = useAppSelector((state) => state);

  const handleSelect = (code: string) => {
    if (code === LANGUAGE.GB) {
      code = LanguageCodes.EN;
    } else if (code === LANGUAGE.ES) {
      code = LanguageCodes.ES;
    }
    i18n.changeLanguage(code);
    dispatch(updateLanguage(code));

    currentViewedPack &&
      dispatch(changeCurrentSettings({ ...currentSettings, language: code }));

    currentViewedFocusTemplate &&
      dispatch(
        changeCurrentSettings({ ...currentViewedFocusTemplate, language: code })
      );
  };

  return (
    <ReactFlagsSelect
      countries={[LANGUAGE.GB, LANGUAGE.ES]}
      onSelect={handleSelect}
      selected={
        language === LanguageCodes.EN ? LANGUAGE.GB : language.toUpperCase()
      }
      customLabels={{ GB: 'EN', ES: 'ES' }}
    />
  );
}
