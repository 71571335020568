import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

export const ChevronRight = ({
  fill = COLOR.BLACK,
  styles = 'w-2 h-auto'
}: IconProps) => {
  return (
    <svg
      className={styles}
      viewBox='-5 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      fill={fill}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <defs> </defs>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Icon-Set'
            transform='translate(-473.000000, -1195.000000)'
            fill={fill}
          >
            <path
              d='M486.717,1206.22 L474.71,1195.28 C474.316,1194.89 473.678,1194.89 473.283,1195.28 C472.89,1195.67 472.89,1196.31 473.283,1196.7 L484.566,1206.98 L473.283,1217.27 C472.89,1217.66 472.89,1218.29 473.283,1218.69 C473.678,1219.08 474.316,1219.08 474.71,1218.69 L486.717,1207.75 C486.927,1207.54 487.017,1207.26 487.003,1206.98 C487.017,1206.71 486.927,1206.43 486.717,1206.22'
              id='chevron-right'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
