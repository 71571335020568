import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routineGoalsSelector } from 'store/reducer/routine-suggestions/selectors';
import { WizardSectionWrapper } from '..';
import ListOfSelectedGoals from './ListOfSelectedGoals';
import { updateSelectedGoals } from 'store/reducer/routine-suggestions/slice';

const RoutineGoals: FC = () => {
  const dispatch = useAppDispatch();
  const { user_goals, selectedGoals } = useAppSelector(routineGoalsSelector);

  useEffect(() => {
    dispatch(updateSelectedGoals(selectedGoals.concat(user_goals)));
  }, []);

  return (
    <WizardSectionWrapper>
      <ListOfSelectedGoals />
    </WizardSectionWrapper>
  );
};

export default RoutineGoals;
