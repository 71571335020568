import ActivityDetails from 'dashboard/marketplace/ActivityDetails';
import PageSubHeading from 'dashboard/marketplace/PageSubHeading';
import { ActivityType } from 'interfaces/common.interface';

const HabitPackActivityList = ({
  title,
  habitActivities
}: {
  title: string;
  habitActivities: ActivityType[];
}) => {
  return (
    <div className='w-full h-fit flex flex-col items-center gap-2 md:gap-4 py-2 px-4'>
      <PageSubHeading text={title} />
      {habitActivities.map((activity: ActivityType) => (
        <ActivityDetails
          key={activity.id}
          id={activity.id}
          name={activity.name}
          duration_seconds={activity.duration_seconds}
          video_urls={activity.video_urls}
          choice_type={activity.choice_type}
          allowed_focus_mode_id={activity.allowed_focus_mode_id}
          allowed_apps={activity.allowed_apps}
        />
      ))}
    </div>
  );
};
export default HabitPackActivityList;
