import { t } from 'i18next';
import { ActivityType } from 'interfaces/common.interface';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRoutinesDuration } from 'utils/routine-suggestion.util';
import RoutineSuggestion from './RoutineSuggestion';
import { routineActivitiesSelector } from 'store/reducer/routine-suggestions/selectors';
import { updateRoutineSuggestions } from 'store/reducer/routine-suggestions/slice';

const RoutineSuggestions = ({ activities }: { activities: ActivityType[] }) => {
  const dispatch = useAppDispatch();
  const { activeTab, routines, routineSuggestions } = useAppSelector(
    routineActivitiesSelector
  );

  const routine_duration = useMemo(
    () => getRoutinesDuration(activeTab, activities, routines),
    [activeTab, routineSuggestions, routines]
  );

  const handleDuration = (value: string, activity_id: string) => {
    dispatch(
      updateRoutineSuggestions(
        (routineSuggestions ?? [])?.map((activity) =>
          activity.id === activity_id
            ? { ...activity, duration_seconds: parseInt(value) }
            : activity
        )
      )
    );
  };

  return (
    <div className='w-full h-fit flex flex-col gap-6'>
      {activities?.map((suggestion) => (
        <RoutineSuggestion
          key={suggestion.id}
          suggestion={suggestion}
          handleDuration={handleDuration}
        />
      ))}
      <p className='text-xs self-end capitalize'>
        {t('routine_suggestion.total_routine_duration', {
          routine_duration
        })}
      </p>
    </div>
  );
};
export default RoutineSuggestions;
