import { useState, useEffect } from 'react';
import {
  google,
  outlook,
  office365,
  yahoo,
  ics,
  CalendarEvent
} from 'calendar-link';
import { useAppSelector } from 'store/hooks';
import {
  calculateSequenceDuration,
  determineRoutineNextStartTime
} from 'utils/helpers';
import Select, { SingleValue } from 'react-select';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import {
  CALENDAR_OPTIONS,
  CALENDAR_RECURRENCE_RULE_FOR_DAILY_RECURRENCE,
  UTC_TIMEZONE
} from 'constants/general';
import { ActivityTableFilter, CalendarTypes } from 'constants/enum';

export default function RoutineCalendarReminders() {
  const { t } = useTranslation();
  const {
    setting: {
      currentSettings: {
        morning_activities,
        evening_activities,
        startup_time,
        shutdown_time
      }
    },
    user: {
      details: { timezone }
    }
  } = useAppSelector((state) => state);
  const timeZoneToUse = timezone ?? UTC_TIMEZONE;
  const [calendarToUse, setCalendarToUse] = useState<string | undefined>(
    'google'
  );
  const [morningRoutineEventLink, setMorningRoutineEventLink] = useState('');
  const [eveningRoutineEventLink, setEveningRoutineEventLink] = useState('');
  const morningSequenceDuration = calculateSequenceDuration(
    morning_activities ?? []
  );
  const eveningSequenceDuration = calculateSequenceDuration(
    evening_activities ?? []
  );
  const morningRoutineStart = determineRoutineNextStartTime(
    timeZoneToUse,
    startup_time
  );
  const eveningRoutineStart = determineRoutineNextStartTime(
    timeZoneToUse,
    shutdown_time
  );

  const morningEvent: CalendarEvent = {
    title: t('inquiry.do_morning_routine'),
    start: morningRoutineStart,
    duration: [morningSequenceDuration, 'seconds'],
    rRule: CALENDAR_RECURRENCE_RULE_FOR_DAILY_RECURRENCE
  };

  const eveningEvent: CalendarEvent = {
    title: t('inquiry.do_evening_routine'),
    start: eveningRoutineStart,
    duration: [eveningSequenceDuration, 'seconds'],
    rRule: CALENDAR_RECURRENCE_RULE_FOR_DAILY_RECURRENCE
  };

  useEffect(() => {
    switch (calendarToUse) {
      case CalendarTypes.OUTLOOK:
        setMorningRoutineEventLink(outlook(morningEvent));
        setEveningRoutineEventLink(outlook(eveningEvent));
        break;
      case CalendarTypes.YAHOO:
        setMorningRoutineEventLink(yahoo(morningEvent));
        setEveningRoutineEventLink(yahoo(eveningEvent));
        break;
      case CalendarTypes.OFFICE_365:
        setMorningRoutineEventLink(office365(morningEvent));
        setEveningRoutineEventLink(office365(eveningEvent));
        break;
      case CalendarTypes.ICS:
        setMorningRoutineEventLink(ics(morningEvent));
        setEveningRoutineEventLink(ics(eveningEvent));
        break;
      default:
        setMorningRoutineEventLink(google(morningEvent));
        setEveningRoutineEventLink(google(eveningEvent));
    }
  }, [calendarToUse]);

  const handleSaveIcsFile = (file: string, routine: string) => {
    const blob = new Blob([file], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${routine}-routine-reminder.ics`);
  };

  return (
    <>
      <Select
        className='w-full text-sm font-semibold'
        defaultValue={CALENDAR_OPTIONS[0]}
        options={CALENDAR_OPTIONS}
        onChange={(data: SingleValue<{ value: string; label: string }>) =>
          setCalendarToUse(data?.value)
        }
      />
      {calendarToUse !== CalendarTypes.ICS && (
        <div className='flex md:grid grid-cols-2 flex-col justify-start gap-2'>
          <a href={morningRoutineEventLink} target='_blank' rel='noreferrer'>
            <button className='md:w-fit w-full h-fit p-4 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded text-xs lg:text-sm'>
              {t('inquiry.set_morning_routine_reminder')}
            </button>
          </a>
          <a href={eveningRoutineEventLink} target='_blank' rel='noreferrer'>
            <button className='md:w-fit w-full xs:w-full h-fit p-4 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded text-xs lg:text-sm'>
              {t('inquiry.set_evening_routine_reminder')}
            </button>
          </a>
        </div>
      )}
      {calendarToUse === CalendarTypes.ICS && (
        <div className='flex md:grid grid-cols-2 flex-col justify-start gap-2'>
          <button
            onClick={() =>
              handleSaveIcsFile(
                morningRoutineEventLink,
                ActivityTableFilter.MORNING
              )
            }
            className='md:w-fit w-full h-fit p-4 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded text-xs lg:text-sm'
          >
            {t('inquiry.set_morning_routine_reminder')}
          </button>
          <button
            onClick={() =>
              handleSaveIcsFile(
                eveningRoutineEventLink,
                ActivityTableFilter.EVENING
              )
            }
            className='md:w-fit w-full h-fit p-4 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded text-xs lg:text-sm'
          >
            {t('inquiry.set_evening_routine_reminder')}
          </button>
        </div>
      )}
    </>
  );
}
