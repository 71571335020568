import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeActivityOrder,
  insertNewHabit
} from 'store/reducer/setting/slice';
import { DraggableContentProps } from 'interfaces';
import TabBodyRow from '../tabs/tabBodyRow';
import PlusCircle from 'assets/icons/PlusCircle';
import COLOR from 'constants/color';
import {
  ACTIVITY_TYPES,
  MOVE_OPTION,
  NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY,
  NUMBER_OF_DELETE_ITEMS,
  ROUTINE_FIRST_ACTIVITY_INDEX,
  TAB,
  TAB_INDEX
} from 'constants/general';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Draggable from 'assets/icons/Draggable';
import Tooltip from 'components/common/Tooltip';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import MoveTop from 'assets/icons/MoveTop';
import { decrement, increment } from 'utils/helpers';
import CloseCircle from 'assets/icons/CloseCircle';
import { isDarkModeActivated } from 'utils/validators';

interface DraggableHeaderProps {
  type: string;
  sequence_id: string;
  position: number;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const MoveUpAndDownButtons = ({
  type,
  position
}: {
  type: string;
  position: number;
}) => {
  const dispatch = useAppDispatch();
  const {
    morning_activities,
    evening_activities,
    break_activities,
    standalone_activities
  } = useAppSelector((state) => state.setting.currentSettings);

  const routineInfo = () => {
    if (type === ACTIVITY_TYPES.MORNING) {
      return {
        activities: Array.from(morning_activities ?? []),
        routine: ACTIVITY_TYPES.MORNING
      };
    } else if (type === ACTIVITY_TYPES.BREAKING) {
      return {
        activities: Array.from(break_activities ?? []),
        routine: ACTIVITY_TYPES.BREAKING
      };
    } else if (type === ACTIVITY_TYPES.EVENING) {
      return {
        activities: Array.from(evening_activities ?? []),
        routine: ACTIVITY_TYPES.EVENING
      };
    } else {
      return {
        activities: Array.from(standalone_activities ?? []),
        routine: ACTIVITY_TYPES.STANDALONE
      };
    }
  };

  const { activities, routine } = routineInfo();

  const handleMoveToBoundaries = (move?: string) => {
    const [currentActivity] = activities.splice(
      position,
      NUMBER_OF_DELETE_ITEMS
    );
    dispatch(
      changeActivityOrder({
        type: routine,
        data:
          (move ?? MOVE_OPTION.TOP) === MOVE_OPTION.TOP
            ? [currentActivity, ...activities]
            : [...activities, currentActivity]
      })
    );
  };

  return (
    <div className='flex gap-2'>
      {position !== ROUTINE_FIRST_ACTIVITY_INDEX ? (
        <button onClick={() => handleMoveToBoundaries()}>
          <MoveTop />
        </button>
      ) : null}
      {position !== decrement(activities?.length) ? (
        <button onClick={() => handleMoveToBoundaries(MOVE_OPTION.BOTTOM)}>
          <MoveTop styles='w-auto h-4 md:h-5 rotate-180' />
        </button>
      ) : null}
    </div>
  );
};

const ContextMenus = ({
  setShowContextMenu,
  type,
  position
}: {
  setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  type: string;
  position: number;
}) => {
  return (
    <div className='w-fit max-w-[70vw] h-fit flex gap-2 px-2.5 py-1 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 bg-gray-200 rounded-md shadow-md absolute -top-1 left-0 z-20'>
      <MoveUpAndDownButtons type={type} position={position} />
      <button onClick={() => setShowContextMenu(false)}>
        <CloseCircle fill={COLOR.BLACK} />
      </button>
    </div>
  );
};

const DraggableHeader = ({
  type,
  sequence_id,
  position,
  dragHandleProps
}: DraggableHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    activeTabIndex,
    currentSettings: {
      morning_activities,
      break_activities,
      evening_activities,
      standalone_activities
    },
    tabs
  } = useAppSelector((state) => state.setting);
  const [showContextMenu, setShowContextMenu] = useState(false);

  /**
   *
   * @return an array of activities of each tab for given setting mode
   */
  const shouldShowAddNewActivityButtonNextToEachActivity = useMemo(() => {
    switch (tabs[activeTabIndex]) {
      case TAB.MORNING:
        return (
          (morning_activities ?? []).length >
          NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY
        );
      case TAB.MICRO_BREAKS:
        return (
          (break_activities ?? []).length >
          NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY
        );
      case TAB.EVENING:
        return (
          (evening_activities ?? []).length >
          NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY
        );
      case TAB.ACTIVITY:
        return (
          (standalone_activities ?? []).length >
          NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY
        );
      default:
        return false;
    }
  }, [
    morning_activities?.length,
    evening_activities?.length,
    break_activities?.length,
    standalone_activities?.length
  ]);

  return (
    <div
      className={`w-fit h-fit flex flex-col items-center gap-2.5 mr-1.5 self-start ${
        shouldShowAddNewActivityButtonNextToEachActivity
          ? 'justify-between'
          : 'justify-center'
      } items-center relative`}
    >
      {showContextMenu && (
        <ContextMenus
          setShowContextMenu={setShowContextMenu}
          type={type}
          position={position}
        />
      )}
      <button
        className='hover:cursor-move'
        {...dragHandleProps}
        tabIndex={TAB_INDEX.DRAGGABLE_HANDLE}
        onClick={() => setShowContextMenu(false)}
        onContextMenu={(event) => {
          event.preventDefault();
          setShowContextMenu(true);
        }}
      >
        <Draggable />
      </button>
      {shouldShowAddNewActivityButtonNextToEachActivity && (
        <button
          className='w-fit h-fit rounded-full bg-white'
          onClick={() => {
            dispatch(
              insertNewHabit({
                type,
                sequence_id,
                position: increment(position)
              })
            );
          }}
        >
          <Tooltip
            message={t('inset_new_habit_tooltip')}
            icon={
              <PlusCircle
                styles='w-4 h-4'
                fill={COLOR.BLACK}
                fillDarkMode={COLOR.GRAY_700}
              />
            }
          />
        </button>
      )}
    </div>
  );
};

const DraggableContent = ({
  position,
  type,
  sequence_id,
  provided,
  customRoutinePosition
}: DraggableContentProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  const isDarkMode = useMemo(() => isDarkModeActivated(themeMode), [themeMode]);

  return (
    <div
      className={`w-full h-fit flex items-end p-5 pb-10 cursor-pointer outline-none border-gray-400 tracking-normal gap-2 select-none ${
        isDarkMode ? 'bg-gray-700' : 'bg-white'
      } border-t-2 rounded-t-xl relative`}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <DraggableHeader
        type={type}
        sequence_id={sequence_id}
        position={position}
        dragHandleProps={provided.dragHandleProps}
      />
      <TabBodyRow
        type={type}
        position={position}
        customRoutinePosition={customRoutinePosition}
      />
    </div>
  );
};

export default DraggableContent;
