import logo from 'assets/images/logo.png';
import { ATTRIB } from 'constants/test';
import { Link } from 'react-router-dom';
import NavMenusMiddle from '../navBar/NavMenusMiddle';
import NavMenusRight from '../navBar/NavMenusRight';
import NavigationWrapper from './NavigationWrapper';

const DesktopNavigation = () => (
  <NavigationWrapper>
    <Link to='/' className='w-[10%]' data-test={ATTRIB.TEST.HOME}>
      <img src={logo} className='w-full object-cover' alt='focus bear' />
    </Link>
    <NavMenusMiddle />
    <NavMenusRight />
  </NavigationWrapper>
);

export default DesktopNavigation;
