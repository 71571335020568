import InputTime from 'components/common/inputs/InputTime';
import { TIME_FORMAT_24H } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingSelector } from 'store/reducer/setting/selectors';
import { updateStartupTime } from 'store/reducer/setting/slice';
import { v4 as uuid } from 'uuid';

const StartUpTime = () => {
  const dispatch = useAppDispatch();
  const {
    startup_time,
    shutdown_time,
    routineTimeValidation: { isValid, errorMessages }
  } = useAppSelector(timingSelector);
  const [resetKey, setResetKey] = useState(uuid()); // Unique key to forcefully reset the input when any value is cleared

  return (
    <InputTime
      key={resetKey}
      selectedDate={startup_time ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateStartupTime(value));
        if (!value) {
          setResetKey(uuid());
        }
      }}
      errorMessages={errorMessages.startupTime}
      label={t('what_time_do_you_wake_up')}
      tooltipMessage={t('explanation_starting_time', {
        startup_time: startup_time
          ? moment(startup_time, TIME_FORMAT_24H).format('hh:mm a')
          : t('wake_up_time'),
        shutdown_time: shutdown_time
          ? moment(shutdown_time, TIME_FORMAT_24H).format('hh:mm a')
          : t('finish_work_time')
      })}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      labelStyles='text-sm xs:text-base sm:text-lg'
      validateTime={() => !isValid.startupTime}
    />
  );
};

export default StartUpTime;
