import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompletedActivityPayloadType, UserRank } from 'interfaces';
import {
  HTTP_STATS_CODE,
  NUMBERS,
  PLATFORMS,
  ROUTINE
} from 'constants/general';
import { SCHEMA_LINK } from 'constants/routes';
import focusBearApi from 'services/axios-config';
import * as Sentry from '@sentry/react';
import { increment, updateDataWithID } from 'utils/helpers';
import Endpoints from 'constants/endpoints';
import {
  updateEveningStatsLoading,
  updateLastCompletedActivityLog,
  updateMorningStatsLoading,
  updateWeeklySummaryLoading
} from '../user/slice';
import {
  getMorningActivityStats,
  getEveningActivityStats,
  getWeeklyStats,
  getUserDetailsCurrentActivityProps
} from '../user/extra';
import Validator, { JSONSchema4 } from 'json-schema';
import {
  changeAreLibraryActivitiesLoading,
  changeCurrentSettings,
  changeIsSettingLoaded,
  changeIsSettingLoading,
  changeIsValidateSettings,
  changeLoadingSchema,
  changeShowChoice,
  changeShowDropProcessor,
  changeShowTabs,
  updateDevice,
  updateError,
  updateIsActivityLibraryUpdating,
  updateIsDeviceRegistering,
  updateIsPlatformCoursesSyncing,
  updateIsRoutineSummaryFetching,
  updateIsSavingRoutineCompletedActivity,
  updateIsSearchingDevice,
  updatePreviousActivityLibrary,
  updateShowSkipActivityModal
} from './slice';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { AxiosError } from 'axios';
import { SettingsType } from 'interfaces/setting.interface';
import { ActivityType } from 'interfaces/common.interface';
import { isJsonString } from 'utils/validators';
import { LEADER_BOARD } from 'constants/table';
import { RootState } from 'store';
import { Mode } from 'constants/enum';

export const initUserSettings = createAsyncThunk(
  'setting/init_user_settings',
  async (_, { dispatch }) => {
    try {
      dispatch(updateMorningStatsLoading(true));
      dispatch(updateEveningStatsLoading(true));
      dispatch(updateWeeklySummaryLoading(true));
      const { data } = await focusBearApi.get(Endpoints.USER_SETTINGS);
      dispatch(getUserDetailsCurrentActivityProps());
      dispatch(getWeeklyStats());
      const haveActivities =
        data && data.morning_activities && data.evening_activities;
      if (haveActivities) {
        const morning_activities = [...data.morning_activities];
        const evening_activities = [...data.evening_activities];
        if (morning_activities.length > 0) {
          data.morning_activities?.[0]?.activity_sequence_id &&
            dispatch(
              getMorningActivityStats(
                data.morning_activities[0]?.activity_sequence_id
              )
            );
        } else {
          dispatch(updateMorningStatsLoading(false));
        }
        if (evening_activities.length > 0) {
          data.evening_activities[0]?.activity_sequence_id &&
            dispatch(
              getEveningActivityStats(
                data.evening_activities?.[0]?.activity_sequence_id
              )
            );
        } else {
          dispatch(updateEveningStatsLoading(false));
        }
      } else {
        dispatch(updateMorningStatsLoading(false));
        dispatch(updateEveningStatsLoading(false));
      }
      return data ?? {};
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'errors.couldnt_fetch_your_settings',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const getUserSettings = createAsyncThunk(
  'setting/get_user_settings',
  async (_, { dispatch }) => {
    try {
      dispatch(changeIsSettingLoading(true));
      const { data } = await focusBearApi.get(Endpoints.USER_SETTINGS);
      return data ?? {};
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'errors.couldnt_fetch_your_settings',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const saveUserSettings = createAsyncThunk(
  'setting/save_user_settings',
  async (settings: SettingsType, { dispatch, getState, rejectWithValue }) => {
    const { device_id, mode, customRoutine } = (getState() as RootState)
      .setting;
    const is_onboarding =
      mode === Mode.CUSTOM && customRoutine === ROUTINE.SUGGESTION;
    try {
      dispatch(updateIsSavingRoutineCompletedActivity(true));
      const response = await focusBearApi.put(
        Endpoints.USER_SETTINGS,
        settings,
        {
          params: { device_id, is_onboarding }
        }
      );

      if (response?.status === HTTP_STATS_CODE.SUCCESS) {
        // it will enforce to fetch the latest changed settings from BE
        dispatch(changeIsSettingLoaded(false));
      } else {
        toast.error(i18n.t('failed_to_save_settings'));
      }
    } catch (error) {
      if (is_onboarding) {
        toast.error(i18n.t('failed_to_save_settings'));
        return rejectWithValue(null);
      } else {
        dispatch(
          updateError({
            message: 'errors.couldnt_update_your_settings',
            status: (error as AxiosError).response?.status
          })
        );
      }
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getLibraryActivities = createAsyncThunk(
  'setting/get_library_activities',
  async (_, { dispatch }) => {
    try {
      dispatch(changeAreLibraryActivitiesLoading(true));
      const { data } = await focusBearApi.get(Endpoints.ACTIVITY_LIBRARY);
      return data ?? [];
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'errors.couldnt_fetch_library_activities',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const updateLibraryActivities = createAsyncThunk(
  'setting/update_library_activities',
  async (libraryActivities: ActivityType[], { dispatch }) => {
    try {
      dispatch(updateIsActivityLibraryUpdating(true));
      const { status } = await focusBearApi.put(
        Endpoints.ACTIVITY_LIBRARY,
        libraryActivities
      );
      dispatch(updateIsActivityLibraryUpdating(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        toast.success(i18n.t('settings_saved'));
        dispatch(updatePreviousActivityLibrary(libraryActivities));
      } else {
        toast.error(i18n.t('failed_to_save_settings'));
      }
    } catch (error) {
      dispatch(updateIsActivityLibraryUpdating(false));
      toast.error(i18n.t('failed_to_save_settings'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

/**
 * @description
 * it helps to validate settings when the users
 * able to paste their settings on textarea
 */
export const validatePastedSettings = createAsyncThunk(
  'setting/validate_pasted_settings',
  async (inputJson: string, { dispatch }) => {
    try {
      dispatch(changeLoadingSchema(true));
      fetch(SCHEMA_LINK)
        .then((res) => res.json())
        .then((data: JSONSchema4) => {
          if (
            isJsonString(inputJson) &&
            Validator.validate(JSON.parse(inputJson), data)['valid']
          ) {
            dispatch(
              changeCurrentSettings(updateDataWithID(JSON.parse(inputJson)))
            );
            dispatch(changeIsValidateSettings(true));
            dispatch(changeShowChoice(false));
            dispatch(changeShowDropProcessor(false));
            dispatch(changeShowTabs(true));
          } else {
            dispatch(changeIsValidateSettings(false));
          }
        });
    } catch (error) {
      toast.error(i18n.t('validation_server_not_responding'), {
        position: 'bottom-left'
      });
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const saveRoutineCompletedActivity = createAsyncThunk(
  'setting/save_routine_completed_activity',
  async (activity: CompletedActivityPayloadType, { dispatch }) => {
    try {
      dispatch(updateIsSavingRoutineCompletedActivity(true));
      const { status, data } = await focusBearApi.post(
        Endpoints.COMPLETED_ACTIVITY,
        activity
      );
      if (status === HTTP_STATS_CODE.CREATED && data) {
        dispatch(updateLastCompletedActivityLog(data));
      }
      dispatch(updateIsSavingRoutineCompletedActivity(false));
    } catch (error) {
      toast.error(i18n.t('errors.couldnt_update_your_settings'));
      dispatch(updateIsSavingRoutineCompletedActivity(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getRoutineSummary = createAsyncThunk(
  'setting/get_routine_summary',
  async (sequence_id: string, { dispatch }) => {
    try {
      dispatch(updateIsRoutineSummaryFetching(true));
      const { data } = await focusBearApi.get(
        Endpoints.COMPLETED_ACTIVITY_SEQUENCE_STATS.replace(
          '{activity_sequence_id}',
          sequence_id
        )
      );
      return data?.daily_durations_minutes ?? [];
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const registerUserDevice = createAsyncThunk(
  'setting/register_user_device',
  async (
    payload: { operating_system: string; device_id?: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsDeviceRegistering(true));
      const { data } = await focusBearApi.post(Endpoints.DEVICE, payload);
      if (data) {
        dispatch(updateDevice(data));
        toast.success(i18n.t('routine_player.device_registration_successful'));
      } else {
        toast.error(i18n.t('routine_player.device_registration_faild'));
      }
    } catch (error) {
      dispatch(
        updateError({
          message: 'routine_player.device_registration_faild',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    } finally {
      dispatch(updateIsDeviceRegistering(false));
    }
  }
);

export const saveSkipRoutineCompletedActivity = createAsyncThunk(
  'setting/save_skip_routine_completed_activity',
  async (activity: CompletedActivityPayloadType, { dispatch }) => {
    try {
      dispatch(updateIsSavingRoutineCompletedActivity(true));
      const { status, data } = await focusBearApi.post(
        Endpoints.COMPLETED_ACTIVITY_SKIP,
        activity
      );
      if (status === HTTP_STATS_CODE.CREATED && data) {
        dispatch(updateLastCompletedActivityLog(data));
      }
      dispatch(updateIsSavingRoutineCompletedActivity(false));
      dispatch(updateShowSkipActivityModal(false));
    } catch (error) {
      toast.error(i18n.t('errors.couldnt_update_your_settings'));
      dispatch(updateIsSavingRoutineCompletedActivity(false));
      dispatch(updateShowSkipActivityModal(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getUserStatsLeaderBoard = createAsyncThunk(
  'setting/get_user_stats_leader_board',
  async (queries: { streak_type: string; limit: number }, { dispatch }) => {
    try {
      let params = {};
      if (queries.streak_type) {
        params = { ...params, streak_type: queries.streak_type };
      }
      if (queries.limit) {
        params = { ...params, limit: queries.limit };
      } else {
        params = { ...params, limit: LEADER_BOARD.STAT_LIMIT };
      }
      const { data } = await focusBearApi.get(
        Endpoints.USER_STATS_LEADER_BOARD,
        {
          params
        }
      );
      if (data.users_rankings) {
        const rank = parseInt(data.user_rank?.rank);
        const ranks = data?.users_rankings ?? [];

        let rankings: UserRank[] = [];
        const isUserInTheList = ranks.some(
          (user: UserRank) => user.id === data.user_rank?.id
        );
        if (!isUserInTheList && !isNaN(rank)) {
          rankings = ranks.map((user: UserRank) => {
            if (parseInt(user.rank) >= rank) {
              const newRank = increment(parseInt(user.rank));
              return { ...user, rank: newRank.toString() };
            } else {
              return user;
            }
          });
          rankings.splice(
            rankings.length - NUMBERS.ONE,
            NUMBERS.ONE,
            data.user_rank
          );
        } else {
          rankings = ranks;
        }
        return { user_rank: data.user_rank, users_rankings: rankings };
      } else {
        return null;
      }
    } catch (error) {
      dispatch(updateWeeklySummaryLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const syncPlatformCourses = createAsyncThunk(
  'setting/syncPlatformCourses',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsPlatformCoursesSyncing(true));
      await focusBearApi.post(Endpoints.SYNC_PLATFORM_COURSES, {
        platform: PLATFORMS.WEB
      });
      dispatch(updateIsPlatformCoursesSyncing(false));
    } catch (error) {
      dispatch(updateIsPlatformCoursesSyncing(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const searchDevice = createAsyncThunk(
  'setting/search_device',
  async (
    params: { operating_system?: string; device_id?: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsSearchingDevice(true));
      const { data } = await focusBearApi.get(`${Endpoints.DEVICE}/search`, {
        params
      });
      return data;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      return null;
    } finally {
      dispatch(updateIsSearchingDevice(false));
    }
  }
);
