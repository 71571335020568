import Goal from 'assets/icons/Goal';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import { t } from 'i18next';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { listOfSelectedGoalsSelector } from 'store/reducer/routine-suggestions/selectors';
import {
  updateRoutineSuggestionUserGoalsPayload,
  updateSelectedGoals,
  updateShowAvailableGoalsModal
} from 'store/reducer/routine-suggestions/slice';
import SelectedGoal from './SelectedGoal';

const ListOfSelectedGoals: FC = () => {
  const dispatch = useAppDispatch();
  const { isLogTermGoalUpdating, selectedGoals } = useAppSelector(
    listOfSelectedGoalsSelector
  );

  function handleDeleted(position: number) {
    const updatedGoals = selectedGoals.filter((_, index) => index !== position);
    dispatch(updateSelectedGoals(updatedGoals));
    dispatch(updateRoutineSuggestionUserGoalsPayload([...updatedGoals]));
  }

  return (
    <div className='min-w-full w-full flex flex-col gap-1'>
      <p className='w-full flex justify-center gap-2 py-6 text-lg md:text-xl lg:text-2xl'>
        <span>
          <Goal styles='w-7 sm:w-6 h-auto pt-1 sm:pt-0' />
        </span>
        {t('routine_suggestion.got_other_goals_you_want')}
      </p>
      <div className='w-full min-h-[10vh] h-fit flex items-center justify-center gap-x-10 gap-y-2.5 md:gap-y-5 flex-wrap rounded-lg p-2 sm:px-3.5 md:p-5 shadow-gray-400 shadow-inner overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFirst'>
        {selectedGoals.map((goal, index) => (
          <SelectedGoal
            key={goal}
            position={index}
            goal={goal}
            handleDeleted={handleDeleted}
          />
        ))}
        <ButtonFocusBear
          disabled={isLogTermGoalUpdating}
          onClick={() => dispatch(updateShowAvailableGoalsModal(true))}
          title={t('routine_suggestion.add_another_goal')}
          size={BTN_FB_SIZE.SMALL}
          additionalStyles='my-2'
          disableDarkMode
        />
      </div>
    </div>
  );
};

export default ListOfSelectedGoals;
