import React, { useContext } from 'react';
import { NavBarContext } from '.';
import { t } from 'i18next';
import NavMenu from './NavMenu';
import { ROUTES } from 'constants/routes';
import { ATTRIB } from 'constants/test';
import { useAppSelector } from 'store/hooks';

const NavMenusMiddle = () => {
  const isUserAdmin = useAppSelector((state) => state.user.isUserAdmin);
  const { accountMenuOptions, extraMenuOptions } = useContext(NavBarContext);

  return (
    <div className='w-full lg:w-fit flex flex-col lg:flex-row items-start lg:items-center px-2 lg:px-0 gap-4'>
      <NavMenu text={t('account')} subMenus={accountMenuOptions} />
      <NavMenu
        path={ROUTES.STATS}
        text={t('stats')}
        attribAction={ATTRIB.TEST.STATS}
      />
      <NavMenu
        path={ROUTES.SETTINGS}
        text={t('settings')}
        attribAction={ATTRIB.TEST.SETTINGS}
      />
      <NavMenu
        path={ROUTES.TODO}
        text={t('to_do_procrastinate.to_do')}
        attribAction={ATTRIB.TEST.TODO}
      />
      {isUserAdmin && (
        <NavMenu
          path={ROUTES.ADMIN}
          text={t('admin.admin')}
          attribAction={ATTRIB.TEST.ADMIN}
        />
      )}
      <NavMenu path='' text={t('extra')} subMenus={extraMenuOptions} />
    </div>
  );
};

export default NavMenusMiddle;
