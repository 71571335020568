import classNames from 'classnames';
import CheckBox from 'components/common/CheckBox';
import { useAppSelector } from 'store/hooks';
import { routineAvailableGoalsSelector } from 'store/reducer/routine-suggestions/selectors';
import { themeSelector } from 'store/reducer/setting/selectors';
import { getUniqueGoals } from 'utils/routine-suggestion.util';
import { isDarkModeActivated } from 'utils/validators';
import { AvailableGoalsModalContext } from 'pages/routine-suggestions';
import { useContext } from 'react';

const ModalContent = () => {
  const { goals, setGoals } = useContext(AvailableGoalsModalContext);
  const { availableGoals } = useAppSelector(routineAvailableGoalsSelector);

  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );

  function handleGoalSelection(goal: string) {
    setGoals?.((prev) => ({
      ...prev,
      selected: prev.selected.includes(goal)
        ? prev.selected.filter((prevGoal) => prevGoal !== goal)
        : prev.selected.concat([goal])
    }));
  }

  return (
    <div className='w-[95%] h-[65vh] md:h-[40vh] sm:h-fit flex flex-wrap justify-evenly gap-x-2 gap-y-2 rounded-lg ~p-4/6 overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFirst border border-sunRayFirst self-center'>
      {getUniqueGoals([...availableGoals, ...goals.new]).map((goal, idx) => (
        <CheckBox
          key={goal + idx}
          checked={goals.selected.includes(goal)}
          onChange={() => handleGoalSelection(goal)}
          label={goal}
          labelStyles={classNames(
            'w-fit max-w-full h-fit flex gap-1 items-start rounded-md px-2 py-1 shadow-md ~text-sm/base my-2 cursor-pointer capitalize',
            {
              'bg-gray-600 hover:bg-gray-600/80': shouldActivateDarkMode,
              'bg-whiteLaceFirst hover:bg-whiteLaceSecond ':
                !shouldActivateDarkMode
            }
          )}
          inputStyles='~w-3.5/4 ~h-3.5/4 cursor-pointer outline-none accent-sunRayFirst my-auto'
        />
      ))}
    </div>
  );
};

export default ModalContent;
