import CloseCircle from 'assets/icons/CloseCircle';
import classNames from 'classnames';
import COLOR from 'constants/color';
import { BANNER_TIMEOUT } from 'constants/general';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validators';

interface BannerProps {
  message: string;
  contentWrapperStyles?: string;
  wrapperStyles?: string;
  defaultValue?: boolean;
  timeOut?: number;
}

const Banner = ({
  message,
  wrapperStyles = 'self-center',
  contentWrapperStyles = 'w-fit p-4',
  defaultValue = true,
  timeOut = BANNER_TIMEOUT.DEFAULT
}: BannerProps) => {
  const [showBanner, setShowBanner] = useState(defaultValue);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(false);
    }, timeOut);
  }, []);

  return showBanner ? (
    <div className={`relative animate-fadeIn ${wrapperStyles}`}>
      <div
        className={classNames('rounded shadow', {
          'bg-gray-700 text-white': shouldActivateDarkMode,
          'bg-sunRayFourth text-black': !shouldActivateDarkMode,
          [contentWrapperStyles]: true
        })}
      >
        <p className='text-xs md:text-sm text-center'>{message}</p>
      </div>
      <button
        onClick={() => setShowBanner(false)}
        className='absolute -top-1.5 -right-1.5 bg-white rounded-full'
      >
        <CloseCircle
          styles='w-4 h-auto'
          fill={COLOR.RED}
          fillDarkMode={COLOR.RED}
        />
      </button>
    </div>
  ) : null;
};

export default Banner;
