import { PropsWithChildren, useEffect, useRef } from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routineActivitiesWrapperSelector } from 'store/reducer/setting/selectors';
import { resetIsNewHabitAppended } from 'store/reducer/setting/slice';
import { isDarkThemeActivated } from 'utils/settings.util';

interface Props extends PropsWithChildren<object> {
  provided: DroppableProvided;
}

const RoutineActivitiesWrapper = ({ provided, children }: Props) => {
  const dispatch = useAppDispatch();
  const dummyDiv = useRef<HTMLDivElement>(null);
  const isNewHabitAppended = useAppSelector(routineActivitiesWrapperSelector);

  useEffect(() => {
    if (isNewHabitAppended) {
      dummyDiv.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
      dispatch(resetIsNewHabitAppended());
    }
  }, [isNewHabitAppended]);

  return (
    <div
      className={`w-full h-full max-h-full py-2.5 pr-3 flex flex-col items-center gap-10 overflow-y-auto scrollbar-thin ${
        isDarkThemeActivated()
          ? 'scrollbar-thumb-gray-700 scrollbar-track-gray-500'
          : 'scrollbar-thumb-gray-300 scrollbar-track-gray-100'
      }`}
      {...provided.droppableProps}
      ref={provided.innerRef}
    >
      {children}
      <div className='float-left clear-both' ref={dummyDiv}></div>
    </div>
  );
};

export default RoutineActivitiesWrapper;
