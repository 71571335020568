import { createAppSelector } from 'store/hooks';

export const moreOptionModalSelector = createAppSelector(
  [
    (state) => state.cache.youtube_urls_metadata.videos_metadata,
    (state) => state.modal.moreOption.activity,
    (state) => state.modal.moreOption.showYouTubeURLModal,
    (state) => state.modal.moreOption.showAllowedURLModal,
    (state) => state.modal.moreOption.showChoicesModal,
    (state) => state.modal.moreOption.showImageURLModal,
    (state) => state.modal.moreOption.showCheckListModal,
    (state) => state.modal.choice.showChoicesYouTubeURLModal
  ],
  (
    videos_metadata,
    activity,
    showYouTubeURLModal,
    showAllowedURLModal,
    showChoicesModal,
    showImageURLModal,
    showCheckListModal,
    showChoicesYouTubeURLModal
  ) => {
    return {
      videos_metadata,
      activity,
      showYouTubeURLModal,
      showAllowedURLModal,
      showChoicesModal,
      showImageURLModal,
      showCheckListModal,
      showChoicesYouTubeURLModal
    };
  }
);

export const modalContentSelector = createAppSelector(
  [
    (state) => state.setting.moreOptions.type,
    (state) => state.setting.platform,
    (state) => state.setting.flags,
    (state) => state.setting.mode,
    (state) => state.cache.youtube_urls_metadata.videos_metadata,
    (state) => state.modal.moreOption.activity
  ],
  (activityType, platform, flags, mode, videos_metadata, activity) => {
    return { activityType, platform, flags, mode, videos_metadata, activity };
  }
);

export const modalContentHeaderHabitDurationSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.themeMode,
    (state) => state.setting.currentSettings.evening_activities,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.user.details?.focus_modes ?? []
  ],
  (
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  ) => {
    return {
      activity,
      themeMode,
      evening_activities,
      cutoff_time,
      shutdown_time,
      user_focus_modes
    };
  }
);

export const modalContentHeaderSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.flags,
    (state) => state.setting.platform,
    (state) => state.setting.customRoutine
  ],
  (activity, flags, platform, customRoutine) => {
    return { activity, flags, platform, customRoutine };
  }
);

export const desktopViewModalContentBodySelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.themeMode
  ],
  (activity, themeMode) => {
    return {
      activity,
      themeMode
    };
  }
);

export const moreOptionModalActionsSelector = createAppSelector(
  [
    (state) => state.modal.moreOption,
    (state) => state.setting.moreOptions,
    (state) => state.setting.currentSettings,
    (state) => state.setting.mode,
    (state) => state.setting.activityLibrary,
    (state) => state.course.tutorials
  ],
  (
    moreOption,
    moreOptions,
    currentSettings,
    mode,
    activityLibrary,
    tutorials
  ) => {
    return {
      moreOption,
      moreOptions,
      currentSettings,
      mode,
      activityLibrary,
      tutorials
    };
  }
);

export const modalContentHeaderHabitNameSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.themeMode
  ],
  (activity, themeMode) => {
    return {
      activity,
      themeMode
    };
  }
);
