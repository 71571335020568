import { DEFAULT_COURSE_LESSON_INFO } from 'assets/data';
import classNames from 'classnames';
import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { THEME_OPTION } from 'constants/enum';
import {
  FIRST_LESSON_OFFSET,
  ITEM_INDEX_NOT_FOUND,
  MODAL_TYPES
} from 'constants/general';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import { courseLessonsModalSelector } from 'store/reducer/course/selectors';
import { decrement } from 'utils/helpers';
import CourseLessonsSlider from './CourseLessonsSlider';
import ListOfLessons from './ListOfLessons';
import { CourseLesson } from 'interfaces';

interface LessonInfo {
  currentLessonIndex: number;
  lastCompletedLessonIndex: number;
  showContents: boolean;
  isLastLesson: boolean;
}

export const CourseLessonsModalContext = createContext<{
  lessonInfo: LessonInfo;
  setLessonInfo?: Dispatch<SetStateAction<LessonInfo>>;
}>({
  lessonInfo: DEFAULT_COURSE_LESSON_INFO
});

const CourseLessonsModal = () => {
  const { selectedCourse, themeMode } = useAppSelector(
    courseLessonsModalSelector
  );
  const [lessonInfo, setLessonInfo] = useState<LessonInfo>(
    DEFAULT_COURSE_LESSON_INFO
  );

  useEffect(() => {
    const lessons = selectedCourse?.lessons ?? [];
    const lessonCompletions = selectedCourse?.lessonCompletions ?? [];
    const completedLessonsIDs = lessonCompletions?.map(
      (lesson) => lesson.lesson_id
    );
    const areAllLessonsCompleted = lessons?.every((lesson) =>
      completedLessonsIDs?.includes(lesson.id)
    );
    let currentLessonIndex = FIRST_LESSON_OFFSET;
    let isLastLesson = false;

    if (!areAllLessonsCompleted) {
      const index = lessons?.findIndex((lesson) =>
        lessonCompletions?.every(
          (completion) => completion.lesson_id !== lesson.id
        )
      );
      currentLessonIndex =
        index !== ITEM_INDEX_NOT_FOUND ? index : FIRST_LESSON_OFFSET;
    } else {
      isLastLesson =
        lessonInfo.currentLessonIndex === decrement(lessons.length);
    }

    setLessonInfo?.((prev) => ({
      ...prev,
      currentLessonIndex,
      isLastLesson,
      lessons
    }));
  }, []);

  return (
    <ModalOverlay>
      <ModalWrapper
        mainStyles={classNames(
          'relative top-1/2 -translate-y-1/2 w-11/12 md:w-10/12 h-[90vh] md:h-[80vh] flex flex-col border-2 border-orange-300 rounded-lg p-3 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] mx-auto',
          {
            'bg-white': themeMode === THEME_OPTION.LIGHT,
            'bg-gray-400': themeMode === THEME_OPTION.DARK
          }
        )}
        additionalStyles='gap-1'
      >
        <ModalHeader
          modalType={MODAL_TYPES.COURSE_LESSONS_MODAL}
          title={selectedCourse?.name}
        />
        <CourseLessonsModalContext.Provider
          value={useMemo(() => ({ lessonInfo, setLessonInfo }), [lessonInfo])}
        >
          {lessonInfo.showContents ? (
            <CourseLessonsSlider />
          ) : (
            <ListOfLessons />
          )}
        </CourseLessonsModalContext.Provider>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default CourseLessonsModal;
