import { LOCAL_STORAGE } from 'constants/general';
import { ROUTES, VALID_REDIRECT_PATHS } from 'constants/routes';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { updateLocalStorage } from 'utils/helpers';

const DocumentMetaData = ({ pageName }: { pageName: string }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    VALID_REDIRECT_PATHS.includes(pathname) &&
      updateLocalStorage(
        LOCAL_STORAGE.REDIRECT_PATH,
        pathname === ROUTES.TODO ? `${pathname}${search}` : pathname
      );
  }, [pathname]);

  return (
    <Helmet>
      <title>{`Focus Bear ${pageName ? `- ${pageName}` : ''}`}</title>
      <link rel='canonical' href='http://dashboard.focusbear.io/' />
    </Helmet>
  );
};

export default DocumentMetaData;
