import { useEffect, useRef, useCallback } from 'react';
import { ChoiceType } from 'interfaces/common.interface';
import ChoiceItem from './ChoiceItem';
import { useTranslation } from 'react-i18next';
import {
  EMPTY_STRING,
  HABIT_CHOICE_TYPE,
  MODAL_TYPES
} from 'constants/general';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoices,
  updateIsChoiceEdited,
  updateMoreOptionChoices,
  updateMoreOptionShowChoicesModal
} from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import {
  DEFAULT_CHOICE_ITEM,
  DEFAULT_COMPETENCY_CHOICE_ITEM
} from 'assets/data';
import _ from 'lodash';
import { THEME_OPTION } from 'constants/enum';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`relative top-[40%] -translate-y-[40%] mx-auto w-[95%] md:w-[65%] shadow-lg rounded-lg ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-700' : 'bg-gray-200'
      } flex flex-col gap-2`}
    >
      {children}
    </div>
  );
};

const ModalBody = () => {
  const dummyDiv = useRef<HTMLDivElement>(null); // this will help to scroll down when a new choice item added to the list
  const { choices } = useAppSelector((state) => state.modal.choice);

  useEffect(() => {
    dummyDiv.current?.scrollIntoView({
      behavior: 'smooth'
    });
  }, [choices.length]);

  return (
    <div className='w-full h-fit max-h-96 flex flex-col gap-4 pl-4 pr-6 py-2 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
      {choices.length === 0 ? (
        <EmptyChoices />
      ) : (
        choices.map((choice: ChoiceType, index: number) => (
          <ChoiceItem key={choice.id} position={index} />
        ))
      )}
      <div className='float-left clear-both' ref={dummyDiv}></div>
    </div>
  );
};

const EmptyChoices = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <p className='font-semibold'>{t('no_choices_added_yet')}</p>
    </div>
  );
};

const ModalActions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    choice: {
      choices,
      showChoicesYouTubeURLModal,
      areLogQuestionsInvalid,
      isChoiceEdited
    },
    moreOption: { activity }
  } = useAppSelector((state) => state.modal);

  const areNamesValid = choices.every((choice) => Boolean(choice.name));
  const areDurationsValid = choices.every((choice) =>
    Boolean(choice.duration_seconds)
  );

  const shouldAllowSaving =
    areNamesValid && areDurationsValid && !areLogQuestionsInvalid;

  const newChoice =
    activity?.choice_type === HABIT_CHOICE_TYPE.COMPETENCY_BASED
      ? DEFAULT_COMPETENCY_CHOICE_ITEM()
      : DEFAULT_CHOICE_ITEM();

  useEffect(() => {
    checkChoicesChanges();
  }, [choices]);

  const handleSave = useCallback(() => {
    const checkHabitChoicesValidity =
      choices.filter((choice) => choice.name === EMPTY_STRING).length > 0;
    if (!checkHabitChoicesValidity) {
      dispatch(updateMoreOptionChoices(choices));
      dispatch(updateMoreOptionShowChoicesModal(false));
    }
  }, [choices]);

  const checkChoicesChanges = () => {
    const isEdited = !_.isEqual(choices, activity?.choices ?? []);
    dispatch(updateIsChoiceEdited(isEdited));
  };

  return (
    <div className='w-fit h-fit flex items-center gap-5 self-end mr-6 mb-2'>
      {isChoiceEdited && (
        <button
          disabled={!shouldAllowSaving}
          data-test={ATTRIB.TEST.SAVE_ACTIVITY_CHOICES}
          onClick={handleSave}
          className={`w-fit px-5 py-1 rounded-lg font-medium text-sm ${
            shouldAllowSaving
              ? 'text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
              : 'text-gray-500 bg-transparent border-2 border-gray-500 cursor-not-allowed'
          }`}
        >
          {t('save')}
        </button>
      )}
      <button
        data-test={ATTRIB.TEST.NEW_ACTIVITY_CHOICE}
        disabled={showChoicesYouTubeURLModal}
        onClick={() => {
          dispatch(updateChoices([...choices, newChoice]));
        }}
        className='w-fit px-3 py-1 rounded-lg bg-gray-500 hover:bg-gray-600 text-white font-medium text-sm cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
      >
        {t('new_choice')}
      </button>
    </div>
  );
};

const ChoicesModal = () => {
  const { t } = useTranslation();
  return (
    <ModalOverlay zIndex='z-[70]'>
      <Wrapper>
        <ModalHeader title={t('choices')} modalType={MODAL_TYPES.CHOICES} />
        <ModalBody />
        <ModalActions />
      </Wrapper>
    </ModalOverlay>
  );
};

export default ChoicesModal;
