import COLOR from 'constants/color';
import { IconProps } from 'interfaces';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validators';

const HelpFilled = ({
  fill = COLOR.BLACK,
  styles = 'w-5 h-auto object-cover cursor-pointer'
}: IconProps) => {
  return (
    <svg
      className={styles}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path
          d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7.92 9.234v.102a.5.5 0 0 0 .5.5h.997a.499.499 0 0 0 .499-.499c0-1.29.998-1.979 2.34-1.979 1.308 0 2.168.689 2.168 1.67 0 .928-.482 1.359-1.686 1.91l-.344.154C11.379 11.54 11 12.21 11 13.381v.119a.5.5 0 0 0 .5.5h.997a.499.499 0 0 0 .499-.499c0-.516.138-.723.55-.912l.345-.155c1.445-.654 2.529-1.514 2.529-3.39v-.103c0-1.978-1.72-3.441-4.164-3.441-2.478 0-4.336 1.428-4.336 3.734zm2.58 7.757c0 .867.659 1.509 1.491 1.509.85 0 1.509-.642 1.509-1.509 0-.867-.659-1.491-1.509-1.491-.832 0-1.491.624-1.491 1.491z'
          fill={
            !isDarkModeActivated(useAppSelector(themeSelector))
              ? fill
              : COLOR.WHITE
          }
        ></path>
      </g>
    </svg>
  );
};

export default HelpFilled;
