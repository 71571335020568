import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_VARIANT, Mode } from 'constants/enum';
import {
  ALL_APPS_POST_MESSAGE_DATA,
  MAC_APP_DATA_LOCATION,
  ROUTINE
} from 'constants/general';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/common.interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { saveUserSettings } from 'store/reducer/setting/extra';
import { configureTabs } from 'store/reducer/setting/slice';
import { filterOutCutoffTimeAndTutorial } from 'utils/helpers';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { captureEvents } from 'utils/events';
import { MOBILE_APP_EVENT, POST_HOG_EVENT } from 'constants/events';
import { useMemo } from 'react';
import { suggestionsActionsSelector } from 'store/reducer/routine-suggestions/selectors';
import {
  updateAreSuggestedHabitPacksInstalled,
  updateIsInstallingRoutineSuggestions,
  updateShowEditSuggestionsModal
} from 'store/reducer/routine-suggestions/slice';

const SuggestionsActions = ({
  morning_activities,
  evening_activities
}: {
  morning_activities: ActivityType[];
  evening_activities: ActivityType[];
}) => {
  const dispatch = useAppDispatch();
  const {
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userEmail,
    userId,
    routineSuggestions
  } = useAppSelector(suggestionsActionsSelector);

  const handleGeneratedHabits = async () => {
    captureEvents(
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_USE_GENERATED_BUTTON_CLICKED,
      userEmail,
      { platform, userId }
    );
    dispatch(
      configureTabs({
        mode: Mode.CUSTOM,
        routine: ROUTINE.SUGGESTION
      })
    );
    dispatch(updateIsInstallingRoutineSuggestions(true));
    const [morning_habits] = filterOutCutoffTimeAndTutorial(
      morning_activities,
      []
    );
    const { cutoff_time_for_non_high_priority_activities, ...rest } =
      currentSettings;
    const response = await dispatch(
      saveUserSettings({
        ...rest,
        morning_activities: morning_habits,
        evening_activities
      })
    );
    const isRequestFulfilled = response.meta.requestStatus === 'fulfilled';
    isRequestFulfilled && dispatch(updateIsInstallingRoutineSuggestions(false));

    sendDataToPlatform(
      platform,
      isRequestFulfilled
        ? ALL_APPS_POST_MESSAGE_DATA.ROUTINE_SUGGESTIONS_INSTALLED_SUCCESSFULLY
        : ALL_APPS_POST_MESSAGE_DATA.ROUTINE_SUGGESTIONS_INSTALLATION_FAILED,
      MAC_APP_DATA_LOCATION.SEND_SUGGESTED_ACTIVITIES,
      MOBILE_APP_EVENT.SUGGESTED_ACTIVITIES_INSTALLED
    );
  };

  const routineSuggestionsValid = useMemo(
    () =>
      routineSuggestions?.every((suggestion) =>
        Boolean(suggestion.duration_seconds)
      ),
    [routineSuggestions]
  );

  return (
    <div className='flex flex-col items-center ~gap-4/16 py-2'>
      {isInstallingRoutineSuggestions ? (
        <div className='w-5 h-5 rounded-full border-t-2 border-black animate-spin'></div>
      ) : (
        <div className='flex items-center justify-center gap-4 sm:gap-8 flex-wrap'>
          <ButtonFocusBear
            title={t('routine_suggestion.edit_habits')}
            onClick={() => {
              dispatch(
                configureTabs({
                  mode: Mode.CUSTOM,
                  routine: ROUTINE.SUGGESTION
                })
              );
              dispatch(updateShowEditSuggestionsModal(true));
              captureEvents(
                POST_HOG_EVENT.ROUTINE_SUGGESTIONS_EDIT_HABITS_BUTTON_CLICKED,
                userEmail,
                { platform, userId }
              );
            }}
            variant={BTN_FB_VARIANT.SECONDARY}
            additionalStyles='text-base'
            disableDarkMode
            disableResize
          />
          <ButtonFocusBear
            title={t('save_restart')}
            onClick={handleGeneratedHabits}
            additionalStyles='text-base'
            disableDarkMode
            disabled={!routineSuggestionsValid}
            disableResize
          />
        </div>
      )}
    </div>
  );
};

export default SuggestionsActions;
