import { PropsWithChildren, useEffect } from 'react';
import { ATTRIB } from 'constants/test';
import Switch from 'react-switch';
import { createAppSelector, useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateMoreOptionAllowedApps,
  updateMoreOptionFocusMode,
  updateMoreOptionIsUseFocusModeChecked,
  updateMoreOptionURLs
} from 'store/reducer/modal/slice';
import { SWITCH } from 'constants/tag';
import { EMPTY_STRING, INDEX, MODAL_TYPES } from 'constants/general';
import { t } from 'i18next';
import { isDarkModeActivated } from 'utils/validators';
import { THEME_OPTION } from 'constants/enum';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full md:w-1/4 flex flex-col'>{children}</div>
);

const FocusMode = () => {
  const dispatch = useAppDispatch();
  const { details, activity, theme, isUseFocusModeChecked } = useAppSelector(
    createAppSelector(
      [
        (state) => state.user.details,
        (state) => state.modal.moreOption.activity,
        (state) => state.setting.themeMode.slice() as THEME_OPTION,
        (state) => state.modal.moreOption.isUseFocusModeChecked
      ],
      (details, activity, theme, isUseFocusModeChecked) => ({
        details,
        activity,
        theme,
        isUseFocusModeChecked
      })
    )
  );

  const focus_modes = details?.focus_modes ?? [];

  useEffect(() => {
    dispatch(
      updateMoreOptionIsUseFocusModeChecked(
        Boolean(activity?.allowed_focus_mode_id)
      )
    );
  }, [activity]);

  return (
    <Wrapper>
      <label
        data-test={ATTRIB.TEST.SWITCH_USE_FOCUS_MODES}
        className='flex gap-2 text-xs lg:text-sm font-medium cursor-pointer py-1'
      >
        <Switch
          width={SWITCH.WIDTH.FOCUS_MODES}
          height={SWITCH.HEIGHT.FOCUS_MODES}
          onChange={(value) => {
            dispatch(updateMoreOptionIsUseFocusModeChecked(value));
            if (value && focus_modes?.length) {
              dispatch(updateMoreOptionFocusMode(focus_modes[INDEX.ZERO]?.id));
              dispatch(updateMoreOptionAllowedApps([]));
              dispatch(
                updateMoreOptionURLs({
                  modal_type: MODAL_TYPES.ALLOWED_URLS,
                  urls: []
                })
              );
            } else {
              dispatch(updateMoreOptionFocusMode(EMPTY_STRING)); // it removes focus mode ids
            }
          }}
          checked={isUseFocusModeChecked}
        />
        {t('use_focus_mode')}
      </label>
      {isUseFocusModeChecked && (
        <div className='w-full'>
          {focus_modes?.length ? (
            <select
              value={activity?.allowed_focus_mode_id}
              onChange={({
                target: { value }
              }: React.ChangeEvent<HTMLSelectElement>) => {
                dispatch(updateMoreOptionFocusMode(value));
              }}
              className={`w-full outline-none border border-gray-300 ${
                isDarkModeActivated(theme) ? 'bg-gray-400' : 'bg-white'
              } rounded cursor-pointer text-sm font-medium px-1 py-0.5 capitalize text-black`}
            >
              {focus_modes?.map((mode) => (
                <option
                  key={mode.id}
                  className='text-sm font-medium'
                  value={mode.id}
                >
                  {mode.name}
                </option>
              ))}
            </select>
          ) : (
            <p className='w-full flex justify-center text-xs font-semibold'>
              {t('no_focus_mode_found')}
            </p>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default FocusMode;
