import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Entitlement } from 'interfaces/initial-states.interface';
import {
  ActiveEntitlement,
  Subscription,
  SubscriptionInfoTypes
} from 'interfaces/subscription.interface';
import { initialSubscriptionState } from 'store/initial-states';

const subscription = createSlice({
  name: 'subscription',
  initialState: initialSubscriptionState,
  reducers: {
    updateSubscriptionStripPans: (state, { payload }) => {
      state.stripPlans = payload;
    },
    updateIsFetchingStripPlans: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingStripPlans = payload;
    },
    updateSubscriptionInfo: (
      state,
      { payload }: PayloadAction<SubscriptionInfoTypes>
    ) => {
      state.subscriptionInfo = payload;
    },
    updateSubscriptionUserId: (state, action) => {
      state.subscriptionInfo.userId = action.payload;
    },
    updateIsSubscriptionChecking: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSubscriptionChecking = payload;
    },
    updateEntitlements: (state, { payload }: PayloadAction<Entitlement[]>) => {
      state.entitlements = payload;
    },
    updateActiveEntitlements: (
      state,
      { payload }: PayloadAction<ActiveEntitlement[]>
    ) => {
      state.activeEntitlements = payload;
    },
    updateSubscriptions: (
      state,
      { payload }: PayloadAction<Subscription[]>
    ) => {
      state.subscriptions = payload;
    }
  }
});

export const {
  updateSubscriptionStripPans,
  updateIsFetchingStripPlans,
  updateSubscriptionInfo,
  updateSubscriptionUserId,
  updateIsSubscriptionChecking,
  updateEntitlements,
  updateActiveEntitlements,
  updateSubscriptions
} = subscription.actions;

export default subscription.reducer;
