import Mail from 'assets/icons/Mail';
import { BTN_FB_SIZE } from 'constants/enum';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { emailVerificationBannerSelector } from 'store/reducer/user/selectors';
import ButtonFocusBear from './common/buttons/ButtonFocusBear';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { sendEmailVerification } from 'store/reducer/user/extra';
import classNames from 'classnames';
import {
  EMAIL_VERIFICATION_COOL_DOWN,
  TIME_UNIT_CONVERT_FACTOR
} from 'constants/general';
import { decrement } from 'utils/helpers';

const EmailVerificationBanner = () => {
  const dispatch = useAppDispatch();
  const { email, isSendingEmailVerification } = useSelector(
    emailVerificationBannerSelector
  );

  const [coolDown, setCoolDown] = useState(EMAIL_VERIFICATION_COOL_DOWN.START);

  useEffect(() => {
    if (coolDown > EMAIL_VERIFICATION_COOL_DOWN.START) {
      const timer = setTimeout(
        () => setCoolDown((prev) => decrement(prev)),
        TIME_UNIT_CONVERT_FACTOR.ONE_SECOND_AS_MILLISECONDS
      );
      return () => clearTimeout(timer);
    }
  }, [coolDown]);

  const handleClick = useCallback(() => {
    if (coolDown === EMAIL_VERIFICATION_COOL_DOWN.START) {
      dispatch(sendEmailVerification());
      setCoolDown(EMAIL_VERIFICATION_COOL_DOWN.RESET);
    }
  }, [dispatch, coolDown]);

  const btnTitle = useMemo(() => {
    if (isSendingEmailVerification) {
      return '.... ..... ....';
    } else if (coolDown) {
      return String(coolDown);
    } else {
      return t('resend_verification_email');
    }
  }, [isSendingEmailVerification, coolDown]);

  return (
    <div className='flex items-center justify-between gap-2 flex-wrap px-4 py-3 bg-sunRayFourth shadow-md animate-fadeIn'>
      <div className='flex items-center gap-2'>
        <Mail styles='~w-9/6 h-auto mb-auto' />
        <span className='~text-xs/base'>
          {t('please_verify_your_email_address_by_clicking_the_link_sent_to')}
          <strong className='ml-1'>{email ?? ''}</strong>
        </span>
      </div>
      <ButtonFocusBear
        disabled={isSendingEmailVerification || !!coolDown}
        title={btnTitle}
        additionalStyles={classNames('min-w-max', {
          'animate-pulse': isSendingEmailVerification,
          'animate-none': !isSendingEmailVerification
        })}
        size={BTN_FB_SIZE.SMALL}
        onClick={handleClick}
      />
    </div>
  );
};

export default EmailVerificationBanner;
