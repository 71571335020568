import classNames from 'classnames';
import InitEmbeddedServices from 'dashboard/InitEmbeddedServices';
import OverlaySpinner from 'components/OverlaySpinner';
import RoutineSuggestionsWizard from 'components/routine-suggestions/routine-suggestions-wizard';
import AvailableGoalsModal from 'components/routine-suggestions/routine-suggestions-wizard/routine-goals/available-goals-modal';
import EditSuggestionsModal from 'components/routine-suggestions/routine-suggestions-wizard/suggestions/EditSuggestionsModal';
import { ROUTES } from 'constants/routes';
import { t } from 'i18next';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getHabitPacks } from 'store/reducer/habit-pack/extra';
import { routineSuggestionsSelector } from 'store/reducer/routine-suggestions/selectors';
import { updateRoutineSuggestionUserGoalsPayload } from 'store/reducer/routine-suggestions/slice';
import { getUserDetails } from 'store/reducer/user/extra';
import { loadMetaDataForRoutineSuggestion } from 'utils/focusBear_apps';
import { isDarkModeActivated } from 'utils/validators';

export interface Goals {
  new: string[];
  selected: string[];
}

export const AvailableGoalsModalContext = createContext<{
  goals: Goals;
  setGoals?: Dispatch<SetStateAction<Goals>>;
}>({ goals: { new: [], selected: [] } });

const RoutineSuggestions = () => {
  const dispatch = useAppDispatch();
  const {
    themeMode,
    isTokenAddedToInterceptor,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal,
    language,
    showAvailableGoalsModal
  } = useAppSelector(routineSuggestionsSelector);
  const [goals, setGoals] = useState<Goals>({ new: [], selected: [] });

  useEffect(() => {
    dispatch(updateRoutineSuggestionUserGoalsPayload(goals.selected));
  }, [goals.selected]);

  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  useEffect(() => {
    loadMetaDataForRoutineSuggestion();
  }, []);

  useEffect(() => {
    isTokenAddedToInterceptor &&
      Promise.allSettled([
        dispatch(getUserDetails()),
        dispatch(getHabitPacks({ featured_for_onboarding: true, language }))
      ]);
  }, [isTokenAddedToInterceptor]);

  return (
    <AvailableGoalsModalContext.Provider value={{ goals, setGoals }}>
      {isTokenAddedToInterceptor ? (
        <section
          className={classNames(
            'w-screen h-screen flex flex-col items-center justify-center ~p-2/8 overflow-x-hidden overflow-y-auto scrollbar-thin',
            {
              'bg-gray-700 text-white': shouldActivateDarkMode,
              'bg-whiteLace text-black': !shouldActivateDarkMode
            }
          )}
        >
          <RoutineSuggestionsWizard />
          {(isSendingInstalledHabitPackId ||
            areSuggestedHabitPacksInstalled) && (
            <OverlaySpinner title={t('updating')} />
          )}
          {window.location.pathname === ROUTES.WEBVIEW_ROUTINE_SUGGESTION && (
            <ToastContainer />
          )}
          {showEditSuggestionsModal && <EditSuggestionsModal />}
          {showAvailableGoalsModal && <AvailableGoalsModal />}
        </section>
      ) : (
        <InitEmbeddedServices />
      )}
    </AvailableGoalsModalContext.Provider>
  );
};

export default RoutineSuggestions;
