import React, { useContext, useState } from 'react';
import { InputURLProps } from 'interfaces';
import { useTranslation } from 'react-i18next';
import {
  isDarkModeActivated,
  isValidImageURL,
  isYoutubeURL
} from 'utils/validators';
import { MODAL_TYPES, YOUTUBE_URL_TAB_INDEX } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ATTRIB } from 'constants/test';
import { toast } from 'react-toastify';
import { fetchYoutubeURLMetadata } from 'store/reducer/cache/extra';
import { URLModalContext } from '.';
import { updateMoreOptionImageURLs } from 'store/reducer/modal/slice';
import _ from 'lodash';
import { increment } from 'utils/helpers';
import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';

const InvalidUrMessage = ({
  modalType,
  habitUrl
}: {
  modalType: string;
  habitUrl: string;
}) => {
  const { t } = useTranslation();
  const isModalEitherYoutubeOrChoiceYoutubeUrls =
    modalType === MODAL_TYPES.YOUTUBE_URLS ||
    modalType === MODAL_TYPES.CHOICE_YOUTUBE;

  return (
    <>
      {' '}
      {isModalEitherYoutubeOrChoiceYoutubeUrls && !isYoutubeURL(habitUrl) && (
        <p className='text-red-500 text-xs font-semibold'>
          {t('invalid_youtube_url')}
        </p>
      )}
      {modalType === MODAL_TYPES.IMAGE_URLS && !isValidImageURL(habitUrl) && (
        <p className='text-red-500 text-xs font-semibold'>
          {t('invalid_image_url')}
        </p>
      )}
    </>
  );
};

const VideoMetaData = ({ id, url, modalType }: InputURLProps) => {
  const {
    cache: { youtube_urls_metadata },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  const metaData = youtube_urls_metadata.videos_metadata.find(
    (metaData) => metaData.video_url === url
  );

  return metaData ? (
    <div className='w-full flex items-center gap-2'>
      <p className='w-fit max-w-full text-xs pb-0.5 font-medium truncate'>
        {metaData.title ??
          `${
            modalType !== MODAL_TYPES.IMAGE_URLS ? t('url') : t('image')
          } ${increment(id)}`}
      </p>
      {metaData.duration && (
        <p
          className={`w-fit text-xs ${
            shouldActivateDarkMode ? 'text-gray-100' : 'text-blue-700'
          }`}
        >
          {`(${metaData.duration})`}
        </p>
      )}
    </div>
  ) : null;
};

const InputURL = ({ id, url, modalType }: InputURLProps) => {
  const { habitUrls, setHabitUrls } = useContext(URLModalContext);
  const [habitUrl, setHabitUrl] = useState(url);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    cache: { isYouTubeURLMetadataFetching, youtube_urls_metadata },
    modal: {
      moreOption: { activity }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const isModalEitherYoutubeOrChoiceYoutubeUrls =
    modalType === MODAL_TYPES.YOUTUBE_URLS ||
    modalType === MODAL_TYPES.CHOICE_YOUTUBE;

  const processURLs = (id: number, value: string) => {
    setHabitUrl(value);
    if (modalType !== MODAL_TYPES.IMAGE_URLS) {
      habitUrls.includes(value) &&
        toast.error(t('errors.duplicate_url'), { position: 'bottom-center' });
      const urls = [...habitUrls];
      urls.forEach((_, index) => {
        if (id === index) {
          urls[id] = value;
        }
      });
      setHabitUrls?.([...urls]);
      const video_metadata = youtube_urls_metadata.videos_metadata.find(
        (metadata) => metadata.video_url === value
      );
      if (isYoutubeURL(value) && !video_metadata) {
        dispatch(fetchYoutubeURLMetadata([...urls]));
      }
    } else {
      const imagesArrayClone = _.cloneDeep(activity?.image_urls ?? []);
      imagesArrayClone[id].url = value;
      dispatch(updateMoreOptionImageURLs([...imagesArrayClone]));
    }
  };

  return (
    <div className='w-[90%] flex flex-col gap-0.5 py-2.5'>
      {isYouTubeURLMetadataFetching ? (
        <div
          className={`w-4 sm:w-5 h-4 sm:h-5 rounded-full border-r ${
            shouldActivateDarkMode ? 'border-white' : 'border-gray-600'
          } animate-spin mb-1`}
        ></div>
      ) : (
        <VideoMetaData id={id} url={url} modalType={modalType} />
      )}
      <input
        data-test={
          isModalEitherYoutubeOrChoiceYoutubeUrls
            ? `${ATTRIB.TEST.INPUT_YOUTUBE_URL}${id}`
            : `${ATTRIB.TEST.INPUT_ALLOWED_URL}${id}`
        }
        className={`w-full outline-none border border-gray-400 rounded px-1.5 py-0.5 text-sm font-medium text-black ${
          !habitUrl && 'border border-red-500'
        } ${shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'}`}
        type='text'
        value={habitUrl}
        onChange={({
          target: { value }
        }: React.ChangeEvent<HTMLInputElement>) => {
          processURLs(id, value);
        }}
        tabIndex={YOUTUBE_URL_TAB_INDEX}
        {...DEFAULT_HTML_ATTRIBUTES}
      />
      <InvalidUrMessage habitUrl={habitUrl} modalType={modalType} />
    </div>
  );
};

export default React.memo(InputURL);
