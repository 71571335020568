import { useMemo } from 'react';
import {
  ALLOWED_APPS_MIN_QUERY_LENGTH,
  AUTO_COMPLETION_TAGS,
  KEY_BOARD
} from 'constants/general';
import { ATTRIB } from 'constants/test';
import { WithContext as ReactTags } from 'react-tag-input';
import { isDarkModeActivated } from 'utils/validators';
import { useAppSelector } from 'store/hooks';
import { AutoCompletionTag } from 'interfaces/common.interface';
import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';

interface AutoCompletionTagsProps {
  tags: AutoCompletionTag[];
  suggestions: AutoCompletionTag[];
  setTags: (arg: AutoCompletionTag[]) => void;
  handleAddTag: (arg: AutoCompletionTag) => void;
  placeholder?: string;
  containerStyles?: string;
  disabled?: boolean;
  allowDragDrop?: boolean;
}

export const AutoCompletionTags = ({
  tags,
  setTags,
  handleAddTag,
  suggestions,
  placeholder,
  containerStyles,
  disabled = false,
  allowDragDrop = true
}: AutoCompletionTagsProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  const formatBackground = useMemo(
    () =>
      `${shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'} ${
        tags.length && 'p-2'
      } text-black`,
    [themeMode, tags?.length]
  );

  return (
    <div className={containerStyles ?? 'w-full h-fit'}>
      <ReactTags
        data-test={ATTRIB.TEST.INPUT_APPLICATIONS}
        minQueryLength={ALLOWED_APPS_MIN_QUERY_LENGTH}
        tags={tags}
        suggestions={suggestions}
        delimiters={[KEY_BOARD.ENTER.CODE]}
        handleDrag={(
          app: AutoCompletionTag,
          currPosition: number,
          newPosition: number
        ) => {
          const slicedApps = tags
            ?.splice(
              currPosition,
              AUTO_COMPLETION_TAGS.DELETE_OLD_POSITION_TAG_COUNT
            )
            ?.splice(
              newPosition,
              AUTO_COMPLETION_TAGS.DELETE_NEW_POSITION_TAG_COUNT,
              app
            );
          setTags(slicedApps);
        }}
        handleDelete={(id: number) => {
          const filteredApps = (tags ?? []).filter((_, idx) => idx !== id);
          setTags(filteredApps);
        }}
        handleAddition={(app: AutoCompletionTag) => handleAddTag(app)}
        inputFieldPosition='top'
        autocomplete
        placeholder={placeholder}
        classNames={{
          tags: 'w-full',
          tagInputField: `w-full p-1.5 outline-none rounded-t text-sm font-medium ${formatBackground} border-b border-gray-300 rounded-b`,
          selected: `w-full h-fit max-h-24 flex flex-wrap gap-x-2.5 gap-y-1.5 rounded-b text-sm ${formatBackground} overflow-y-auto scroll-thin scrollbar-thumb-gray-300`,
          tag: `${
            shouldActivateDarkMode
              ? 'text-black bg-gray-300'
              : 'text-white bg-gray-600'
          } pl-2 pr-1 py-0.5 rounded-md`,
          suggestions: `w-full h-fit max-h-16 overflow-y-auto scroll-thin scrollbar-thumb-gray-400 text-xs md:text-sm ${
            shouldActivateDarkMode
              ? 'bg-gray-500 text-white'
              : 'bg-gray-50 text-black'
          } px-1.5`,
          activeSuggestion: 'bg-gray-600 text-white rounded cursor-pointer p-1',
          remove: 'ml-2'
        }}
        allowDragDrop={allowDragDrop}
        inputProps={{ ...DEFAULT_HTML_ATTRIBUTES, disabled }}
      />
    </div>
  );
};
