import AddHabitButton from 'components/setting-generator/tabs/AddHabitButton';
import { ACTIVITY_TYPES, ROUTINE } from 'constants/general';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/common.interface';
import { useCallback, useContext, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { CustomRoutineContext } from '.';
import DraggableContent from '../../DraggableContent';
import { useAppDispatch } from 'store/hooks';
import { changeActivityOrder } from 'store/reducer/setting/slice';

const CustomRoutineActivities = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);
  const [activities, sequence_id] = useMemo(
    () => [
      customRoutine?.standalone_activities ?? [],
      customRoutine?.standalone_activities?.[0]?.activity_sequence_id ?? uuid()
    ],
    [customRoutine?.standalone_activities]
  );

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const updateData = Array.from(activities ?? []);
      const [removed] = updateData.splice(result.source.index, 1);
      updateData.splice(result.destination.index, 0, removed);
      dispatch(
        changeActivityOrder({
          type: ACTIVITY_TYPES.CUSTOM_ROUTINES,
          data: [...updateData],
          customRoutinePosition: customRoutine?.position
        })
      );
    },
    [activities]
  );

  return (
    <>
      {activities?.length ? (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId={`list-container-${ACTIVITY_TYPES.STANDALONE}`}
          >
            {(provided) => (
              <div
                className='w-full h-fit flex flex-col items-center  gap-4 lg:gap-5 xl:gap-6'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {activities?.map((activity: ActivityType, index: number) => (
                  <Draggable
                    key={activity.id}
                    draggableId={activity.id}
                    index={index}
                  >
                    {(provided) => (
                      <DraggableContent
                        position={index}
                        type={ACTIVITY_TYPES.CUSTOM_ROUTINES}
                        sequence_id={sequence_id}
                        provided={provided}
                        customRoutinePosition={customRoutine?.position}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
      <AddHabitButton
        routine={
          !activities.length
            ? customRoutine?.name || t('custom_routine')
            : undefined
        }
        title={
          !activities.length
            ? customRoutine?.name || t('custom_routine')
            : undefined
        }
        customRoutinePosition={customRoutine?.position}
      />
    </>
  );
};

export default CustomRoutineActivities;
