import MobileNavigation from '../layout/MobileNavigation';
import DesktopNavigation from '../layout/DesktopNavigation';
import { SCREEN } from 'constants/general';
import { useContext, createContext, useMemo } from 'react';
import { LayoutContext } from '../layout';
import { useAppSelector } from 'store/hooks';
import { MenuItem } from 'interfaces';
import { getAccountDropDownMenus } from 'utils/navBar.util';
import { navBarSelector } from 'store/reducer/user/selectors';

export const NavBarContext = createContext<{
  accountMenuOptions: MenuItem[];
  extraMenuOptions: MenuItem[];
}>({ accountMenuOptions: [], extraMenuOptions: [] });

const NavBar = () => {
  const { entitlements, language, teams } = useAppSelector(navBarSelector);

  const { width } = useContext(LayoutContext);
  const { accountMenuOptions, extraMenuOptions } = getAccountDropDownMenus(
    entitlements,
    teams
  );

  return (
    <NavBarContext.Provider
      value={useMemo(
        () => ({ accountMenuOptions, extraMenuOptions }),
        [entitlements, language, teams]
      )}
    >
      {width >= SCREEN.WIDTH.LARGE ? (
        <DesktopNavigation />
      ) : (
        <MobileNavigation />
      )}
    </NavBarContext.Provider>
  );
};

export default NavBar;
