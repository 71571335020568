import {
  MAC_APP_DATA_LOCATION,
  ROUTINE_SUGGESTION_WIZARD_STEP
} from 'constants/general';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { platformSelector } from 'store/reducer/setting/selectors';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { isMobilePlatform } from 'utils/settings.util';
import RoutineDuration from './RoutineDuration';
import RoutineSelection from './RoutineSelection';
import WizardNavigation from './WizardNavigation';
import ExploreHabitPacks from './explore-habit-packs/ExploreHabitPacks';
import RoutineGoals from './routine-goals';
import Suggestions from './suggestions';

export const WizardSectionWrapper = ({
  children
}: PropsWithChildren<object>) => {
  return (
    <div className='min-w-full h-full flex flex-col gap-8 items-center justify-center py-10 px-2 sm:px-4 md:px-10 lg:px-16 relative shadow rounded-3xl overflow-y-auto scrollbar-thin'>
      {children}
    </div>
  );
};

const RoutineSuggestionsWizard = () => {
  const platform = useAppSelector(platformSelector);
  const [step, setStep] = useState(ROUTINE_SUGGESTION_WIZARD_STEP.GOALS);

  useEffect(() => {
    // TODO (Bao): Remove the condition disabling this logic on android and iOS until mobile app dev integrate this
    if (!isMobilePlatform()) {
      sendDataToPlatform(
        platform,
        { step },
        MAC_APP_DATA_LOCATION.ROUTINE_SUGGESTIONS_STEP
      );
    }
  }, [step]);

  return (
    <>
      <div
        style={{ transform: `translateX(-${step * 100}%)` }}
        className='min-w-full w-full h-[calc(100%-84px)] flex transition ease-out duration-300 m-auto'
      >
        <RoutineGoals />
        <RoutineSelection />
        <RoutineDuration setStep={setStep} />
        <Suggestions setStep={setStep} />
        <ExploreHabitPacks />
      </div>
      <WizardNavigation step={step} setStep={setStep} />
    </>
  );
};

export default RoutineSuggestionsWizard;
