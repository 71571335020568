import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateRoutineCategory } from 'store/reducer/setting/slice';
import { EMPTY_STRING, ROUTINE_DESTINATION_POSITION } from 'constants/general';
import DropDown from 'components/common/DropDown';
import { DropDownSelectedValue } from 'interfaces';
import { isDarkModeActivated } from 'utils/validators';
import { DEFAULT_ROUTINE_DESTINATION_OPTIONS } from 'assets/data';
import {
  getMoveActivityDestinationLabel,
  getRoutineActivities
} from 'utils/helpers';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  return (
    <div
      className={`w-full md:w-1/2 flex flex-col gap-3 rounded-md ${
        shouldActivateDarkMode ? 'bg-gray-600' : 'bg-gray-200/40'
      } overflow-hidden p-0 sm:p-3`}
    >
      {children}
    </div>
  );
};

const MoveActivity = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    setting: {
      moreOptions: { type, position }
    },
    modal: {
      moreOption: { activity }
    }
  } = useAppSelector((state) => state);
  const routineSources = useMemo(() => getRoutineActivities(type), [type]);
  const [default_activity_type] = routineSources;
  const [moveRoutineData, setMoveRoutineData] = useState({
    destination_type: default_activity_type.value,
    destination_position: ROUTINE_DESTINATION_POSITION.START
  });

  return (
    <Wrapper>
      <DropDown
        title={t('move_habit.move_to_different_routine')}
        options={getRoutineActivities(type).map((src) => ({
          label: t(src.label),
          value: src.value
        }))}
        value={{
          label: t(
            routineSources.find(
              (src) => src.value === moveRoutineData.destination_type
            )?.label ?? EMPTY_STRING
          ),
          value: moveRoutineData.destination_type
        }}
        handleChange={(data) => {
          const result = data as DropDownSelectedValue;
          result &&
            setMoveRoutineData({
              ...moveRoutineData,
              destination_type: result.value
            });
        }}
        labelStyles='text-xs lg:text-sm font-medium'
        containerStyles='w-full'
        isClearable={false}
      />
      <DropDown
        title={t('move_habit.where_should_it_be_placed')}
        options={DEFAULT_ROUTINE_DESTINATION_OPTIONS}
        value={{
          label: getMoveActivityDestinationLabel(
            moveRoutineData.destination_position
          ),
          value: moveRoutineData.destination_position
        }}
        handleChange={(data) => {
          const result = data as DropDownSelectedValue;
          result &&
            setMoveRoutineData({
              ...moveRoutineData,
              destination_position: result.value
            });
        }}
        labelStyles='text-xs lg:text-sm font-medium'
        containerStyles='w-full'
        isClearable={false}
      />
      <button
        onClick={() => {
          dispatch(
            updateRoutineCategory({
              type,
              position,
              ...moveRoutineData
            })
          );
          toast.success(
            t('move_habit.completed', {
              name: activity?.name,
              category: moveRoutineData.destination_type.replace('_', ' '),
              position: moveRoutineData.destination_position
            })
          );
        }}
        className='w-fit h-fit text-[11px] font-medium px-2 py-1 rounded-md text-white bg-gray-500 cursor-pointer transition ease-in-out delay-150 hover:scale-110 hover:bg-gray-700 duration-300 self-start sm:self-end'
      >
        {t('move_habit.move_activity')}
      </button>
    </Wrapper>
  );
};

export default MoveActivity;
