import { IconProps } from 'interfaces';

const NerdFace = ({ styles = '~w-5/8 h-auto' }: IconProps) => {
  return (
    <svg
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      className={styles}
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <circle fill='#FFCC4D' cx='18' cy='18' r='18'></circle>
        <path
          fill='#664500'
          d='M27.335 23.629a.501.501 0 0 0-.635-.029c-.039.029-3.922 2.9-8.7 2.9c-4.766 0-8.662-2.871-8.7-2.9a.5.5 0 0 0-.729.657C8.7 24.472 11.788 29.5 18 29.5s9.301-5.028 9.429-5.243a.499.499 0 0 0-.094-.628z'
        ></path>
        <path
          fill='#65471B'
          d='M18 26.591c-.148 0-.291-.011-.438-.016v4.516h.875v-4.517c-.145.005-.289.017-.437.017z'
        ></path>
        <path
          fill='#FFF'
          d='M22 26c.016-.004-1.45.378-2.446.486c-.366.042-.737.076-1.117.089v4.517H20c1.1 0 2-.9 2-2V26zm-8 0c-.016-.004 1.45.378 2.446.486c.366.042.737.076 1.117.089v4.517H16c-1.1 0-2-.9-2-2V26z'
        ></path>
        <path
          fill='#65471B'
          d='M27.335 23.629a.501.501 0 0 0-.635-.029c-.03.022-2.259 1.668-5.411 2.47c-.443.113-1.864.43-3.286.431c-1.424 0-2.849-.318-3.292-.431c-3.152-.802-5.381-2.448-5.411-2.47a.501.501 0 0 0-.729.657c.097.162 1.885 3.067 5.429 4.481v-1.829c-.016-.004 1.45.378 2.446.486c.366.042.737.076 1.117.089c.146.005.289.016.437.016c.148 0 .291-.011.438-.016c.38-.013.751-.046 1.117-.089c.996-.108 2.462-.49 2.446-.486v1.829c3.544-1.414 5.332-4.319 5.429-4.481a.5.5 0 0 0-.095-.628zm-.711-9.605c0 1.714-.938 3.104-2.096 3.104c-1.157 0-2.096-1.39-2.096-3.104s.938-3.104 2.096-3.104c1.158 0 2.096 1.39 2.096 3.104zm-17.167 0c0 1.714.938 3.104 2.096 3.104c1.157 0 2.096-1.39 2.096-3.104s-.938-3.104-2.096-3.104c-1.158 0-2.096 1.39-2.096 3.104z'
        ></path>
        <path
          fill='#292F33'
          d='M34.808 9.627c-.171-.166-1.267.274-2.376-.291c-2.288-1.166-8.07-2.291-11.834.376c-.403.285-2.087.333-2.558.313c-.471.021-2.155-.027-2.558-.313c-3.763-2.667-9.545-1.542-11.833-.376c-1.109.565-2.205.125-2.376.291c-.247.239-.247 1.196.001 1.436c.246.239 1.477.515 1.722 1.232c.247.718.249 4.958 2.213 6.424c1.839 1.372 6.129 1.785 8.848.238c2.372-1.349 2.289-4.189 2.724-5.881c.155-.603.592-.907 1.26-.907s1.105.304 1.26.907c.435 1.691.351 4.532 2.724 5.881c2.719 1.546 7.009 1.133 8.847-.238c1.965-1.465 1.967-5.706 2.213-6.424c.245-.717 1.476-.994 1.722-1.232c.248-.24.249-1.197.001-1.436zm-20.194 3.65c-.077 1.105-.274 3.227-1.597 3.98c-.811.462-1.868.743-2.974.743h-.001c-1.225 0-2.923-.347-3.587-.842c-.83-.619-1.146-3.167-1.265-4.12c-.076-.607-.28-2.09.388-2.318c1.06-.361 2.539-.643 4.052-.643c.693 0 3.021.043 4.155.741c1.005.617.872 1.851.829 2.459zm16.278-.253c-.119.954-.435 3.515-1.265 4.134c-.664.495-2.362.842-3.587.842h-.001c-1.107 0-2.163-.281-2.975-.743c-1.323-.752-1.52-2.861-1.597-3.966c-.042-.608-.176-1.851.829-2.468c1.135-.698 3.462-.746 4.155-.746c1.513 0 2.991.277 4.052.638c.668.228.465 1.702.389 2.309z'
        ></path>
      </g>
    </svg>
  );
};

export default NerdFace;
