import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import TabLayout from 'components/common/TabLayout';
import { REACT_KEY } from 'constants/general';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from 'store/hooks';
import { suggestionsSelector } from 'store/reducer/routine-suggestions/selectors';
import { getRoutineActivitiesFromSuggestions } from 'utils/helpers';
import RoutineSuggestions from './RoutineSuggestions';
import { captureEvents } from 'utils/events';
import { POST_HOG_EVENT } from 'constants/events';
import InfoCircle from 'assets/icons/InfoCircle';
import classNames from 'classnames';

const Suggestions = ({
  setStep
}: {
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  const { routineSuggestions, routines, userId, userEmail, platform } =
    useAppSelector(suggestionsSelector);

  const shouldSelectBothRoutines = routines.every((routine) => routine.value);

  const { morning_activities, evening_activities } =
    getRoutineActivitiesFromSuggestions(routineSuggestions ?? []);

  return (
    <div
      className={classNames(
        'min-w-full flex flex-col gap-2 ~pt-4/8 ~px-3/6 shadow rounded-lg animate-fadeIn overflow-y-auto scrollbar-thin',
        {
          'h-fit max-h-full': Boolean(routineSuggestions?.length),
          'h-full justify-center': !routineSuggestions?.length
        }
      )}
    >
      {(routineSuggestions ?? [])?.length ? (
        <>
          {shouldSelectBothRoutines ? (
            <TabLayout
              tabs={[
                <RoutineSuggestions
                  key={REACT_KEY.MORNING_ROUTINE_SUGGESTION}
                  activities={morning_activities}
                />,
                <RoutineSuggestions
                  key={REACT_KEY.EVENING_ROUTINE_SUGGESTION}
                  activities={evening_activities}
                />
              ]}
            />
          ) : (
            <RoutineSuggestions
              key={REACT_KEY.EVENING_ROUTINE_SUGGESTION}
              activities={routineSuggestions ?? []}
            />
          )}
          <p className='w-fit ~text-sm/base py-5 flex items-start sm:items-center gap-1 self-center'>
            <InfoCircle styles='~w-4/6 h-auto ~py-0.5/0' />
            {t('routine_suggestion.you_can_also_customise_your_habits_later')}
          </p>
        </>
      ) : (
        <div className='w-full h-fit flex flex-col gap-2 items-center justify-center'>
          <p className='text-sm sm:text-base md:text-lg text-center ~px-6/20'>
            {t(
              'routine_suggestion.we_couldnt_find_any_habits_that_match_your_goals'
            )}
          </p>
          <ButtonFocusBear
            title={t('routine_suggestion.explore_habit_packs')}
            onClick={() => {
              captureEvents(
                POST_HOG_EVENT.ROUTINE_SUGGESTIONS_EXPLORE_HABIT_PACKS_BUTTON_CLICKED,
                userEmail,
                { platform, userId }
              );
              setStep((prev) => ++prev);
            }}
            additionalStyles='my-2'
          />
        </div>
      )}
    </div>
  );
};

export default Suggestions;
