import NerdFace from 'assets/icons/NerdFace';
import SmilingFaceWithSunglasses from 'assets/icons/SmilingFaceWithSunglasses';
import classNames from 'classnames';
import UnlockHabitPackModal from 'components/UnlockHabitPackModal';
import { FLAG, THEME_OPTION } from 'constants/enum';
import { PLATFORMS, SETTING_TAB_INDEX, TAB } from 'constants/general';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import {
  resetFilters,
  updateFilterIsSettingToolbarShow
} from 'store/reducer/filter/slice';
import { getUserSettings } from 'store/reducer/setting/extra';
import { tabBodySelector } from 'store/reducer/setting/selectors';
import { updateActiveTabIndex } from 'store/reducer/setting/slice';
import { getRoutineTabs, getSettingTabIcon } from 'utils/settings.util';
import MoreOptions from './MoreOptions';
import MorningAdvancedModal from './routine/MorningAdvancedModal';
import { getSettingPageTabContent } from '../tabs-body-steps/content-body-types';

const TabBody = () => {
  const {
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags,
    isUserAdmin
  } = useAppSelector(tabBodySelector);

  const dispatch = useAppDispatch();

  const [isGeekMode] = useMemo(() => [flags.includes(FLAG.GEEK_MODE)], [flags]);

  const routine_tabs = useMemo(
    () =>
      getRoutineTabs(tabs, platform, isGeekMode, isUserAdmin, customRoutine),
    [tabs, platform, flags, isUserAdmin]
  );

  useEffect(() => {
    const isHabitPackInstallOrUninstalled =
      [
        SETTING_TAB_INDEX.EVENING_ROUTINE,
        SETTING_TAB_INDEX.HABIT_PACKS
      ].includes(activeTabIndex) && !isSettingLoaded; //@Description: this change needs to be reverted back once the mobile apps have the micro-break tab active
    isHabitPackInstallOrUninstalled && dispatch(getUserSettings());
  }, [isSettingLoaded, activeTabIndex]);

  return (
    <>
      <Tabs
        selectedIndex={activeTabIndex}
        className={classNames('w-full h-[89%] flex flex-col', {
          'py-2 md:py-4': !customRoutine,
          'px-2 py-4': customRoutine
        })}
        onSelect={(selectedIndex) => {
          dispatch(updateActiveTabIndex(selectedIndex));
          dispatch(updateFilterIsSettingToolbarShow(false));
          dispatch(resetFilters());
          routine_tabs[selectedIndex] === TAB.HABIT_PACK &&
            dispatch(setCurrentViewedPack(null));
        }}
        selectedTabClassName={classNames(
          {
            'bg-gray-700': themeMode === THEME_OPTION.DARK,
            'bg-white': themeMode === THEME_OPTION.LIGHT
          },
          'border-t rounded-t-md'
        )}
      >
        <TabList className='w-full min-h-[2rem] h-[7%] flex items-center sm:items-start ~gap-2/4'>
          {routine_tabs.map((tab: string, index: number) => {
            const testId = getSettingPageTabContent(
              mode,
              index,
              customRoutine,
              platform,
              isUserAdmin
            ).testIds?.[index];
            return (
              <Tab
                data-test={testId}
                className={classNames(
                  'min-w-max ~text-sm/base px-4 py-1 cursor-pointer outline-none border-gray-400 flex items-center gap-2 select-none',
                  {
                    'tracking-wider': platform !== PLATFORMS.WEB,
                    'tracking-normal': platform === PLATFORMS.WEB,
                    'border-b': activeTabIndex !== index
                  }
                )}
                key={`tab-${tab}`}
              >
                {getSettingTabIcon(tab)}
                <p
                  className={`${index === activeTabIndex ? 'inline-flex' : 'hidden sm:inline-flex'}`}
                >
                  {t(`${tab}`)}
                </p>
              </Tab>
            );
          })}
          {platform !== PLATFORMS.WEB && (
            <span className='~px-2/4'>
              {!isGeekMode ? <NerdFace /> : <SmilingFaceWithSunglasses />}
            </span>
          )}
        </TabList>
        {routine_tabs.map((tab: string, index: number) => (
          <TabPanel
            key={tab}
            className={`h-full sm:h-[93%] relative ~p-1/2 ${index === activeTabIndex ? 'relative' : 'hidden'}`}
          >
            {
              getSettingPageTabContent(
                mode,
                index,
                customRoutine,
                platform,
                isUserAdmin
              ).body
            }
          </TabPanel>
        ))}
      </Tabs>
      {isShowRoutineModal && <MorningAdvancedModal />}
      {isShowMoreOptionsOpened && position !== -1 && type && <MoreOptions />}
      {isUnlockHabitPackModalShown && <UnlockHabitPackModal />}
    </>
  );
};

export default TabBody;
