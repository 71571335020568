import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import { initialTeamState } from 'store/initial-states';
import { fetchAdminTeams, fetchAllMembers } from './extra';
import { Team, TeamMember } from 'interfaces';

const team = createSlice({
  name: 'team',
  initialState: initialTeamState,
  reducers: {
    updateAreMembersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.areMembersLoading = payload;
    },
    updateAreTeamsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.areTeamsLoading = payload;
    },
    updateIsInvitingUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvitingUser = payload;
    },
    updateIsRemovingMember: (state, { payload }: PayloadAction<boolean>) => {
      state.isRemovingMember = payload;
    },
    updateIsAssigningAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.isAssigningAdmin = payload;
    },
    updateIsRemovingAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.isRemovingAdmin = payload;
    },
    updateIsUpdatingTeamName: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingTeamName = payload;
    },
    updateIsDeletingTeam: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingTeam = payload;
    },
    updateTeamOwnerAction: (
      state,
      { payload }: PayloadAction<TEAM_OWNER_ACTION>
    ) => {
      state.teamOwnerAction = payload;
      state.showTeamOwnerActionModal = true;
    },
    updateShowTeamOwnerActionModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showTeamOwnerActionModal = payload;
    },
    updateSelectedTeam: (state, { payload }: PayloadAction<Team | null>) => {
      state.selectedTeam = payload;
    },
    updateTeams: (state, { payload }: PayloadAction<Team[]>) => {
      state.teams = payload;
    },
    updateIsAcceptingInvitation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAcceptingInvitation = payload;
    },
    updateIsInvitationAccepted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInvitationAccepted = payload;
    },
    updateIsUpdatingExpiryDate: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingExpiryDate = payload;
    },
    updateSelectedMemberId: (state, { payload }: PayloadAction<string>) => {
      state.selectedMemberId = payload;
    },
    updateSelectedMembersId: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedMembersId = payload;
    },
    updateSearchMember: (state, { payload }: PayloadAction<string>) => {
      state.searchMember = payload;
    },
    updateShowTeamOwnerMassUpdateModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showTeamOwnerMassUpdateModal = payload;
    },
    updateIsExpiryDateMassUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isExpiryDateMassUpdating = payload;
    },
    updateIsMemberTypeMassUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isMemberTypeMassUpdating = payload;
    },
    updateHasInvitationAlreadyAccepted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.hasInvitationAlreadyAccepted = payload;
    },
    updateIsAddingMemberManually: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddingMemberManually = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdminTeams.fulfilled, (state, { payload }) => {
        if (Array.isArray(payload) && payload?.length) {
          state.teams = payload;
        }
        state.areTeamsLoading = false;
      })
      .addCase(fetchAllMembers.fulfilled, (state, { payload }) => {
        state.teamMembers = {
          admins: payload?.admin ?? [],
          members:
            payload?.members?.sort(
              (member1: TeamMember, member2: TeamMember) =>
                new Date(member1.created_at).getTime() -
                new Date(member2.created_at).getTime()
            ) ?? []
        };
        state.areMembersLoading = false;
      })
      .addCase(fetchAdminTeams.rejected, (state) => {
        state.teams = [];
        state.areTeamsLoading = false;
      });
  }
});

export const {
  updateAreMembersLoading,
  updateAreTeamsLoading,
  updateIsInvitingUser,
  updateIsRemovingMember,
  updateIsAssigningAdmin,
  updateIsRemovingAdmin,
  updateIsUpdatingTeamName,
  updateIsDeletingTeam,
  updateTeamOwnerAction,
  updateShowTeamOwnerActionModal,
  updateSelectedTeam,
  updateTeams,
  updateIsAcceptingInvitation,
  updateIsInvitationAccepted,
  updateIsUpdatingExpiryDate,
  updateSelectedMemberId,
  updateSelectedMembersId,
  updateSearchMember,
  updateShowTeamOwnerMassUpdateModal,
  updateIsExpiryDateMassUpdating,
  updateIsMemberTypeMassUpdating,
  updateHasInvitationAlreadyAccepted,
  updateIsAddingMemberManually
} = team.actions;

export default team.reducer;
