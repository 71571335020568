import Edit from 'assets/icons/Edit';
import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/common.interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatRoutineTimeSuggestions } from 'utils/routine-suggestion.util';

interface RoutineSuggestionProps {
  readonly suggestion: ActivityType;
  readonly handleDuration: (value: string, activity_id: string) => void;
}

const RoutineSuggestion = ({
  suggestion,
  handleDuration
}: RoutineSuggestionProps) => {
  const [duration, setDuration] = useState({
    isEditable: false,
    value: suggestion.duration_seconds,
    isInvalid: !suggestion.duration_seconds
  });

  useEffect(() => {
    setDuration((prev) => ({ ...prev, value: suggestion.duration_seconds }));
  }, [suggestion.duration_seconds]);

  const handleToggle = useCallback(
    () => setDuration((prev) => ({ ...prev, isEditable: !prev.isEditable })),
    []
  );

  const hasCompletionRequirement = useMemo(
    () => Boolean(suggestion.completion_requirements),
    [suggestion]
  );

  return (
    <div className='w-full h-fit flex items-center gap-4 p-3 rounded-md shadow-md relative'>
      <p
        className={classNames(
          'w-fit ~max-w-[70%]/[80%] h-fit ~text-xs/base truncate',
          {
            'self-start': duration.isEditable,
            'self-center': !duration.isEditable
          }
        )}
      >
        {suggestion.name}
      </p>
      {!hasCompletionRequirement ? (
        <>
          {duration.isEditable ? (
            <div className='flex flex-col gap-0.5 pt-0 md:pt-0.5'>
              <input
                type='number'
                value={duration.value}
                className={classNames(
                  'w-28 px-2 ~py-0.5/-1 text-black outline-none ~text-[0.60rem]/xs text-center shadow rounded font-semibold min-w-max',
                  {
                    'bg-red-100 border border-red-600': duration.isInvalid,
                    'bg-gray-50': !duration.isInvalid
                  }
                )}
                onBlur={handleToggle}
                min={1}
                onChange={({ target: { value } }) => {
                  setDuration((prev) => ({
                    ...prev,
                    value: parseInt(value),
                    isInvalid: !value || !parseInt(value)
                  }));
                  handleDuration(value, suggestion.id);
                }}
              />
              <p className='text-[0.6rem] self-end font-medium leading-[0.6rem]'>
                {t('seconds')}
              </p>
            </div>
          ) : (
            <p
              className={classNames(
                'min-w-max w-fit h-fit ~text-[0.60rem]/xs text-white pl-2.5 gap-2 rounded-md shadow flex items-center',
                {
                  'bg-red-600': duration.isInvalid,
                  'bg-gray-700': !duration.isInvalid
                }
              )}
            >
              {formatRoutineTimeSuggestions(suggestion.duration_seconds)}
              <ButtonFocusBear
                size={BTN_FB_SIZE.SMALL}
                icon={<Edit styles='w-3.5 h-4' />}
                variant={BTN_FB_VARIANT.SECONDARY}
                additionalStyles='rounded-l-none'
                disableDarkMode
                onClick={handleToggle}
              />
            </p>
          )}
        </>
      ) : (
        <p className='~text-[0.60rem]/xs bg-sunRayFirst text-white rounded-md px-2 shadow select-none min-w-max'>
          {t('completion_requirement')}
        </p>
      )}
    </div>
  );
};

export default RoutineSuggestion;
