import { useAuth0 } from '@auth0/auth0-react';
import BearPawPrint from 'assets/icons/BearPawPrint';
import GlobeNo from 'assets/icons/GlobeNo';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import DocumentMetaData from 'components/DocumentMetaData';
import {
  EMPTY_STRING,
  ERROR,
  ERROR_PAGE_RELOADED,
  ERROR_PAGE_TIMEOUT_DELAY,
  HTTP_STATS_CODE,
  LOCAL_STORAGE
} from 'constants/general';
import { PAGES, ROUTES } from 'constants/routes';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  errorMessageSelector,
  errorPageSelector
} from 'store/reducer/setting/selectors';
import { updateError } from 'store/reducer/setting/slice';
import {
  getLocalStorage,
  removeLocalStorage,
  updateLocalStorage
} from 'utils/helpers';
import { isRouteEmbedded } from 'utils/validators';

const NoInternetConnection = () => {
  return (
    <div className='w-fit h-fit flex flex-col items-center gap-4'>
      <BearPawPrint />
      <div className='w-fit flex items-center gap-2'>
        <GlobeNo />
        <p className='text-orange-500 text-xl font-medium'>
          {t('no_internet_connection')}
        </p>
      </div>
      <ButtonFocusBear
        onClick={() => {
          const redirectURL = getLocalStorage(LOCAL_STORAGE.REDIRECT_PATH);
          window.location.pathname = redirectURL ?? ROUTES.HOME;
        }}
        title={t('reload_the_page')}
      />
    </div>
  );
};

const ErrorMessage = () => {
  const dispatch = useAppDispatch();
  const {
    error: { message, url: previousURL },
    subscriptionInfo
  } = useAppSelector(errorMessageSelector);
  const { logout } = useAuth0();

  const isValidUser = subscriptionInfo?.hasActiveSubscription;
  const isPageReloaded = getLocalStorage(LOCAL_STORAGE.ERROR_PAGE_RELOADED);
  const isChunkLoadError = message === ERROR.CHUNK_LOAD;

  useEffect(() => {
    !isPageReloaded && setTimeout(handleResetPage, ERROR_PAGE_TIMEOUT_DELAY);
  }, []);

  function checkPageReloaded() {
    !isPageReloaded &&
      updateLocalStorage(
        LOCAL_STORAGE.ERROR_PAGE_RELOADED,
        ERROR_PAGE_RELOADED
      );
    window.location.pathname = previousURL ?? ROUTES.HOME;
  }

  const handleResetPage = () => {
    if (isChunkLoadError || isRouteEmbedded) {
      checkPageReloaded();
    } else {
      dispatch(
        updateError({
          message: EMPTY_STRING,
          status: undefined,
          url: undefined
        })
      );
      if (isValidUser) {
        const redirectURL = getLocalStorage(LOCAL_STORAGE.REDIRECT_PATH);
        window.location.pathname = redirectURL ?? ROUTES.HOME;
      } else {
        removeLocalStorage([LOCAL_STORAGE.ERROR_PAGE_RELOADED]);
        // logs user out if there was a problem fetching their subscription info
        logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI });
      }
    }
  };

  return (
    <div className=' container px-4 mx-auto flex flex-col justify-center items-center'>
      <div className='mx-auto text-center flex flex-col justify-center items-center ~gap-6/10 p-4/8'>
        {!isRouteEmbedded ? (
          <div className='flex flex-col items-center ~gap-1.5/4'>
            <BearPawPrint />
            <h2 className='text-orange-400 ~text-2xl/4xl leading-tight font-bold tracking-tighter'>
              {t('encountered_problem')}
            </h2>
          </div>
        ) : null}
        <p className='w-11/12 md:w-10/12 ~text-sm/xl'>
          {isChunkLoadError
            ? t('chunk_error_description')
            : t('error_page_message')}
        </p>
        <ButtonFocusBear
          onClick={handleResetPage}
          title={isRouteEmbedded ? t('reload_the_page') : t('back_to_homepage')}
        />
      </div>
    </div>
  );
};

export default function ErrorPage() {
  const error = useAppSelector(errorPageSelector);

  return (
    <section className='bg-main mt-[80px] h-[calc(100vh-80px)] flex flex-col justify-center items-center'>
      <DocumentMetaData pageName={PAGES.ERROR} />
      {error?.status === HTTP_STATS_CODE.NO_CONNECTION ? (
        <NoInternetConnection />
      ) : (
        <ErrorMessage />
      )}
    </section>
  );
}
