import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoutineSuggestionPayload } from 'interfaces';
import { ActivityType } from 'interfaces/common.interface';
import { initialRoutineSuggestions } from 'store/initial-states';
import { getRoutineSuggestionActivities } from './extra';
import { getRoutineActivitiesFromSuggestions } from 'utils/helpers';

const routineSuggestions = createSlice({
  name: 'routine-suggestions',
  initialState: initialRoutineSuggestions,
  reducers: {
    updateIsFetchingRoutineSuggestions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingRoutineSuggestions = payload;
    },
    updateRoutineSuggestions: (
      state,
      { payload }: PayloadAction<ActivityType[] | null>
    ) => {
      state.routineSuggestions = payload;
    },
    updateRoutineSuggestionPayload: (
      state,
      { payload }: PayloadAction<RoutineSuggestionPayload>
    ) => {
      state.routineSuggestionPayload = payload;
    },
    updateAreSuggestedHabitPacksInstalled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areSuggestedHabitPacksInstalled = payload;
    },
    updateIsInstallingRoutineSuggestions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInstallingRoutineSuggestions = payload;
    },
    updateShowEditSuggestionsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showEditSuggestionsModal = payload;
    },
    updateShowAvailableGoalsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showAvailableGoalsModal = payload;
    },
    updateSelectedGoals: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedGoals = payload;
    },
    updateAvailableGoals: (state, { payload }: PayloadAction<string[]>) => {
      state.availableGoals = payload;
    },
    updateRoutineSuggestionUserGoalsPayload: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.routineSuggestionPayload.user_goals = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        getRoutineSuggestionActivities.fulfilled,
        (state, { payload }) => {
          const { morning_activities, evening_activities } =
            getRoutineActivitiesFromSuggestions(payload);
          const sorted_morning_activities = morning_activities.sort(
            (activityA, activityB) =>
              activityA.duration_seconds - activityB.duration_seconds
          );
          const sorted_evening_activities = evening_activities.sort(
            (activityA, activityB) =>
              activityA.duration_seconds - activityB.duration_seconds
          );

          state.routineSuggestions = sorted_morning_activities.concat(
            sorted_evening_activities
          );
          state.isFetchingRoutineSuggestions = false;
        }
      )
      .addCase(getRoutineSuggestionActivities.rejected, (state) => {
        state.routineSuggestions = [];
      });
  }
});

export const {
  updateIsFetchingRoutineSuggestions,
  updateRoutineSuggestions,
  updateAreSuggestedHabitPacksInstalled,
  updateIsInstallingRoutineSuggestions,
  updateRoutineSuggestionPayload,
  updateShowAvailableGoalsModal,
  updateShowEditSuggestionsModal,
  updateAvailableGoals,
  updateSelectedGoals,
  updateRoutineSuggestionUserGoalsPayload
} = routineSuggestions.actions;

export default routineSuggestions.reducer;
