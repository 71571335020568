import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import TextInput from 'components/common/inputs/TextInput';
import { BTN_FB_SIZE } from 'constants/enum';
import { EMPTY_STRING } from 'constants/general';
import { t } from 'i18next';
import _ from 'lodash';
import { AvailableGoalsModalContext } from 'pages/routine-suggestions';
import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routineAvailableGoalsSelector } from 'store/reducer/routine-suggestions/selectors';
import { updateSelectedGoals } from 'store/reducer/routine-suggestions/slice';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validators';

interface ModalActionsProps {
  handleModalClose: () => void;
}

export const ModalActions = ({ handleModalClose }: ModalActionsProps) => {
  const { goals, setGoals } = useContext(AvailableGoalsModalContext);
  const dispatch = useAppDispatch();
  const [newGoal, setNewGoal] = useState(EMPTY_STRING);
  const { isLogTermGoalUpdating, availableGoals, selectedGoals } =
    useAppSelector(routineAvailableGoalsSelector);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const [shouldDisableAddNewGoal, shouldDisableApplyChanges] = [
    !newGoal.trim() ||
      [...goals.new, ...availableGoals].some(
        (goal) =>
          goal?.toLowerCase() === newGoal?.toLowerCase() ||
          isLogTermGoalUpdating
      ),
    _.isEqual(goals.selected, selectedGoals)
  ];

  return (
    <div className='flex flex-col ~gap-4/6 ~mt-4/6'>
      <div className='w-full flex items-center justify-center gap-3 flex-wrap'>
        <TextInput
          disabled={isLogTermGoalUpdating}
          value={newGoal}
          onChange={({ target: { value } }) => setNewGoal(value)}
          className={classNames(
            'min-w-[55vw] outline-none rounded-md shadow px-2 ~py-1/2 ~text-xs/sm text-black',
            {
              'bg-gray-400 focus:bg-gray-400/80': shouldActivateDarkMode,
              'bg-white': !shouldActivateDarkMode
            }
          )}
          placeholder={t('routine_suggestion.long_term_goal')}
        />
        <ButtonFocusBear
          disabled={shouldDisableAddNewGoal}
          size={BTN_FB_SIZE.SMALL}
          onClick={() => {
            setNewGoal(EMPTY_STRING);
            setGoals?.((prev) => ({ ...prev, new: prev.new.concat(newGoal) }));
          }}
          title={t('add_goal')}
          disableDarkMode
        />
      </div>
      <div
        className={classNames(
          'flex items-center justify-center md:justify-end ~gap-6/10 ~p-4/6 rounded-b-md border-t border-sunRayFirst',
          {
            'bg-gray-700': shouldActivateDarkMode,
            'bg-whiteLace': !shouldActivateDarkMode
          }
        )}
      >
        <ButtonFocusBear
          disabled={shouldDisableApplyChanges}
          size={BTN_FB_SIZE.SMALL}
          onClick={() => {
            dispatch(updateSelectedGoals(goals.selected));
            handleModalClose();
          }}
          title={t('routine_suggestion.update_list')}
          disableDarkMode
        />
      </div>
    </div>
  );
};

export default ModalActions;
