import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import classNames from 'classnames';
import {
  Attributes,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { isDarkThemeActivated } from 'utils/settings.util';
interface TextInputProps
  extends DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    Attributes {
  readonly additionalStyles?: string;
  readonly enableDebounce?: boolean;
}

const TextInput = forwardRef(
  (
    {
      type = 'text',
      className,
      additionalStyles,
      enableDebounce = false,
      onChange,
      ...rest
    }: TextInputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const shouldActivateDarkTheme = useMemo(() => isDarkThemeActivated(), []);
    const [debouncedValue, setDebouncedValue] = useState('');
    const debounceDelay = 300;

    useEffect(() => {
      if (!enableDebounce) {
        return;
      } else {
        const handler = setTimeout(() => {
          onChange?.({
            target: { value: debouncedValue }
          } as React.ChangeEvent<HTMLInputElement>);
        }, debounceDelay);

        return () => clearTimeout(handler);
      }
    }, [debouncedValue, enableDebounce, debounceDelay, onChange]);

    useImperativeHandle(ref, () => ({
      getValue: () => inputRef.current?.value
    }));

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (enableDebounce) {
          setDebouncedValue(event.target.value);
        } else {
          onChange?.(event);
        }
      },
      []
    );

    return (
      <input
        ref={inputRef}
        type='text'
        {...rest}
        className={classNames({
          [`${className}`]: Boolean(className),
          'border-orange-300 border outline-none rounded-md px-2 py-1 min-w-[30vw] ~text-sm/base text-black':
            !className,
          [`${additionalStyles}`]: true,
          'bg-white focus:bg-gray-100': !shouldActivateDarkTheme,
          'bg-gray-400': shouldActivateDarkTheme
        })}
        {...DEFAULT_HTML_ATTRIBUTES}
        onChange={handleChange}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
