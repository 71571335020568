import PlusCircle from 'assets/icons/PlusCircle';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import COLOR from 'constants/color';
import { BTN_FB_SIZE } from 'constants/enum';
import { ROUTINE, SETTING_TAB_INDEX } from 'constants/general';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addHabitButtonSelector } from 'store/reducer/setting/selectors';
import { newHabit } from 'store/reducer/setting/slice';
import { isMobilePlatform } from 'utils/settings.util';
import { decrement, getNewHabitProps } from 'utils/helpers';

interface AddHabitButtonProps {
  routine?: string;
  title?: string;
  customRoutinePosition?: number;
  additionalBtnStyles?: string;
}

const AddHabitButton = ({
  routine,
  title,
  customRoutinePosition,
  additionalBtnStyles
}: AddHabitButtonProps) => {
  const dispatch = useAppDispatch();
  const {
    tabs,
    currentSettings,
    activeTabIndex,
    customRoutine,
    routines,
    platform
  } = useAppSelector(addHabitButtonSelector);

  const isCustomRoutinesTab = useMemo(
    () =>
      isMobilePlatform()
        ? activeTabIndex === decrement(SETTING_TAB_INDEX.CUSTOM_ROUTINES)
        : activeTabIndex === SETTING_TAB_INDEX.CUSTOM_ROUTINES,
    [activeTabIndex, platform]
  );

  const [activity_type, sequence_id] = useMemo(
    () =>
      getNewHabitProps(
        tabs,
        activeTabIndex,
        _.cloneDeep(currentSettings),
        customRoutine,
        routines,
        isCustomRoutinesTab,
        customRoutinePosition
      ),
    [tabs, activeTabIndex, currentSettings, customRoutine, routines]
  );

  return (
    <div className='w-fit h-full flex flex-col items-center justify-center gap-4 sm:gap-6 animate-fadeIn my-auto'>
      {routine ? (
        <p className='text-sm sm:text-base md:text-lg font-semibold italic text-center'>
          {t(
            routine === ROUTINE.CUSTOM_ROUTINES
              ? 'make_at_least_one_new_custom_routine_to_your_custom_routines'
              : 'make_at_least_one_new_habit_a_part_of_your_routine',
            {
              routine: routine || t('custom_routine')
            }
          )}
        </p>
      ) : null}

      <ButtonFocusBear
        onClick={() =>
          dispatch(
            newHabit({
              type: activity_type,
              sequence_id,
              customRoutinePosition
            })
          )
        }
        icon={
          <PlusCircle
            styles={`${customRoutinePosition !== undefined ? '~w-3/4' : '~w-3/5'} h-auto bg-sunRayFirst rounded-full`}
            fill={COLOR.FOCUS_BEAR_LIGHT}
          />
        }
        title={
          routine
            ? t(
                routine === ROUTINE.CUSTOM_ROUTINES
                  ? 'add_your_first_custom_routine'
                  : 'add_your_first_routine_habit',
                {
                  routine:
                    routine === ROUTINE.CUSTOM_ROUTINES
                      ? t('custom_routine')
                      : routine
                }
              )
            : title ?? t('add_a_habit')
        }
        additionalStyles={additionalBtnStyles}
        size={
          customRoutinePosition !== undefined
            ? BTN_FB_SIZE.SMALL
            : BTN_FB_SIZE.MEDIUM
        }
      />
    </div>
  );
};

export default AddHabitButton;
