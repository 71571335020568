import MorningActivities from './MorningActivities';
import BreakActivities from './BreakActivities';
import EveningActivities from './EveningActivities';
import Timing from './timing';
import PackManager from './habit-packs/PackManager';
import FocusModeAllowedAppsAndURLs from './FocusModeAllowedAppsAndURLs';
import PackAndFocusModeInfo from './habit-packs/PackAndFocusModeInfo';
import StandaloneActivities from './StandaloneActivities';
import { MANISH_USER_ID, ROUTINE } from 'constants/general';
import CustomRoutines from './CustomRoutines';
import { Mode } from 'constants/enum';
import { ATTRIB } from 'constants/test';
import { store } from 'store';
import { getRoutineValue } from 'utils/helpers';
import { isMobilePlatform } from 'utils/settings.util';

export const DEFAULT_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <MorningActivities />,
  '2': <BreakActivities />,
  '3': <EveningActivities />,
  '4': <CustomRoutines />,
  '5': <PackManager />
};

export const DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_CUSTOM_ROUTINES: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <MorningActivities />,
  '2': <BreakActivities />,
  '3': <EveningActivities />,
  '4': <PackManager />
};

export const DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_MICRO_BREAKS_AND_CUSTOM_ROUTINES: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <MorningActivities />,
  '2': <EveningActivities />,
  '3': <PackManager />
};

export const FOCUS_MODE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <FocusModeAllowedAppsAndURLs />,
  '1': <PackAndFocusModeInfo />
};

export const HABIT_PACK_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <MorningActivities />,
  '1': <BreakActivities />,
  '2': <EveningActivities />,
  '3': <PackAndFocusModeInfo />
};

export const STANDALONE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <StandaloneActivities />,
  '1': <PackAndFocusModeInfo />
};

export const FREE_STYLE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <BreakActivities />
};

export const ROUTINE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  [ROUTINE.TIMING]: <Timing />,
  [ROUTINE.MORNING]: <MorningActivities />,
  [ROUTINE.BREAKING]: <BreakActivities />,
  [ROUTINE.EVENING]: <EveningActivities />
};

export const getSettingPageTabContent = (
  mode: Mode,
  index: number,
  customRoutine: string,
  platform: string,
  isUserAdmin: boolean
) => {
  let body, testIds;
  switch (mode) {
    case Mode.FREESTYLE:
      body = FREE_STYLE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.FREESTYLE;
      break;
    case Mode.ROUTINE:
      body = HABIT_PACK_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.ROUTINE;
      break;
    case Mode.STANDALONE:
      body = STANDALONE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.STANDALONE;
      break;
    case Mode.FOCUS_MODE:
      body = FOCUS_MODE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      break;
    default: {
      if (customRoutine && Object.values(ROUTINE).includes(customRoutine)) {
        if (customRoutine === ROUTINE.SUGGESTION) {
          const routines = store
            .getState()
            .routineSuggestions.routineSuggestionPayload.routines.slice();
          const shouldSelectBothRoutines = routines.every(
            (routine) => routine.value
          );
          const routine = getRoutineValue(routines);
          body = shouldSelectBothRoutines
            ? ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[
                index === 0 ? ROUTINE.MORNING : ROUTINE.EVENING
              ]
            : ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[routine];
        } else {
          body = ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[customRoutine];
        }
      } else if (isMobilePlatform()) {
        // @Description, temporary disabled custom-routines & breaks for mobile apps
        body =
          DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_MICRO_BREAKS_AND_CUSTOM_ROUTINES[
            index.toString()
          ];
      } else {
        body =
          isUserAdmin || store.getState().user.details?.id === MANISH_USER_ID
            ? DEFAULT_STEP_TO_STEP_NUMBER_MAPPING[index.toString()]
            : DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_CUSTOM_ROUTINES[
                index.toString()
              ];
      }
      testIds = ATTRIB.TEST.HEADERS.DEFAULT;
    }
  }
  return { body, testIds, platform };
};
