import { MenuItem, Team } from 'interfaces';
import { v4 as uuid } from 'uuid';
import i18n from 'services/i18n';
import { ROUTES } from 'constants/routes';
import { ATTRIB } from 'constants/test';
import { DEFAULT_TEAM_NAME } from 'assets/data';
import 'moment-timezone';
import { isTeamOwnerOrAdmin } from './team.util';
import { ActiveEntitlement } from 'interfaces/subscription.interface';

export const getAccountDropDownMenus = (
  activeEntitlements: ActiveEntitlement[],
  teams: Team[]
) => {
  let accountMenuOptions: MenuItem[] = [
    {
      id: uuid(),
      path: ROUTES.PROFILE,
      text: i18n.t('profile'),
      attribAction: ATTRIB.TEST.PROFILE
    }
  ];

  if (isTeamOwnerOrAdmin(activeEntitlements)) {
    accountMenuOptions = [
      ...accountMenuOptions,
      {
        path: ROUTES.TEAM,
        text: i18n.t('team'),
        attribAction: ATTRIB.TEST.TEAM,
        subMenus: teams.map((team) => ({
          path: `team/${team.id}`,
          text: team.name ?? DEFAULT_TEAM_NAME
        }))
      }
    ];
  }

  accountMenuOptions = [
    ...accountMenuOptions,
    {
      id: uuid(),
      path: ROUTES.MANAGE_SUBSCRIPTION,
      text: i18n.t('manage_subscription'),
      attribAction: ATTRIB.TEST.MANAGE_SUBSCRIPTION
    },
    {
      id: uuid(),
      path: ROUTES.LOG_OUT
    }
  ];

  const extraMenuOptions = [
    {
      id: uuid(),
      path: ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES,
      text: i18n.t('packs.my_packs_and_focus_templates'),
      attribAction: ATTRIB.TEST.PACKS_AND_TEMPLATES
    },
    {
      id: uuid(),
      path: ROUTES.MARKETPLACE,
      text: i18n.t('market.marketplace'),
      attribAction: ATTRIB.TEST.MARKETPLACE
    },
    {
      id: uuid(),
      path: ROUTES.CHAT,
      text: i18n.t('chatbot.chat'),
      attribAction: ATTRIB.TEST.CHAT
    }
  ];

  return {
    accountMenuOptions,
    extraMenuOptions
  };
};
