import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NUMBERS, SCREEN } from 'constants/general';
import { ROUTES } from 'constants/routes';
import { LayoutContext } from '../layout';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  configureTabs,
  setGeneratorMode,
  updateIsSettingEditingFinished,
  updatePreviousSettings
} from 'store/reducer/setting/slice';
import { Mode } from 'constants/enum';
import LogoutButton from 'components/common/buttons/LogoutButton';
import { MenuItem } from 'interfaces';
import { ChevronRight } from 'assets/icons/ChevronRight';
import { navMenuSelector } from 'store/reducer/setting/selectors';

interface Props extends MenuItem {
  closeMenu?: React.MouseEventHandler;
  subMenus?: MenuItem[];
}

function NavMenu({ id, text, subMenus, path, closeMenu, attribAction }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isCurrentPage = path && window.location.href.endsWith(path);
  const screen = useContext(LayoutContext);

  const currentSettings = useAppSelector(navMenuSelector);
  const { width } = useContext(LayoutContext);

  const menuStyle =
    screen?.width ?? NUMBERS.ZERO > SCREEN.WIDTH.LARGE
      ? `text-gray-500 hover:text-gray-800 h-fit p-1 lg:px-1 xl:px-2 rounded-md ${
          isCurrentPage && 'bg-gray-100 text-orange-400'
        } hover:bg-gray-50 hover:text-orange-400`
      : `text-gray-800 block p-1 text-lg font-extrabold hover:bg-gray-50 rounded w-full text-left hover:text-orange-400 ${
          isCurrentPage && 'bg-gray-100 text-orange-400'
        }`;

  const subMenuStyle = (path?: string) => {
    const childPath = path && window.location.href.endsWith(path);
    return width > SCREEN.WIDTH.LARGE
      ? `text-gray-500 hover:bg-gray-100 bg-orange-50 w-full h-fit ~px-2/4 py-2 rounded-md ${
          childPath && 'bg-gray-100 text-orange-400'
        }`
      : `text-gray-800 block ~px-2/4 py-2 font-extrabold hover:bg-gray-50 rounded-md hover:text-orange-400  ${
          childPath && 'bg-gray-100 text-orange-400'
        }`;
  };

  return (
    <div className='dropdown dropdown-hover w-full min-w-max'>
      <button
        key={id}
        tabIndex={0}
        className={menuStyle}
        onClick={(event) => {
          path && navigate(path);
          closeMenu && closeMenu(event);
          // it will reset the current settings of the user
          if (path?.includes(ROUTES.SETTINGS)) {
            dispatch(setGeneratorMode(Mode.DEFAULT));
            dispatch(configureTabs({ mode: Mode.DEFAULT }));
            dispatch(updatePreviousSettings(currentSettings)); // it helps to reset the validation process and "finish" button become active
            dispatch(updateIsSettingEditingFinished(false)); // it navigates back to the UI where user settings can be seen
          }
        }}
        data-test={attribAction}
      >
        {text}
      </button>

      {subMenus?.length ? (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className='dropdown-content menu bg-sunRayFourth/80 rounded-box min-w-full lg:min-w-56 p-2 shadow z-50 flex flex-col gap-1'
        >
          {subMenus.map((subMenu) =>
            subMenu.subMenus?.length ? (
              <div key={subMenu.id} className='relative group'>
                <button
                  className={`${subMenuStyle(subMenu.path)} w-full flex justify-between`}
                >
                  {subMenu.text}
                  <ChevronRight styles='w-2 h-auto rotate-90 lg:rotate-0' />
                </button>
                <div className='absolute left-1 lg:left-full top-full lg:top-0 hidden group-hover:flex flex-col gap-1 bg-sunRayFifth lg:bg-sunRayFourth shadow-lg rounded w-full lg:w-52 p-2'>
                  {subMenu.subMenus.map((nestedMenu) => (
                    <Link key={nestedMenu.id} to={nestedMenu.path!}>
                      <button
                        className={`${subMenuStyle(subMenu.path)} w-full text-left`}
                      >
                        {nestedMenu.text}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <>
                {subMenu.path === ROUTES.LOG_OUT ? (
                  <LogoutButton key={subMenu.id} />
                ) : (
                  <Link key={subMenu.id} to={subMenu.path!}>
                    <button
                      data-action={subMenu.attribAction}
                      onClick={(event) => closeMenu?.(event)}
                      className={`${subMenuStyle(subMenu.path)} w-full text-left`}
                    >
                      {subMenu.text}
                    </button>
                  </Link>
                )}
              </>
            )
          )}
        </div>
      ) : null}
    </div>
  );
}

export default NavMenu;
